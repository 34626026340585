import {InvitationDesign} from "@firefly/fly-design-inv-data";
import {isMiniapp} from "@firefly/fly-defter";
import {NavigationOptions, useRelaunchHomepageWithToDesignDesigner} from "../suppport/router";
import {Documents} from "@firefly/fly-design-core";
import {doc2designer} from "./util";


export function useNavigateToDesignDesigner() {

    const relaunchToDesignDesigner = useRelaunchHomepageWithToDesignDesigner();
    return (design: Pick<InvitationDesign, 'id' | 'doctype'>, options: NavigationOptions = {}) => {
        const {
            id,
            doctype,
        } = design;
        const {
            replace = false,
        } = options;
        if (isMiniapp()) {
            return relaunchToDesignDesigner(design, {
                from: ""
            })
            // return navigateToMiniapp(`/page/design/designer`, omitUndefined({
            //     id: id,
            //     doctype: doctype,
            //     from: from
            // }))
        }
        let type = doc2designer(doctype);
        if (type === 'invitation-page') {
            type = 'page'
        }
        const location = window.location;
        const target = `/app/invitation/${type}-designer.html?id=${id}&from=`;
        //兼容，确保同一个域名
        if (replace) {
            location.replace(target)
        } else {
            location.assign(target);
        }
        return Promise.resolve();
    }
}