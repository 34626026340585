import React from "react";
import {useNavigate} from "react-router-dom";
import Result from "src/component/Result";
import {Flex, Empty} from "@firefly/fly-kda";

// import {Result, ResultBody, ResultFooter} from "../styles";

export interface OrderEmptyProps {
    description?: string;
}
function OrderEmpty(props: OrderEmptyProps) {
    //TODO  暂时兼容 头条 和 抖音
    const {
        description = "请打开原窗口继续操作"
    } = props;
    const navigate = useNavigate();


    return <Flex style={{height: "100%", backgroundColor: "#fff"}} align={"center"} justify={"center"}>

        <Result
            icon={<Empty description={description} />}
            extra={
                <>
                </>
                // <Space direction={"vertical"} gap={16} block>
                //     <Button plain block type={"primary"} onClick={() => {
                //         navigate("/", {
                //             replace: true
                //         })
                //     }}>
                //         回到主页
                //     </Button>
                // </Space>
            }
        />
    </Flex>
}

export default OrderEmpty;
