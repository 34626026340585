import {Toast} from "antd-mobile";
import {ErrorMessage} from "./typing";
import {isAccessDeniedException, isGraphQLException} from "@firefly/fly-security";
import {isMessageError, isNetworkError, isResponseStatusError} from "@firefly/fly-http";
// import {report} from "src/trace/TraceKit";





export namespace Errors {
    export function format(reason: any): ErrorMessage {
        if (isGraphQLException(reason)) {
            return ({
                title: reason.message
            })
        } else if (isAccessDeniedException(reason)) {
            return ({
                title: reason.message??"未认证"
            })
        } else if (isMessageError(reason)) {
            const {
                message,
                errorInfo,
                request,
                response,
            } = reason;
            const title = message;
            const description = errorInfo ? `错误代码： ${errorInfo.code}` : undefined;
            return ({
                title: title,
                description: description,
                details: [
                    response && {
                        key: "X-TraceID",
                        value: response.headers.get("X-TraceID"),
                    },
                    response && {
                        key: "Method",
                        value: request.method,
                    },
                    request && {
                        key: "URL",
                        value: request.url,
                    }
                ].filter(item => item)
            })
        } else if (isResponseStatusError(reason)) {
            return ({
                title: reason.message??"响应错误"
            })
        } else if (isNetworkError(reason)) {
            return ({
                title: reason.message??"网络请求错误"
            })
        } else if (typeof reason === 'string'){
            return ({
                title: reason as string,
            })
        } else if (reason.status === 0){
            //忽略status ==0 的响应错误 以后在考虑
            // return ({
            //
            // })
        }
        return {
            type: "UNKNOWN",
            title: "出错了！"
        }
    }

    export function error(message: ErrorMessage) {
        // report(new Error(message.title))
        // exception(`report: ${message.title}`, false)
        Toast.show({
            icon: null,
            content: message.title
        })
    }
    export function handle(reason: any) {
        console.log(`reason:`, reason)
        const message = format(reason);
        error(message)
        if (message.type === 'UNKNOWN') {
            console.warn(`无法识别错误：`, reason)
        }
    }
}
