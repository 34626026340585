import Cookies from "js-cookie";
import {omitUndefined} from "@firefly/fly-defter";
import { SESSION_KEY_ADVERT } from "./config";


export interface Advert {
    landingUri: string;
    landing: string;
    sem: string;
    identifier?: string;
    keyword?: string
}
export namespace Adverts {

    export function store(advert: Advert) {
        sessionStorage.setItem(SESSION_KEY_ADVERT, encode(advert))
    }
    export function get(): Advert | null {
        const value = Cookies.get(SESSION_KEY_ADVERT);
        if (value) {
            try {
                const params = new URLSearchParams(value);
                return {
                    sem: params.get('sem') as string,
                    landingUri: params.get('landingUri') as string,
                    landing: params.get('landing') as string,
                    identifier: params.get('identifier')??undefined,
                    keyword: params.get('keyword')??undefined,
                }
            } catch (e) {

            }

        }
        return null;
    }

    export function encode(advert: Advert) {
        const params = new URLSearchParams(omitUndefined(advert));
        return params.toString()
    }
}