import {useGraphQLClient} from "@firefly/fly-security";
import {useQuery, useSuspenseQuery} from "@tanstack/react-query";
import gql from "fake-tag";
import {ProjectVariant} from "@firefly/fly-design-inv-data";

function useQueryFn(project: string, identifiers: string[]) {
    const graphqlClient = useGraphQLClient();
    return () => {
        return graphqlClient.query({
            query: gql`
                query variants($project: String!, $identifiers: [String!]) {
                    projectVariants(project: $project, identifiers: $identifiers) {
                        project,
                        identifier,
                        title,
                        description,
                        pricing {
                            amount,
                            originalAmount,
                        }
                    }
                }
            `,
            variables: {
                project: project,
                identifiers: identifiers,
            }
        }).then((output) => {
            return output.projectVariants
        })
    }
}
export function useProjectVariantsSuspenseQuery(project: string, identifiers: string[]) {
    return useQuery<ProjectVariant[]>({
        queryKey: ['project-variant', project, identifiers],
        queryFn: useQueryFn(project, identifiers)
    })
}
export function useProjectVariantsQuery(project: string, identifiers: string[]) {
    return useQuery<ProjectVariant[]>({
        queryKey: ['project-variant', project, identifiers],
        queryFn: useQueryFn(project, identifiers)
    })
}
