import React from "react";
import {Navigate} from "react-router-dom";
import {WechatMenuConfig} from "@firefly/fly-bridge";
import {SecuredBoundary as FlySecuredBoundary} from "@firefly/fly-security";
import {Flex, Loading} from "@firefly/fly-kda";

export interface SecuredBoundaryProps {
    disabledShare?: boolean;
    children: React.ReactNode;
}

function SecuredPageBoundary(props: SecuredBoundaryProps): React.ReactElement {

    const {
        disabledShare = true,
        children
    } = props;

    function fallback() {

        //页面级拦截 直接引导到登录页面
        return <Navigate
            to={`/login?return=${encodeURIComponent(window.location.href)}`}
            replace
        />
    }
    return <WechatMenuConfig
        disabledShare={disabledShare}
    >
        <FlySecuredBoundary
            loading={
                <Flex align={"center"} justify={"center"} style={{height: "100%"}}>
                    <Loading />
                </Flex>
            }
            fallback={fallback}
        >
            {children}
        </FlySecuredBoundary>
    </WechatMenuConfig>
}

export default SecuredPageBoundary;