import {ReactNode} from "react";
import styled from "styled-components";


export interface PanelProps {
    title: string;
    subtitle?: string;
    onMoreClick?: () => void;
    children?: ReactNode;
}

function Panel({
    title,
    subtitle,
    onMoreClick,
    children
}: PanelProps) {
    return <div>
        <Header>
            <HeaderContent>
                <Title>
                    {title}
                </Title>
                {subtitle ? <Subtitle>
                    {subtitle}
                </Subtitle> : null}
            </HeaderContent>
            <MoreLink onClick={(e: any) => {
                onMoreClick?.();
                e.preventDefault();
            }}>
                查看更多
            </MoreLink>
        </Header>
        <div className={"panel-body"}>
            {children}
        </div>
    </div>
}

const Header = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`
const HeaderContent = styled.div`
  flex: 1;
  text-align: left;
`
const Title = styled.span`
  color: #000;
  font-weight: bold;
  font-size: 15px;
`
const Subtitle = styled.span`
  color: #999;
  margin-left: 2vw;
  font-size: 2.8vw;
`
const MoreLink = styled.a`
  font-size: 13px;
  font-weight: 600;
  color: #000;
  cursor: pointer;
`
export default Panel;
