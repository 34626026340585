import {useMemo} from "react";
import {PaymentMethod, usePaymentMethods} from "@firefly/fly-payment";
import {GLOBAL} from "../config";


function useConfigPaymentMethods(): PaymentMethod[] {
    const methods = useMemo<PaymentMethod[]>(() => {
        return GLOBAL.paymentMethods as PaymentMethod[];
    }, []);

    return usePaymentMethods(methods);
}

export default useConfigPaymentMethods;