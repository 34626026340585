import {cloneElement, isValidElement, PropsWithChildren, useContext} from "react";
import MediaQuery, {EdgeInsets} from "./MediaQuery";
import RemovePaddingMediaQuery from "./RemovePaddingMediaQuery";


interface SafeAreaPaddingProps {
    top?: boolean;
    bottom?: boolean;
    left?: boolean;
    right?: boolean;
}

function SafeAreaPadding({
    top = false, bottom = false, left = false, right = false, children
}: PropsWithChildren<SafeAreaPaddingProps>) {
    const mediaQuery = useContext(MediaQuery);
    const { padding: mediaQueryPadding } = mediaQuery;
    const padding: EdgeInsets = {
        left: left ? mediaQueryPadding.left : 0,
        top: top ? mediaQueryPadding.top : 0,
        right: right ? mediaQueryPadding.right : 0,
        bottom: bottom ? mediaQueryPadding.bottom : 0,
    }
    return (
        <RemovePaddingMediaQuery
            removeLeft={left}
            removeTop={top}
            removeRight={right}
            removeBottom={bottom}
        >
            {isValidElement(children) ?
                cloneElement(children, {
                    style: {
                        paddingTop: padding.top,
                        paddingBottom: padding.bottom,
                        paddingLeft: padding.left,
                        paddingRight: padding.right
                    }
                } as any)
                :
                null
            }
        </RemovePaddingMediaQuery>
    )
}

export default SafeAreaPadding;
