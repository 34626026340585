// import {Empty, List} from "react-vant";
import {useViewerOrdersSuspenseInfiniteQuery} from "src/hook/order";
import OrderCard from "./OrderCard";
import {OrderItem, OrderListLayout} from "./styles";
// import InfiniteList from "../../../kda/InfiniteList";
import {useMemo} from "react";
import {Order} from "@firefly/fly-design-inv-data";
// import Empty from "../../../kda/Empty";
import {InfiniteList} from "@firefly/fly-kda-mobile";
import {Empty} from "@firefly/fly-kda";
import Indicator from "../../../component/Indicator";


function OrderList() {

    const {
        data,
        hasNextPage,
        isFetching,
        fetchNextPage,
        error,
    } = useViewerOrdersSuspenseInfiniteQuery({
        paymentState: "PAID"
    })
    const items = useMemo(() => {
        return data?.pages.reduce((result: Order[], page) => {
            if (page.nodes) {
                return result.concat(page.nodes);
            }
            return result;
        }, [])
    }, [data])
    const total = (data?.pages??[]).reduce((value, page) => {
        return value + page.nodes.length
    }, 0)
    return <InfiniteList
        dataSource={items}
        hasMore={hasNextPage}
        loadMore={() => {
            return fetchNextPage().then()
        }}
        empty={() => {
            return <Empty
                description={"还没有订单"}
            />
        }}
    >
        {(records) => {
            return <OrderListLayout>
                {records.map((record) => {
                    return <OrderItem key={record.id}>
                        <OrderCard
                            key={record.id}
                            value={record}
                        />
                    </OrderItem>
                })}
            </OrderListLayout>
        }}
    </InfiniteList>
    // return <OrderListLayout>
    //     <List finished={!hasNextPage} onLoad={() => {
    //         return fetchNextPage().then();
    //     }} errorText={!!error}>
    //         {}
    //     </List>
    //     {!isFetching && total === 0 ? <>
    //         <Empty description="还没有订单" />;
    //     </> : null}
    // </OrderListLayout>
}

export default Indicator.withSuspense(OrderList);
