import React, {CSSProperties, ReactNode} from "react";
import classNames from "classnames";


export interface TagProps {
    className?: string;
    style?: CSSProperties;
    checked?: boolean;
    disabled?: boolean;
    onClick?: (active: boolean, event: React.MouseEvent) => void;
    children?: ReactNode;
}

function Tag({
    className, style, checked = false, disabled = false, onClick, children
}: TagProps, ref: React.ForwardedRef<HTMLDivElement>) {
    return <div ref={ref} className={classNames('fd-tag', {
        'fd-tag--checked': checked,
        'fd-tag--disabled': disabled,
    }, className)} style={style} onClick={event => {
        onClick?.(!checked, event)
    }}>
        {children}
    </div>
}

export default React.forwardRef<HTMLDivElement, TagProps>(Tag);
