import {AdvertAffix, AdvertImage, Layout} from "./styles";
import MAKE_SERVICE_URL from "src/asset/guide/make-service.png";
import {useNavigate} from "react-router";

interface GuideFloatingAdvertProps {

}
function GuideFloatingAdvert(props: GuideFloatingAdvertProps) {
    const {

    } = props;

    const navigate = useNavigate();
    function navigateToHelp() {
        navigate(`/payment/make-payment`)
        // navigateToCustomerService()
    }
    return <AdvertAffix>
        <Layout onClick={navigateToHelp}>
            <AdvertImage
                src={MAKE_SERVICE_URL}
            />
        </Layout>
    </AdvertAffix>
}

export default GuideFloatingAdvert;
