import React, {useState} from "react";
import {ReactComponent as ShareIcon} from "src/asset/share2.svg";
import WechatShareGuidePopup from "../WechatShareGuidePopup";
import GridItem from "./GirdItem";

interface WechatShareGridItemProps {
    icon?: React.ReactNode;
    text?: string;
}
function WechatShareGridItem(props: WechatShareGridItemProps) {
    const {
        icon = <ShareIcon />,
        text = "转发好友"
    } = props;
    const [visible, setVisible] = useState<boolean>(false);
    return <>
        <GridItem
            {...props}
            icon={
                icon
            }
            text={text}
            onClick={() => {
                setVisible(true)
            }}
        />
        <WechatShareGuidePopup
            visible={visible}
            onClose={() => {
                setVisible(false)
            }}
        />
    </>
}

export default WechatShareGridItem;
