import styled from "styled-components";
import device from "src/device";
import {Link} from "react-router-dom";


export const FooterLayout = styled.div`
  color: #909090;
  padding: 30px 0;
  left: 0;
  right: 0;
  text-align: center;
  @media (${device.pc}) {
    font-size: 12px;
    padding: 30px 0;
  }
`;

export const FooterNav = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  gap: 10px;
`

export const ContactLink = styled(Link)`
  font-size: 16px;
  font-weight: bold;
  color: #000;
`
export const AboutUs = styled.div`

`

export const Statement = styled.div`
  font-size: 13px;
    
    
`
export const OutChainLink = styled.a`
  color: #909090;
  margin-right: 10px;
  
  @media (${device.pc}) {
    margin: 0 15px;
    &:hover {
      text-decoration: underline;
    }
  }
`

export const Copyright = styled.div`
    margin-top: 8px;
`
