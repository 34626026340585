import {Wechat} from "@react-vant/icons";
import {useNavigateToWeapp, WeappJump} from "src/suppport/router";
import GridItem from "./GirdItem";


interface WeappGridItemProps {
    enabled?: boolean;
    jump?: WeappJump
}

function WeappGridItem(props: WeappGridItemProps) {
    const {
        jump = {},
    } = props;

    const navigateWeapp = useNavigateToWeapp();
    // const {
    //     mutateAsync
    // } = useWechatWeappURLSchemeGenerateMutation();
    function onClick() {
        navigateWeapp(jump)
        // mutateAsync({
        //     jump: jump
        // }).then((data) => {
        //     window.location.href = data.url;
        // })
        // window.location.href = `weixin://dl/business/?t=cIAB3tuWdSu`;
    }
    return <GridItem
        {...props}
        icon={<Wechat fill={"#1eb51e"}  />}
        text={"微信打开"}
        onClick={onClick}
    />
}

export default WeappGridItem;
