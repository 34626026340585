import React from "react";
import {Layout, LayoutExtra, LayoutIcon, LayoutSubtitle, LayoutTitle} from "./styles";
import {Checked} from "@react-vant/icons";


interface ResultProps {
    className?: string;
    style?: React.CSSProperties;
    status?: 'success' | 'error' | 'info';
    icon?: React.ReactNode;
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    //操作区
    extra?: React.ReactNode;
}
const colors: Record<string, string> = {
    "success": "#5dba6b"
}
function Result(props: ResultProps) {
    const {
        className,
        style,
        status = 'success',
        icon,
        title,
        subtitle,
        extra,
    } = props;

    return <Layout className={className} style={style}>
        <LayoutIcon>
            {icon ?
                icon :
                <Checked  fontSize={66} color={colors[status]} />
            }
        </LayoutIcon>
        <LayoutTitle>{title}</LayoutTitle>
        <LayoutSubtitle>{subtitle}</LayoutSubtitle>
        <LayoutExtra>
            {extra}
        </LayoutExtra>
    </Layout>
}


export default Result;
