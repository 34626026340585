import {useQuery} from "@tanstack/react-query";
import gql from "fake-tag";
import {RequestOptions} from "@firefly/fly-http";
import {GraphQLClient, isAccessDeniedException, useGraphQLClient} from "@firefly/fly-security";
import {Userinfo} from "src/context/security";
import {UID_SESSION_STORAGE_KEY} from "../../config";


const viewerQueryKey = ['viewer'];

export function executeQuery(graphqlClient: GraphQLClient, options: RequestOptions): Promise<Userinfo | null> {
    return graphqlClient.query({
        query: gql`
            {
                viewer {
                    uid,
                    userId,
                    nickname,
                    avatar,
                    gender,
                    vip {
                        expiresAt
                    }
                    mobile {
                        identityType,
                        group,
                        identifier
                    },
                    email {
                        identityType
                        group,
                        identifier
                    }
                }
            }
        `,
        variables: {}
    }, options).then(data => {
        const viewer = data.viewer;
        if (viewer) {
            sessionStorage.setItem(UID_SESSION_STORAGE_KEY, viewer.uid)
        }
        return viewer;
    }, (reason) => {
        if (isAccessDeniedException(reason)) {
            return null;
        }
        throw reason;
    })
}
export function useViewerQuery(options: {
    enabled?: boolean
} = {}) {
    const {
        enabled
    } = options;
    const graphqlClient = useGraphQLClient();
    const {
        isLoading,
        data: viewer,
        refetch,
    } = useQuery<Userinfo | null>({
        queryKey: viewerQueryKey,
        queryFn: () => executeQuery(graphqlClient, {}),
        retry: false,
        retryOnMount: false,
        enabled: enabled,
    });


    return {
        isLoading,
        viewer,
        refetch,

    }
}