import DocumentTitle from "react-document-title";
import NavBar from "src/page/component/NavBar";
import {
    ContactGroup,
    Page,
    PageBody,
    Section,
    SectionBody,
    SectionHeader,
    ServiceAvatar,
    ServiceBody,
    ServiceDescription,
    ServiceTime,
    ServiceTitle,
} from "./styles";
// import {Typography} from "react-vant";
import {ClockO, ServiceO} from "@react-vant/icons";
import {Button} from "antd-mobile";
import { Typography } from "@firefly/fly-kda";
import ContactButton from "./ContactButton";


function HelpCenter() {

    function navigateToWechatContact() {
        window.location.href = `https://work.weixin.qq.com/kfid/kfc9b520c76bd0d154a`;
    }
    return <DocumentTitle title={"帮助中心"}>
        <Page>
            <NavBar title={"帮助中心"}/>
            <PageBody>
                <Section>
                    <SectionHeader>联系我们</SectionHeader>
                    <SectionBody>
                        <ServiceDescription>
                            <ServiceAvatar>
                                <ServiceO  />
                            </ServiceAvatar>

                            <ServiceTitle>
                                在线客服
                            </ServiceTitle>
                            <ServiceBody>
                                <ServiceTime>
                                    <ClockO  /> 上午9:00 —— 晚上21:00
                                </ServiceTime>

                                <Typography.Text type="secondary">节假日休息</Typography.Text>
                            </ServiceBody>
                        </ServiceDescription>

                    </SectionBody>
                </Section>

                <ContactGroup>
                    <ContactButton>
                        联系在线客服
                    </ContactButton>
                </ContactGroup>

            </PageBody>
        </Page>
    </DocumentTitle>
}

export default HelpCenter;
