import {PAYMENT_METHOD_CONFIG_MAP} from "src/config";
import React from "react";
import cls from "classnames";
import {PaymentMethod} from "@firefly/fly-payment";
import {ReactComponent as CheckIcon} from "src/asset/form/checked.svg";
import {ReactComponent as CircleIcon} from "src/asset/form/circle.svg";
import {useMergedState} from "@firefly/fly-hook";
import "./index.scss";


interface PaymentMethodSelectProps {
    methods?: PaymentMethod[];
    selectedValue?: PaymentMethod;
    onSelect?: (value: PaymentMethod) => void;
}

function PaymentMethodSelect(props: PaymentMethodSelectProps) {
    const {
        selectedValue,
        onSelect,
        methods = []
    } = props;

    const [value, setValue] = useMergedState<PaymentMethod>(selectedValue!, {
        onChange: onSelect
    })
    return (
        <div className={cls("order-payment")}>
            {/*<div className={cls("order-payment-title")}>*/}
            {/*    选择支付方式*/}
            {/*</div>*/}
            <ul className={cls(("payment-method"))}>
                {methods.map((method: PaymentMethod) => {
                    const config = PAYMENT_METHOD_CONFIG_MAP[method];
                    return (
                        <li
                            key={method}
                            className={cls("payment-method-item")}
                            onClick={() => {
                                setValue(method);
                            }}
                        >
                            <span className={"payment-method-icon"}>{config.icon}</span>
                            <span className={"payment-method-title"}>{config.title}</span>
                            <div className={"payment-method-radio"}>
                                {method === value ? <>
                                    <CheckIcon/>
                                </> : <>
                                    <CircleIcon/>
                                </>}
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
    // return  <PaymentLayout>
    //     <Radio.Group
    //         value={selectedValue}
    //     >
    //         <Cell.Group>
    //             {methods.map((method, index) => {
    //                 const {
    //                     title,
    //                     icon,
    //                 } = PAYMENT_METHOD_CONFIG_MAP[method];
    //                 return <Cell
    //                     key={method}
    //                     clickable
    //                     title={title}
    //                     icon={icon}
    //                     rightIcon={<Radio name={method} />}
    //                     onClick={() => {
    //                         onSelect?.(method);
    //                     }}
    //                 />
    //             })}
    //         </Cell.Group>
    //     </Radio.Group>
    // </PaymentLayout>
}

export default PaymentMethodSelect;
