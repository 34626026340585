import React, {useContext, useEffect, useRef} from "react";
import RestorationContext from "./RestorationContext";

import {addEventListener} from "@firefly/fly-hook";


interface ScrollRestorationProps {
    restorationKey: string | string[] | any[];
    children: React.ReactElement;
}

/**
 * TODO 没设计好  暂时 只在首页使用 ，不要在push页面
 * @param props
 * @constructor
 */
function ScrollRestoration(props: ScrollRestorationProps) {
    const {
        restorationKey,
        children
    } = props;

    const {
        restoration
    } = useContext(RestorationContext);

    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        const key = JSON.stringify(restorationKey);
        const element = ref.current!;
        // addEventListener(element, )
        const restorationElement = restoration.mountElement(key, element)
        restorationElement.resume();
        function onScroll() {
            restorationElement.save()
        }
        const subscriber = addEventListener(element, 'scroll', onScroll)
        return () => {
            subscriber.remove();
        }
    }, [restoration, restorationKey])



    return React.cloneElement(children, {
        ref: ref,
    })

}

export default ScrollRestoration;
