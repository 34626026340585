import {Button, Selector} from "antd-mobile";
import {createNamespace, Flex} from "@firefly/fly-kda";
import cls from "classnames";
import React from "react";
import {TemplateDoctypeOptionType, TemplateSceneOptionType, TemplateSceneValueType} from "../../../typing/template";
import {useMergedState} from "@firefly/fly-hook";


const [bem] = createNamespace("template-query-form");

export interface FormItemProps {
    label: React.ReactNode;
    children?: React.ReactNode;
}
function FormItem(props: FormItemProps): React.ReactElement {
    const {
        label,
        children
    } = props;
    return <div className={cls(bem("item"))}>
        <div className={cls(bem("prefix"))}>
            <label className={cls(bem("title"))}>
                {label}
            </label>
        </div>
        <div className={cls(bem("main"))}>
            {children}
        </div>
    </div>
}

export interface TemplateQueryFormValueType {
    scene: TemplateSceneValueType | undefined;
    doctype: string | undefined;
}
export interface TemplateQueryFormProps {
    sceneOptions: TemplateSceneOptionType[];
    doctypeOptions: TemplateDoctypeOptionType[];
    initialValue: TemplateQueryFormValueType;
    onReset: () => void;
    onSubmit: (value: TemplateQueryFormValueType) => void;
}
function TemplateQueryForm(props: TemplateQueryFormProps) {
    const {
        sceneOptions,
        doctypeOptions,
        initialValue,
        onReset,
        onSubmit,
    } = props;

    const [value, setValue] = useMergedState(initialValue, {

    })
    function set(name: string, v: string | undefined) {
        setValue({
            ...value,
            [name]: v
        })
    }
    return <div className={cls(bem())} style={{padding: 12}}>
        <div className={cls(bem("body"))}>
            <FormItem label={"场景"}>
                <Selector
                    columns={3}
                    options={sceneOptions}
                    value={value.scene ? [value.scene] : []}
                    onChange={v => {
                        set("scene", v[0])
                    }}
                />
            </FormItem>
            <FormItem label={"类型"}>
                <Selector
                    columns={3}
                    options={doctypeOptions}
                    value={value.doctype ? [value.doctype] : []}
                    onChange={v => {
                        set('doctype', v[0])
                    }}
                />
            </FormItem>
        </div>
        <div className={cls(bem("footer"))}>
            <Flex gutter={16}>
                <Flex.Item flex={1}>
                    <Button block className={"secondary"} onClick={onReset}>重置</Button>
                </Flex.Item>
                <Flex.Item flex={1}>
                    <Button block color={"primary"} onClick={() => {
                        onSubmit(value)
                    }}>完成</Button>
                </Flex.Item>
            </Flex>
        </div>
    </div>
}

export default TemplateQueryForm;