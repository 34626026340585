import React from "react";
import {Grid as AntdGrid} from "antd-mobile";

export interface GridProps {
    className?: string;
    style?: React.CSSProperties;
    columns: number;
    children?: React.ReactNode;
}
function Grid(props: GridProps): React.ReactElement {
    const {
        className,
        style,
        columns,
        children
    } = props;
    return <AntdGrid className={className} style={style} columns={columns}>
        {children}
    </AntdGrid>
}

export default Grid;