import React, {useEffect} from "react";
import {useSecurityClient} from "@firefly/fly-security";
import { sem as reportSem } from "src/util/reporter";
import useAdvert from "../trace/useAdvert";


interface SEMTraceProviderProps {
    children: React.ReactNode;
}
function SEMTraceProvider(props: SEMTraceProviderProps) {
    const {
        children
    } = props;


    const client = useSecurityClient();
    const {
        principal
    } = client.authenticationManager;

    const advert = useAdvert();
    useEffect(() => {
        if (advert) {
            const {
                sem,
                landingUri,
                keyword
            } = advert;

            if (principal && sem) {
                reportSem({
                    sem: sem,
                    keyword: keyword,
                    link: landingUri,
                })
            }
        }
    }, [principal, advert])
    return <React.Fragment>
        {children}
    </React.Fragment>
}

export default SEMTraceProvider;
