import DocumentTitle from "react-document-title";
import NavBar from "src/page/component/NavBar";
import useStarredTemplateInfiniteQuery from "src/hook/useStarredTemplateInfiniteQuery";
import classNames from "classnames";
import Waterfall from "src/component/Waterfall";
import React, {useMemo} from "react";
import {SizeType} from "src/typing";
import TemplateCard from "src/component/TemplateCard";
import "./index.scss";
import {useNavigateToTemplate} from "src/suppport/router";
import {DEFAULT_COVER_SIZE, GLOBAL} from "src/config";
import {ViewerTemplate, ViewerWorkTemplate} from "@firefly/fly-design-inv-data";
import cls from "classnames";
import InfiniteScroll from "src/component/InfiniteScroll";
import {createNamespace, Empty, Loading} from "@firefly/fly-kda";
import Indicator from "src/component/Indicator";

const [bem] = createNamespace("me-starred-template-waterfall")
interface MeStarredTemplateWaterfallProps {
    className?: string;

}
function MeStarredTemplateWaterfall(props: MeStarredTemplateWaterfallProps) {
    const {
        className,
    } = props;
    const {
        data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        hasMore,
        loadMore,
        items,
    } = useStarredTemplateInfiniteQuery();
    const navigateToTemplate = useNavigateToTemplate();

    function onItemClick(item: ViewerTemplate) {
        navigateToTemplate(item);
    }
    // const total = (data?.pages??[]).reduce((value, page) => {
    //     return value + page.edges.length
    // }, 0);
    const {
        column,
        gutter,
        size,
    } = useMemo<{
        gutter: number;
        column: number;
        size: SizeType;
    }>(() => {
        if (window.matchMedia('(min-width: 1000px)').matches) {
            return {
                gutter: 14,
                column: 6,
                size: 'large',
            };
        }
        if (window.matchMedia('(min-width: 600px)').matches) {
            return {
                gutter: 12,
                column: 4,
                size: 'large',
            };
        }
        return {
            gutter: 10,
            column: 2,
            size: 'large',
        };
    }, [])
    function renderContent() {
        if (isLoading) {
            return <div className={cls(bem("placeholder"))}>
                <Loading />
            </div>
        }
        if (Number(items?.length) === 0) {
            return <div className={cls(bem("placeholder"))}>
                <Empty description={"还没有收藏模板"}/>
            </div>
        }
        return <React.Fragment>
            <Waterfall width={window.innerWidth} gutter={gutter} column={column} >
                {data?.pages.map(({nodes}) => {
                    return nodes.map((node) => {
                        const {
                            id,
                            cover,
                        } = node;
                        const coverSize = cover ? cover : {width: 200, height: 200};
                        const width = coverSize.width || 1;
                        const height = coverSize.height || 1;
                        const caleHeight = Math.min(width * 2, height)  + 42;
                        return <Waterfall.Cell
                            key={id}
                            width={width}
                            height={caleHeight}
                        >
                            <TemplateCard
                                value={node as ViewerWorkTemplate}
                                width={"100%"}
                                minFit={0.5}
                                size={size}
                                hiddenPayment={GLOBAL.template.paymentHidden}
                                // blur
                                onClick={() => {
                                    navigateToTemplate(node);
                                }}
                            />
                        </Waterfall.Cell>
                    })
                })}
            </Waterfall>
            <InfiniteScroll
                hasMore={hasMore}
                loadMore={loadMore}
            />
        </React.Fragment>
    }
    return <div className={classNames(bem(), className)}>
        {renderContent()}
        {/*{total > 0 ?  <>*/}
        {/*    <Waterfall width={window.innerWidth} gutter={gutter} column={column}>*/}
        {/*        {data?.pages.map(({edges}) => {*/}
        {/*            return edges.filter(({node}) => {*/}
        {/*                return !!node;*/}
        {/*            }).map(({node}) => {*/}
        {/*                const {*/}
        {/*                    id,*/}
        {/*                    cover,*/}
        {/*                } = node;*/}
        {/*                const coverSize = cover ? cover : DEFAULT_COVER_SIZE;*/}
        {/*                const width = coverSize.width || 1;*/}
        {/*                const height = coverSize.height || 1;*/}
        {/*                const caleHeight = Math.min(width * 2, height);*/}
        {/*                return <Waterfall.Cell*/}
        {/*                    key={id}*/}
        {/*                    width={width}*/}
        {/*                    height={caleHeight}*/}
        {/*                >*/}
        {/*                    <TemplateCard*/}
        {/*                        key={id}*/}
        {/*                        className={"me-starred-templates-waterfall-item"}*/}
        {/*                        value={node as any}*/}
        {/*                        maxFit={2}*/}
        {/*                        size={size}*/}
        {/*                        hiddenPayment={GLOBAL.template.paymentHidden}*/}
        {/*                        onClick={() => {*/}
        {/*                            onItemClick?.(node);*/}
        {/*                        }}*/}
        {/*                    />*/}

        {/*                </Waterfall.Cell>*/}
        {/*            })*/}
        {/*        })}*/}
        {/*    </Waterfall>*/}
        {/*</> : <>*/}
        {/*    <Empty description="还没有收藏模板" />*/}
        {/*</>}*/}
        {/*{total > 0 ?*/}
        {/*    <Footer enabled={!isFetchingNextPage}*/}
        {/*            isFetchingNextPage={isFetchingNextPage}*/}
        {/*            hasNextPage={hasNextPage}*/}
        {/*            fetchNextPage={fetchNextPage} />*/}
        {/*    :*/}
        {/*    <>*/}
        {/*    </>*/}
        {/*}*/}
    </div>
}
function Index() {


    return <DocumentTitle title={"我的收藏"}>
        <div className={"me-starred-templates"}>
            <NavBar title={"我的收藏"} />
            <div className={"me-starred-templates-body"}>
                <Indicator.Suspense>
                    <MeStarredTemplateWaterfall />
                </Indicator.Suspense>
            </div>
        </div>
    </DocumentTitle>
}

export default Index;
