import {useContext} from "react";
import {PropertyPath, get} from "@firefly/fly-defter";
import WebappConfigContext from "./WebappConfigContext";


export interface UseConfigQueryType<T> {
  value: T | null;
  isPending: boolean;
}
function useConfigQuery<T>(path: PropertyPath): UseConfigQueryType<T> {

  const {
    value,
    isPending
  } = useContext(WebappConfigContext);
  return {
    isPending,
    value: value ? get(value, path) : null
  }
}

export default useConfigQuery;
