import {useParams} from "react-router";
import DocumentTitle from "react-document-title";
import cls from "classnames";
import {Share, WechatAppBridge} from "@firefly/fly-bridge";
import {usePageQuery, useCreatePageSharePosterMutation} from "src/hook/page";
import {ImageContainer, ImageContent, ImageWrapper, Page, PageAction, PageBody, PosterTip} from "./styles";
import React, {useEffect, useState} from "react";
import {Picture} from "@firefly/fly-design-core";
// import {NoticeBar} from "react-vant";
import NavBar from "src/page/component/NavBar";
import {isWeapp, isWechat} from "@firefly/fly-defter";
import {useNavigate} from "react-router-dom";
import {page2Link} from "src/util/web";
import {Invitations} from "@firefly/fly-design-inv-core";
import {Button, NoticeBar} from "antd-mobile";
import { Loading, createNamespace } from "@firefly/fly-kda";
import "./style/index.scss";


const [bem] = createNamespace('page-share-poster')

function PageSharePoster() {
    const { id } = useParams();

    const {
        data: page
    } = usePageQuery(id!)
    const {
        mutateAsync,
        isPending,
    } = useCreatePageSharePosterMutation();
    const [poster, setPoster] = useState<Picture>()

    useEffect(() => {
        mutateAsync({
            id: id
        }).then((picture) => {
            setPoster(picture)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    const navigate = useNavigate();
    function navigateToHome() {
        navigate('/')
    }

    //提示
    const link = page2Link(id!);

    return <DocumentTitle title={"生成海报"}>
        <WechatAppBridge>
            <Share
                title={page?.title}
                description={page?.description}
                coverUrl={page ? Invitations.shareCover(page.preview, page.coverBox)! : undefined}
                link={link}
            >
                <Page className={cls(bem())}>
                    <NavBar title={"生成海报"} />
                    <PageBody>
                        {!isWechat() || isWeapp() ? <>
                            <NoticeBar
                                icon={null}
                                color="alert"
                                content={"海报保存到本地，通过微信扫一扫进行微信分享"}
                            />
                        </>:null}



                        {isPending ? (
                            <div className={cls(bem("placeholder"))}>
                                <Loading
                                    // fullScreen
                                    tip={"正在生成海报..."}
                                >

                                </Loading>
                            </div>
                        ) : null}
                        {poster ? <ImageWrapper>
                            <ImageContainer>
                                <ImageContent
                                    src={poster.url}
                                    onClick={() => {
                                        if (isWechat()) {
                                            wx.previewImage({
                                                current: '', // 当前显示图片的 http 链接
                                                urls: [] // 需要预览的图片 http 链接列表
                                            })
                                        }
                                    }}
                                />
                                <PosterTip>
                                    长按图片保存到本地
                                </PosterTip>
                            </ImageContainer>
                        </ImageWrapper>: null}
                    </PageBody>
                    <PageAction>
                        <Button
                            className={"fly-button-primary"}
                            block color={"primary"} onClick={navigateToHome}>
                            回到主页
                        </Button>
                    </PageAction>
                </Page>
            </Share>
        </WechatAppBridge>
    </DocumentTitle>
}

export default PageSharePoster;
