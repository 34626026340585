import React from "react";
import {Outlet} from "react-router";
import "./index.scss";
import MainTabBar from "./MainTabBar";
import {ScrollRestoration} from "src/restoration";
import {Share} from "@firefly/fly-bridge";
// import {HomepagePage, HomepagePageBody} from "./styles";


function Index() {


    const homepage = `${window.location.protocol}//${window.location.host}`
    return <div className={"homepage"}>
        <Share
            title={process.env.REACT_APP_TITLE}
            description={process.env.REACT_APP_DESCRIPTION}
            coverUrl={process.env.REACT_APP_LOGO}
            link={homepage}
        >
            <ScrollRestoration restorationKey={'homepage'}>
                <div className={"homepage-body"}>
                    <Outlet />
                </div>
            </ScrollRestoration>
        </Share>
        <MainTabBar />
    </div>
}

export default Index;
