import React, {useState} from "react";
import cls from "classnames";
import {createNamespace, Empty, Result} from "@firefly/fly-kda";
import {InfiniteList} from "@firefly/fly-kda-mobile";
import {InvitationDesign} from "@firefly/fly-design-inv-data";
import "./style/index.scss";
import {useViewerDesignsSuspenseInfiniteQuery} from "../../../hook/design";
import DesignCard2 from "../DesignCard2";
import dayjs from "dayjs";
import {DOCUMENT_TYPE_LABEL_MAP} from "src/config";
import { ReactComponent as EditSVG } from "src/asset/service/edit.svg";
import { ReactComponent as SeeSVG } from "src/asset/service/see.svg";
import { ReactComponent as MoreSVG } from "src/asset/service/more.svg";
import {useNavigateToDesignDesigner} from "../../../navigation/useNavigateToDesignDesigner";
import useNavigateToDesignPreview from "../../../navigation/useNavigateToDesignPreview";
// import {Button} from "../../../fly-ui";
// import Empty from "../../../kda/Empty";
import {useNavigate} from "react-router-dom";
import {Button} from "antd-mobile";
import DesignActionSheet from "../DesignActionSheet";
import SecuredBoundary from "src/security/SecuredBoundary";



const [bem] = createNamespace("viewer-design-list");
export interface MeDesignListProps {
    className?: string;
    style?: React.CSSProperties;
}
function ViewerDesignList(props: MeDesignListProps): React.ReactElement {
    const {
        className,
        style
    } = props;

    const {
        hasNextPage,
        fetchNextPage,
        items,
        error,
    } = useViewerDesignsSuspenseInfiniteQuery();
    const navigateToDesignDesigner = useNavigateToDesignDesigner();
    const navigateToPreview = useNavigateToDesignPreview();
    function onItemClick(item: InvitationDesign) {
        navigateToDesignDesigner(item, {
        }).then();
    }
    const navigate = useNavigate();
    const [selected, setSelected] = useState<InvitationDesign | null>(null);
    return <React.Fragment>
        <InfiniteList
            className={cls(className, bem())} style={style}
            // className={cls(bem("wrap"))}
            dataSource={items}
            hasMore={hasNextPage}
            loadMore={() => {
                return fetchNextPage().then()
            }}
            error={error ? (
                <Empty
                    description={error.message}
                />
            ) : null}
            empty={() => {
                // return <Result
                //
                //     description={"尚未创建邀请函"}
                //     extra={
                //         <Button
                //             className={"fly-button-primary"}
                //             color={"primary"}
                //             shape={"rounded"}
                //             block
                //             onClick={() => {
                //             navigate("/homepage/templates");
                //         }}>
                //             去制作
                //         </Button>
                //     }
                // />
                return <Empty
                    description={"尚未创建邀请函"}
                    extra={
                        <Button
                            className={"fly-button-primary"}
                            color={"primary"}
                            shape={"rounded"}
                            block
                            // size={"large"}
                            onClick={() => {
                                navigate("/homepage/templates");
                            }}
                        >
                            去制作
                        </Button>
                    }
                />
            }}
        >
            {(records) => {
                return <div className={cls(bem("content"))}>
                    {records.map((record) => {
                        const {
                            doctype
                        } = record;
                        const time = dayjs(Number(record.createdAt)).format('YYYY-MM-DD HH:mm')
                        return <div className={cls(bem("item"))} key={record.id}>
                            <DesignCard2
                                key={record.id}
                                title={record.title}
                                cover={record.cover}
                                tags={[DOCUMENT_TYPE_LABEL_MAP[doctype]]}
                                time={time}
                                actions={[
                                    {
                                        icon: <EditSVG />,
                                        text: "编辑",
                                        onClick: () => {
                                            onItemClick(record);
                                        }
                                    },
                                    {
                                        icon: <SeeSVG />,
                                        text: "预览",
                                        onClick: () => {
                                            return navigateToPreview(record);
                                        }
                                    },
                                    {
                                        icon: <MoreSVG />,
                                        text: "更多",
                                        onClick: () => {
                                            setSelected(record)
                                        }
                                    }
                                ]}
                            />
                        </div>
                    })}
                </div>
            }}
        </InfiniteList>
        <DesignActionSheet
            value={selected}
            onClose={() => {
                setSelected(null)
            }}
        />
    </React.Fragment>
}

export default (props: MeDesignListProps) => {
    return (
        <SecuredBoundary
            description={"登录后才可以看到您的邀请函呦！"}
        >
            <ViewerDesignList {...props}/>
        </SecuredBoundary>
    )
};
