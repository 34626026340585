
import {useState} from "react";
import {useCreateOrderOfProjectMutation} from "src/hook/order";
import {useNavigate} from "react-router";
import {usePayment, PaymentMethod} from "@firefly/fly-payment";
import {useProjectVariantsSuspenseQuery} from "src/hook/project-variant";
import {Toast} from "antd-mobile";


function useVipPayment() {


    const {
        isPending,
        setPending,
        execute: executePayment,
        isCompleted,
    } = usePayment(['pvip']);
    const {
        isFetching: variantsLoading,
        data: variants = []
    } = useProjectVariantsSuspenseQuery("VIP", ["1m", "12m", "3m"])


    const [selectedVariantIdentifier, setSelectedVariantIdentifier] = useState<string | null>(variants[0].identifier??null);
    const options = variants.map((variant, index) => {

        const pricing = variant.pricing;
        return {
            value: variant.identifier,
            title: variant.title,
            amount: pricing.amount,
            // badge: pricing.metadata.badge,
            description: variant.description,
        }
    });
    const selectedVariant = variants.find((variant) => {
        return variant.identifier === selectedVariantIdentifier;
    })

    const {
        mutateAsync: createOrderOfProject
    } = useCreateOrderOfProjectMutation();
    //
    const navigate = useNavigate();

    function execute(method?: PaymentMethod | null) {
        if (!selectedVariantIdentifier) {
            Toast.show({
                content: "请选择"
            })
            return Promise.resolve();
        }
        return createOrderOfProject({
            "lineItems": [
                {
                    "project": "VIP",
                    "identifier": selectedVariantIdentifier
                }
            ],
            "extra": {}
        }).then((order) => {
            if (method) {
                const location = window.location;
                const url = new URL(`${location.protocol}//${location.host}/order-payment`);
                url.searchParams.set("orderId", order.paymentOrderId);
                url.searchParams.set("redirect", "homepage");

                return executePayment(order, method, {
                    returnUrl: url.toString()
                });
            } else {
                const params = new URLSearchParams();
                params.set("orderId", order.id);
                params.set("redirect", "homepage");
                navigate({
                    pathname: `/order-payment`,
                    search: params.toString()
                })
            }
        })
    }
    return {
        variantsLoading: variantsLoading,
        options: options,
        selectedVariant,
        selectedVariantIdentifier,
        setSelectedVariantIdentifier,
        execute,
        isPending,
        setPending,
        completed: isCompleted,
    }
}

export default useVipPayment;
