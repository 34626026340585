import {useEffect, useState} from "react";
import {omitUndefined} from "@firefly/fly-defter";
import {addEventListener} from "@firefly/fly-hook";
import {useQueryClient} from "@tanstack/react-query";
import {
    useNavigateToTemplatePayment,
    useNavigateToWeappTemplate,
    useRelaunchToHomepageMyDesign
} from "src/suppport/router";
import {useViewerTemplateQuery} from "src/hook/template";
import {useAddStarTemplateMutation} from "src/hook/template";
import useRemoveStarTemplateMutation from "src/hook/useRemoveStarTemplateMutation";
// import {Template} from "src/typing";
import {event, view} from "src/util/reporter";
import useTemplateViewerHasPurchasedQuery from "src/hook/useTemplateViewerHasPurchasedQuery";
import {
    RefTemplateDesignIdentifier,
    useCreateDesignByTemplateMutation,
    useViewerLatelyRefTemplateDesignQuery
} from "src/hook/design";
import {isBytedanceWebview} from "../../../util/agent";
import {GLOBAL} from "../../../config";
import {InvitationDesign, ViewerTemplate} from "@firefly/fly-design-inv-data";
import {useSecurity} from "../../../context/security";
import {useNavigateToDesignDesigner} from "../../../navigation/useNavigateToDesignDesigner";
import {Dialog} from "antd-mobile";
import {upgrade} from "@firefly/fly-design-upgrade";


interface UseTemplateType {
    isViewerLatelyRefTemplateDesignLoading: boolean;
    isFetching: boolean;
    isLoading: boolean;
    template?: ViewerTemplate;
    viewerHasOwned?: boolean;
    viewerHasStarred?: boolean;
    viewerHasPurchased?: boolean;
    star: () => Promise<void>;
    unstar: () => Promise<void>;
    enterDesign: () => Promise<void>;
    // enterPaymentOrder: () => Promise<void>;
    enterPayment: () => Promise<void>;
    enterWeappTemplate: () => Promise<void>;

    requiredPayment?: boolean;
    /**
     * 必须预支付
     */
    requiredAdvancePayment?: boolean;
}
function useTemplate(id: string, {
    // onQuerySuccess,
}: {
    // onQuerySuccess?: (template: ViewerTemplate) => void;
} = {}): UseTemplateType {
    const navigateToDesignDesigner = useNavigateToDesignDesigner();
    const relaunchToHomepageMe = useRelaunchToHomepageMyDesign()

    const {
        requestAuthenticated,
    } = useSecurity();

    const {
        isFetching,
        isLoading,
        data: template,
    } = useViewerTemplateQuery(id!, {
        map: (template) => {

            return {
                ...template,
                document: template.document ? upgrade(template.document) : undefined,
            };
        }
        // onSuccess: onQuerySuccess,
        // suspense: false,
    });
    const {
        refetch: refetchViewerHasPurchased,
        data : templateViewerHasPurchased
    } = useTemplateViewerHasPurchasedQuery(id, {
        enabled: false,
    });
    useEffect(() => {
        if (templateViewerHasPurchased) {
            const {
                viewerHasOwned,
                viewerHasPurchased,
            } = templateViewerHasPurchased;
            queryClient.setQueryData(['template', id], (data: any) => {
                return {
                    ...data,
                    viewerHasOwned,
                    viewerHasPurchased,
                }
            })
        }
    }, [templateViewerHasPurchased]);

    const {
        mutateAsync: addStarMutate
    } = useAddStarTemplateMutation();
    const {
        mutateAsync: removeStarMutate
    } = useRemoveStarTemplateMutation();
    // const {
    //     mutateAsync: createOrderOfTemplatePayment,
    // } = useCreateOrderOfTemplatePaymentMutation();

    const [latelyRefTemplateDesign, setLatelyRefTemplateDesign] = useState<RefTemplateDesignIdentifier | null>();

    const {
        isLoading: isViewerLatelyRefTemplateDesignLoading,
        data: viewerLatelyRefTemplateDesign
    } = useViewerLatelyRefTemplateDesignQuery(id, {

        // onError: (error) => {
        //     if (isUnauthenticatedError(error)) {
        //         //当前未登录忽略
        //     }
        // },
    })

    //
    useEffect(() => {
        const listener = addEventListener(document, 'visibilitychange', () => {
            const state = document.visibilityState;
            if (state === 'visible') {
                // refetch().then();
                refetchViewerHasPurchased().then();
            }
        });
        return () => {
            listener.remove();
        }
    }, [refetchViewerHasPurchased]);


    const queryClient = useQueryClient();
    // const {
    //     mutateAsync: createWorkByTemplate,
    // } = useCreateWorkByTemplateMutation();

    const {
        mutateAsync: createDesignByTemplate
    } = useCreateDesignByTemplateMutation();
    // const {
    //
    // } = useCreateDe
    function createDesign(): Promise<InvitationDesign> {
        return createDesignByTemplate({
            templateId: id!
        }).then((design: any) => {
            const target  ={
                id: design.id,
                doctype: design.doctype,
            }
            queryClient.setQueryData(['viewer', 'design', {
                'refTemplateId': id
            }], target)
            setLatelyRefTemplateDesign(target);
            return design;
        })
    }


    function enterDesign(): Promise<void> {
        if (isBytedanceWebview()) {
            //字节跳动上传文件有问题
            return enterWeappTemplate().then(() => {
                return Promise.reject();
            });
        }
        if (viewerLatelyRefTemplateDesign || latelyRefTemplateDesign) {
            return new Promise<void>((resolve) => {
                const handler = Dialog.show({
                    closeOnMaskClick: true,
                    bodyStyle: {
                      width: "300px"
                    },
                    content: '您已创建过此模板，是否继续上次编辑',
                    onClose: () => {
                        resolve();
                    },
                    actions: [
                        [
                            {
                                key: "cancel",
                                text: "不，重新创建",
                                style: {
                                    "color": "#323232"
                                },
                                onClick: () => {
                                    return createDesign().then((design) => {
                                        handler.close()
                                        return navigateToDesignDesigner(design, {

                                        });
                                    });
                                    // handler.close();
                                }
                            },
                            {
                                key: "confirm",
                                text: "确定",
                                onClick: () => {
                                    handler.close()
                                    return relaunchToHomepageMe().then();
                                }
                            }
                        ]
                    ]
                });
                // Dialog.alert({
                //     closeable: true,
                //     message: '您已创建过此模板，是否继续上次编辑',
                //     confirmButtonText: "确定",
                //     cancelButtonText: "不，重新创建",
                //     showCancelButton: true,
                //     showConfirmButton: true,
                //     onConfirm: () => {
                //         //跳转到我的页面  避免多次重新创建遗忘
                //
                //         return relaunchToHomepageMe().then();
                //
                //     },
                //     onCancel: () => {
                //
                //         return enterWithCreateDesign().then();
                //     },
                //     onClose: () => {
                //         resolve()
                //     }
                // }).then()
            })
        } else {
            return createDesign().then((design) => {
                return navigateToDesignDesigner(design, {

                });
            });
        }

        // return Promise.resolve();
        // return requiredAuthenticated().then(() => {
        //     return createDesign();
        // });
    }
    // function enterPaymentOrder(): Promise<void> {
    //     return requiredAuthenticated().then(() => {
    //         return createOrderOfTemplatePayment({
    //             templateId: id!,
    //             extra: {}
    //         }).then((order) => {
    //             return navigateToOrder(order);
    //             // navigate(`/payment-order?orderId=${order.id}`)
    //         })
    //     })
    // }

    const navigateToTemplatePayment = useNavigateToTemplatePayment();
    function enterTemplatePayment(): Promise<void> {
        return requestAuthenticated().then(() => {
            //这边异步跳转
            navigateToTemplatePayment({
                id: id,
                from: 'template'
            }).then();
            return;
        })
    }
    function enterPayment(): Promise<void> {

        event('payment', {
            'category': 'template',
            'label': template?.title??id,
        })
        return enterTemplatePayment();
        // if (isMiniapp()) {
        //     return enterPaymentOrder();
        // } else {
        //     navigate(`./payment`, {})
        //     return Promise.resolve();
        // }
    }

    function addStar() {
        return requestAuthenticated().then(() => {
            return addStarMutate({
                starrableId: template!.id
            }).then(() => {

            })
        })
    }
    function removeStar() {
        return requestAuthenticated().then(() => {
            return removeStarMutate({
                starrableId: template!.id
            }).then(() => {

            })
        });
    }

    const viewerHasStarred = template?.viewerHasStarred;
    const viewerHasOwned = template?.viewerHasOwned;
    const viewerHasPurchased = template?.viewerHasPurchased;
    const requiredPayment = ((template?.pricing?.amount??0) > 0) && !viewerHasOwned;
    const requiredAdvancePayment =  GLOBAL.template.paymentAdvance && requiredPayment;

    const navigateToWeappTemplate = useNavigateToWeappTemplate();

    function enterWeappTemplate() {
        view("ENTER_WEAPP_TEMPLATE", omitUndefined({
            templateId: id
        }))
        return navigateToWeappTemplate(template!).then()
    }

    return {
        isViewerLatelyRefTemplateDesignLoading,
        isFetching: isFetching,
        isLoading,
        template: template,
        viewerHasStarred: viewerHasStarred,
        viewerHasOwned: viewerHasOwned,
        viewerHasPurchased: viewerHasPurchased,
        star: addStar,
        unstar: removeStar,
        enterDesign,
        // enterPaymentOrder,
        enterPayment,
        enterWeappTemplate: enterWeappTemplate,
        requiredPayment,
        requiredAdvancePayment,
    }
}

export default useTemplate;
