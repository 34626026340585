import styled from "styled-components";
import {QuestionO} from "@react-vant/icons";

export const PaymentProject = styled.div`
  position: relative;
  min-height: 100px;
`
export const PaymentProjectHeader = styled.div`
  font-weight: normal;
  color: #4c4c4c;
  margin-bottom: 8px;
`
export const PaymentProjectTitle = styled.h3`
  display: inline-block;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
`
export const PaymentProjectQuestionO = styled(QuestionO)`
  font-size: 16px;
  margin-left: 6px;
  vertical-align: sub;
`
export const PaymentProjectSelectedAmount = styled.span`
  margin-left: 10px;
  color: #df0000;
  font-size: 12px;
`
export const PaymentProjectSelectedAmountValue = styled.span`
  font-size: 18px;
  //line-height: 26px;
`
export const PaymentProjectVariants = styled.div`
  min-height: 60px;  
`;


export const MakeQuestionContent = styled.div`
  //padding: 10px;
  font-size: 15px;
  line-height: 1.4;
  li {
    margin-bottom: 4px;
  }
`
