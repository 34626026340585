import {createContext} from "react";

export interface EdgeInsets {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
}
export interface MediaQueryType {
    padding: EdgeInsets
}

const MediaQuery = createContext<MediaQueryType>({
    padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    }
});

export default MediaQuery;
