import React from "react";
import {useSearchParams} from "react-router-dom";
import DocumentTitle from "react-document-title";
import TemplateList from "src/page/component/TemplateList";
// import "./index.scss";
import {omitBy} from "@firefly/fly-defter";
import {Page} from "./styles";
import GuideFloatingAdvert from "../../../component/GuideFloatingAdvert";


function Templates() {
    const [searchParams, setSearchParams] = useSearchParams();
    const filters = {
        doctype: searchParams.get("doctype")??null,
        style: searchParams.get("style")??null,
        tags: searchParams.get("tags")??null
    }


    return <DocumentTitle title={"模板"}>
        <Page>
            <TemplateList
                filters={filters}
                onFiltersChange={(filters) => {
                    setSearchParams(omitBy(filters as any, (value: any) => {
                        return value == null
                    }) as Record<string, string>, {
                        replace: true,
                    })
                }}
            />
            <GuideFloatingAdvert />
        </Page>
    </DocumentTitle>;
    //
    // return <ScrollRestoration restorationKey={["templates", filters]}>

    // </ScrollRestoration>
}

export default Templates;
