import styled from "styled-components";


export const Page = styled.div`
  display: flex;
  flex-direction: column;
`
export const PageBody = styled.div`
   display: flex;
`;


export const Article = styled.article`
  padding-top: 30px;
  padding-bottom: 30px;
  width: 90vw;
  margin: auto;
  //text-indent: 0.6rem;

  font-size: 14px;
  line-height: 26px;
  
  h4 {
    font-size: 24px;
    padding-top: 10px;
    padding-bottom: 16px;
  }
  p {
    margin-bottom: 30px;
  }
`
