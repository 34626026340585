import {createContext} from "react";


export interface Tracking {
    gid: string;
}

export interface TrackingContextType {
    isLoaded: boolean;
    tracking: Tracking | null;
}

const TrackingContext = createContext<TrackingContextType>(null as any)

export default TrackingContext;