import {useEffect, useMemo, useRef} from "react";
import classNames from "classnames";
import cls from "classnames";
import { FilterOutline } from "antd-mobile-icons";
import {useMergedState, useRefs} from "@firefly/fly-hook";
import Tag from "src/component/Tag";
import {useDeliveryView} from "src/hook/useDeliveryView";
import {TemplateDoctypeOptionType, TemplateSceneOptionType} from "src/typing/template";
import TabSelector from "../../../component/TabSelector";
import {Dropdown, DropdownRef} from "antd-mobile";
import TemplateQueryForm from "../TemplateQueryForm";


type ValueType = string | null;
export interface TemplateQueryFiltersValue {
    doctype?: ValueType;
    style?: ValueType;
    tags?: ValueType;
}
interface TemplateQueryFilterProps {
    className?: string;
    doctype?: string;
    hiddenUseOption?: boolean;
    value?: TemplateQueryFiltersValue;
    onChange?: (values: TemplateQueryFiltersValue) => void;
}
function TemplateQueryFilters({
    doctype,
    hiddenUseOption = false,
    value: _value,
    onChange,
}: TemplateQueryFilterProps) {

    const {
        data: filters
    } = useDeliveryView(`template/filter`);


    const doctypes = useMemo<TemplateDoctypeOptionType[]>(() => {
        if (filters?.doctype?.options?.length > 0) {
            return filters.doctype.options.map((option: any) => {
                return {
                    label: option.label,
                    value: option.key,
                }
            })
        }
        return [];
    }, [filters?.doctype?.options])
    const scenes = useMemo<TemplateSceneOptionType[]>(() => {
        if (filters?.scene?.options?.length > 0) {
            return filters.scene.options.map((option: any) => {
                return {
                    label: option.label,
                    value: option.key,
                }
            })
        }
        return [];
    }, [filters?.scene?.options])
    const sceneTabs = useMemo(() => {
        if (scenes.length > 0) {
            const tabs = scenes.map((scene) => {
                return {
                    label: scene.label,
                    key: scene.value,
                }
            });
            return [{
                key: 'all',
                label: "综合"
            }].concat(
                tabs
            )
        }
        return [];
    }, [scenes]);
    const types = useMemo(() => {
        if (doctype) { return [];}
        return filters?.doctype?.options?.length > 0 ? [{
            key: null,
            label: "精选"
        }].concat(filters.doctype?.options) : [];
    }, [doctype, filters]);
    const [value, setInnerValue] = useMergedState<TemplateQueryFiltersValue>({},{
        value: _value,
        onChange: onChange,
    });

    useEffect(() => {
        if ((filters?.scene?.options.length > 0) && value.tags) {
            const options: any[] = filters.scene.options;

            if (!options.find(option => {
                return option.key === value.tags
            })) {
                setInnerValue({
                    ...value,
                    tags: null
                })
            }
        }
    }, [filters?.scene, value.tags, setInnerValue]);
    const filterDropdownRef = useRef<DropdownRef>(null);
    return <div className={cls("template-query-filter")}>
        {!hiddenUseOption && sceneTabs.length > 0 ?
            <TabSelector
                className={cls("template-query-filter-item")}
                tabs={sceneTabs}
                active={value.tags??'all'}
                onActiveChange={(v) => {
                    setInnerValue({
                        ...value,
                        tags: (v !== 'all' ? v : undefined)
                    })
                }}
            />
            // <TemplateQueryFilterItem key={"tags"} sticky options={scenes} value={innerValue.tags??null} onChange={(v) => {
            //     setInnerValue({
            //         ...innerValue,
            //         tags: v,
            //     })
            // }} />
            :null}
        {types.length > 0 ? <div className={"template-query-filter-toolbar"}>
            <TemplateQueryFilterItem
                key={"doctype"}
                options={types}
                value={value.doctype ?? null}
                onChange={(v) => {
                    setInnerValue({
                        ...value,
                        doctype: v,
                    })
                }
            }/>
            <div className={"btn-all-filter"}>
                <Dropdown ref={filterDropdownRef}>
                    <Dropdown.Item
                        key={"filter"}
                        destroyOnClose
                        title={
                            <div>
                                全部筛选
                            </div>
                        }
                        arrow={
                            <FilterOutline style={{
                                fontSize: 15,
                                color: "#333"
                            }}/>
                        }
                    >
                        <TemplateQueryForm
                            initialValue={{
                                scene: value.tags ?? undefined,
                                doctype: value.doctype ?? undefined,
                            }}
                            sceneOptions={scenes}
                            doctypeOptions={doctypes}
                            // scene={}
                            onReset={() => {
                                setInnerValue({
                                    ...value,
                                    tags: undefined,
                                    doctype: undefined,
                                })
                                filterDropdownRef.current?.close();
                            }}
                            onSubmit={(formValue) => {
                                setInnerValue({
                                    ...value,
                                    tags: formValue.scene,
                                    doctype: formValue.doctype,
                                })
                                filterDropdownRef.current?.close();
                            }}
                        />

                    </Dropdown.Item>
                </Dropdown>
            </div>
        </div> : null}



        {/*{styles.length > 0 ?*/}
        {/*    <TemplateQueryFilterItem sticky options={styles} value={innerValue.style} onChange={(v) => {*/}
        {/*        setInnerValue({*/}
        {/*            ...innerValue,*/}
        {/*            style: v,*/}
        {/*        })*/}
        {/*    }*/}
        {/*    } />*/}
        {/*    :null}*/}
    </div>
}

interface TemplateQueryFilterItemProps {
    sticky?: boolean;
    options?: { key: ValueType, label: string }[];
    value?: ValueType;
    onChange?: (value: ValueType) => void;
}

function TemplateQueryFilterItem({
                                     sticky, options = [], value, onChange
                                 }: TemplateQueryFilterItemProps) {

    const [innerValue, setInnerValue] = useMergedState<ValueType>(null, {
        value: value,
        onChange,
    });

    const [getRef, removeRef] = useRefs<HTMLDivElement>();
    return <div className={classNames("template-query-filter-item", {
        sticky: sticky
    })}>
        <div style={{paddingLeft: 12}}>
            {options.map(({key, label}, i) => {
                return <Tag key={i}
                            ref={getRef(String(key))}
                            checked={innerValue === key}
                            onClick={(active, event) => {
                                setInnerValue(key);
                                event.currentTarget.scrollIntoView({
                                    block: 'nearest',
                                    inline : "center",
                                    behavior: "smooth",
                                });
                            }}
                >
                    {label}
                </Tag>
            })}
        </div>
    </div>
}
export default TemplateQueryFilters;
