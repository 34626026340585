
import styled from "styled-components";
import {Popup} from "antd-mobile";


export const GuidePopup = styled(Popup)`
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  
`

export const GuideContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const GuideImage = styled.img`
  object-fit: contain;
  max-width: 80vw;
  max-height: 100vw;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  width: 160px;
  margin-top: 20px;
  img {
    width: 100%;
  }
`;