import gql from "fake-tag";
import {useMutation, useQuery, useQueryClient, useSuspenseInfiniteQuery} from "@tanstack/react-query";
import {MessageError} from "@firefly/fly-http";
import {useInfiniteConnectionQuery} from "@firefly/fly-kda-mobile";
import {isAccessDeniedException, useGraphQLClient} from "@firefly/fly-security";
import {InvitationDesign, InvitationPage} from "@firefly/fly-design-inv-data";


export function useCreateDesignByTemplateMutation() {
    const queryClient = useQueryClient();
    const graphqlClient = useGraphQLClient();
    return useMutation({
        mutationFn: (variables: {
            templateId: string
        }) => {
            return graphqlClient.mutate({
                mutation: gql`
                    mutation createDesignByTemplate($input: CreateDesignByTemplateInput) {
                        createDesignByTemplate(input: $input) {
                            design {
                                id,
                                doctype,
                                workId
                            }
                            error {
                                code,
                                message
                            }
                        }
                    }
                `,
                variables: {
                    input: variables
                }
            }).then((output) => {
                const mutate = output.createDesignByTemplate
                if (mutate.error) {
                    throw mutate.error;
                }
                return mutate.design
            })
        },
        onSuccess: () => {

        },
        onSettled: () => {
            queryClient.resetQueries({
                queryKey: ["viewer", "design"]
            }).then()
            queryClient.resetQueries({
                queryKey: ["viewer", "extra"]
            }).then()
            // queryClient.resetQueries([''])
        }
    })
}

export function useViewerDesignQuery(id: string, {
}: {
} = {}) {
    const graphqlClient = useGraphQLClient();
    return useQuery<InvitationDesign>({
        queryKey: ["viewer", "design", id],
        queryFn: () => {
            return graphqlClient.query({
                query: gql`
                    query viewerDesign($id: ID!) {
                        viewer {
                            design(id: $id) {
                                id,
                                title,
                                cover {
                                    width,
                                    height,
                                    url,
                                }
                                preview {
                                    width,
                                    height,
                                    url
                                },
                                dimension {
                                    width,
                                    height,
                                    ruler
                                },
                                doctype,
                                createdAt
                            }
                        }
                    }
                `,
                variables: {
                    id: id,
                }
            }).then((output) => {
                return output.viewer.design;
            })
        }
    })
}


export function useShareDesignMutation() {
    const graphqlClient = useGraphQLClient();
    return useMutation({
        mutationFn: (input: {
            id: string
        }) => {
            return graphqlClient.mutate({
                mutation: gql`
                    mutation shareDesign($input: ShareDesignInput) {
                        shareDesign(input: $input) {
                            share {
                                id,
                                targetId
                            },
                            error {
                                code,
                                message
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                }
            }).then((output) => {
                return output.shareDesign.share
            });
        },
        onSuccess: () => {

        },
        onSettled: () => {
        }
    })
}

export function useViewerDesignsFirstQuery(first: number, {
}: {
} = {}) {
    const graphqlClient = useGraphQLClient();
    return useQuery<{
        nodes: InvitationDesign[],
        totalCount: number
    }>({
        queryKey: ['viewer', 'design', {
            first: first
        }],
        queryFn: () => {
            return graphqlClient.query({
                query: gql`
                    query viewerDesigns($first: Int, $after: String) {
                        viewer {
                            designs(first: $first, after: $after, sortBy: UPDATED_AT) {
                                nodes {
                                    id,
                                    title,
                                    cover {
                                        width,
                                        height,
                                        url,
                                    }
                                    preview {
                                        width,
                                        height,
                                        url
                                    },
                                    dimension {
                                        width,
                                        height,
                                        ruler
                                    },
                                    doctype,
                                    createdAt
                                },
                                pageInfo {
                                    hasPreviousPage,
                                    hasNextPage,
                                    startCursor,
                                    endCursor,
                                },
                                totalCount
                            }
                        }
                    }
                `,
                variables: {
                    first: first,
                }
            }).then((output) => {
                return output.viewer.designs;
            })
        }
    })
}

export function useViewerDesignsSuspenseInfiniteQuery() {
    const graphqlClient = useGraphQLClient();

    return useInfiniteConnectionQuery<InvitationDesign>({
        initialPageParam: {
            first: 10,
        },
        queryKey: ["viewer", "design"],
        queryFn: ({pageParam}) => {
            return graphqlClient.query({
                query: gql`
                    query viewerDesigns($first: Int, $after: String) {
                        viewer {
                            designs(first: $first, after: $after, sortBy: UPDATED_AT) {
                                nodes {
                                    id,
                                    title,
                                    cover {
                                        width,
                                        height,
                                        url,
                                    }
                                    preview {
                                        width,
                                        height,
                                        url
                                    },
                                    dimension {
                                        width,
                                        height,
                                        ruler
                                    },
                                    doctype,
                                    createdAt
                                },
                                pageInfo {
                                    hasPreviousPage,
                                    hasNextPage,
                                    startCursor,
                                    endCursor,
                                }
                            }
                        }
                    }
                `,
                variables: {
                    first: 10,
                    ...pageParam,
                }
            }).then((output) => {
                return output.viewer.designs;
            })
        },
        meta: {
            onError: () => {
                return true;
            }
        }
    })
}

export function useDesignRemoveMutation() {
    const queryClient = useQueryClient();
    const graphqlClient = useGraphQLClient();
    return useMutation({
        mutationFn: (variables: {
            id: string
        }) => {
            return graphqlClient.mutate({
                mutation: gql`
                    mutation removeDesign($input: IdInput) {
                        removeDesign(input: $input) {
                            design {
                                id
                            }
                            error {
                                code,
                                message
                            }
                        }
                    }
                `,
                variables: {
                    input: variables
                }
            }).then((output) => {
                const removeDesign = output.removeDesign
                if (removeDesign.error) {
                    throw removeDesign.error;
                }
                return removeDesign.design
            })
        },
        onSuccess: () => {

        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ["viewer", "design"]
            }).then()
        }
    })
}

export function useCreateDesignByCloneMutation() {
    const queryClient = useQueryClient();
    const graphqlClient = useGraphQLClient();
    return useMutation({
        mutationFn: (variables: {
            designId: string
        }) => {
            return graphqlClient.mutate({
                mutation: gql`
                    mutation createDesignByClone($input: CreateDesignByCloneInput) {
                        createDesignByClone(input: $input) {
                            design {
                                id,
                                doctype
                            }
                            error {
                                code,
                                message
                            }
                        }
                    }
                `,
                variables: {
                    input: variables
                }
            }).then((output) => {
                const createDesignByClone = output.createDesignByClone;
                if (createDesignByClone.error) {
                    const error = createDesignByClone.error;
                    throw new MessageError(error.messaged, error, {});
                }
                return createDesignByClone.design
            })
        },
        onSuccess: () => {

        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ["viewer", "design"]
            }).then()
            queryClient.invalidateQueries({
                queryKey: ["viewer", "extra"]
            }).then()
        }
    })
}
export type RefTemplateDesignIdentifier = Pick<InvitationDesign, 'id' | 'doctype'>;
export function useViewerLatelyRefTemplateDesignQuery(refTemplateId: any, {
    // onSuccess,
    // onError,
}: {
    // onError?: (error: any) => void;
    // onSuccess?: (data: RefTemplateDesignIdentifier | null) => void;
}) {
    const graphqlClient = useGraphQLClient();
    return useQuery<RefTemplateDesignIdentifier | null>({
        queryKey: ['viewer', 'design', {
            refTemplateId: refTemplateId
        }],
        queryFn: () => {
            return graphqlClient.query({
                query: gql`
                    query viewerDesignsWithRefTemplate($refTemplateId: ID) {
                        viewer {
                            designs(refTemplateId: $refTemplateId, first: 1, sortBy: UPDATED_AT) {
                                nodes {
                                    id,
                                    doctype,
                                },
                                totalCount
                            }
                        }
                    }
                `,
                variables: {
                    refTemplateId: refTemplateId
                }
            }).then(output => {
                const designs =  output.viewer.designs.nodes
                if (designs.length > 0) {
                    return designs[0];
                } else {
                    return null;
                }
            }, (reason) => {
                if (isAccessDeniedException(reason)) {
                    //当前未登录忽略
                    return null;
                }
                throw reason;
            })
        }
    })
}

export function useViewerDesignPageQuery(designId: string) {
    const graphqlClient = useGraphQLClient();
    // const graphQLClient = useGraphQLClient();
    return useQuery<InvitationPage | null>({
        queryKey: ['viewer', 'design', designId, "page"],
        queryFn: () => {
            return graphqlClient.query({
                query: gql`
                    query designPage($id: ID!) {
                        viewer {
                            design(id: $id) {
                                page {
                                    id,
                                    title,
                                    description,
                                    cover {
                                        width,
                                        height,
                                        url
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    id: designId
                }
            }).then((output) => {
                return output.viewer.design?.page;
            })
        }
    });
}