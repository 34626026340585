import React from 'react';
import ReactDOM from 'react-dom/client';
import "./polyfill";
import "./style/global.scss";
//预加载覆盖
import "antd-mobile/es/components/dialog/dialog.css";
import "antd-mobile/es/components/button/button.css";
import "antd-mobile/es/components/search-bar/search-bar.css";
import "antd-mobile/es/components/tab-bar/tab-bar.css";
import "antd-mobile/es/components/tag/tag.css";
import "antd-mobile/es/components/input/input.css";
import "antd-mobile/es/components/selector/selector.css";
import "./style/antd-mobile.scss";
import "@firefly/fly-kda-mobile/dist/style/index.scss";
import App from './App';
import {VConsoleSDK} from "./lib/sdk";

const location = window.location;
const getValue = (search: string, param: string) => new URLSearchParams(search).get(param);


const debugValue = getValue(location.search, 'debug');

const isDebug = debugValue?.toLowerCase() === 'true';


function setup() {
    const root = ReactDOM.createRoot(
        document.getElementById('app') as HTMLElement
    );
    root.render(
        <App />
    );
}
if (isDebug) {
    VConsoleSDK.init().then(() => {
        console.log("vconsole loaded");
        setup();
    })
} else {
    setup();
}




