import React from "react";
// import {Space, Button} from "react-vant";
import {
    useNavigateToContactDesigner,
    useNavigateToTemplate,
    useNavigateToWeapp,
    useNavigateToWeappMe, useNavigateToWeappMeDesign,
    useNavigateToWeappTemplate, useRelaunchToMeDesign
} from "src/suppport/router";
import {useNavigate} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";
import {OrderLineItem} from "@firefly/fly-design-inv-data";
import {Space} from "@firefly/fly-kda";
import {Button} from "antd-mobile";


function isBytedanceWebview() {
    return /BytedanceWebview/i.test(navigator.userAgent)
}
interface TemplateOrderResultProps {
    template: OrderLineItem;
    templateMake?: OrderLineItem;
}
function TemplateOrderResultExtra(props: TemplateOrderResultProps) {
    const {
        template,
        templateMake,
    } = props;

    const queryClient = useQueryClient();

    const navigateToWeappMeDesign = useNavigateToWeappMeDesign();
    // const navigateToWeappTemplate = useNavigateToWeappTemplate();
    const templateId = template?.targetId;
    const navigateToTemplate = useNavigateToTemplate();

    const relaunchToMeDesign = useRelaunchToMeDesign();

    const navigate = useNavigate();
    function next() {
        //没什么影响这边就不检查成功了
        return new Promise<void>((resolve) => {
            if (templateId) {
                // queryClient.invalidateQueries(['template', templateId]).then();
                //直接重定向到主页【我的】进行编辑
                // return window.location.href = '/homepage/me';
                // resolve(navigateToTemplate({
                //     id: templateId
                // }, {
                //
                // }))
                relaunchToMeDesign();
            } else{
                resolve(navigate("/homepage/me"))
            }
        })
    }

    const navigateToContactMake = useNavigateToContactDesigner();


    return <>
        <Space direction={"vertical"} gap={16} block>
            {/*{!isWechat() ?*/}
            {templateMake ? (
                <Button
                    className={"fly-button-primary"}
                    block
                    fill={"outline"}
                    color={"primary"}
                    onClick={() => {
                        return navigateToContactMake().then();
                    }}
                >
                    联系设计师制作
                </Button>
            ) : null}
            <Button
                className={"fly-button-primary"}
                block
                fill={"outline"} color={"primary"}
                onClick={() => {
                    navigateToWeappMeDesign().then();
                }}
            >
                打开微信小程序开始制作
            </Button>
            {/*: null}*/}
            {isBytedanceWebview() ?
                //xhr FormData上传图片到OSS 文件数据为空。
                null :
                <Button
                    className={"fly-button-primary"}
                    block
                    color={"primary"}
                    onClick={next}
                >
                    继续操作
                </Button>
            }
        </Space>
    </>
}

export default TemplateOrderResultExtra;