import {PropsWithChildren, useLayoutEffect, useMemo} from "react";
import {QueryCache, QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {isFunction} from "@firefly/fly-defter";
import {
    isAccessDeniedException,
    useAuthenticationPrincipal,
    useSecurityClient
} from "@firefly/fly-security";
import {Errors} from "src/error";


export default function ViewerQueryClientProvider({children}: PropsWithChildren<any>) {
    const securityClient = useSecurityClient();
    const {
        principal
    } = useAuthenticationPrincipal();
    const queryClient = useMemo(() => {
        return new QueryClient({
            queryCache: new QueryCache({
                onError: (error, query) => {
                    if (isAccessDeniedException(error)) {
                        securityClient.invalidate();
                    }
                    if (isFunction(query.meta?.onError)) {
                        if (query.meta?.onError(error, query) === true) {
                            return;
                        }
                    }
                    Errors.handle(error);
                },
            }),
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                    retry: false,
                    throwOnError: error => {
                        return isAccessDeniedException(error);
                    }
                },
                mutations: {
                    onError: (reason) => {
                        Errors.handle(reason)
                    },
                }
            }
        })
    }, [securityClient]);
    useLayoutEffect(() => {
        queryClient.removeQueries({
            queryKey: ['viewer']
        })
    }, [principal, queryClient]);
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}
