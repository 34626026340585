import {useMemo} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {isMiniapp, isWechat} from "@firefly/fly-defter";
import {http, HttpParams} from "@firefly/fly-http";
import {useDocumentMeta} from "@firefly/fly-hook";
import {useAuthentication} from "@firefly/fly-security";
import {User} from "src/context/security";
import useGuidance from "../../util/useGuidance";


export interface UseAnonymousLoginOptions {
    // on
}
export function useAnonymousLogin() {
    //是否自动登录
    const [useAutoAnonymousLogin, disabledAutoAnonymousLogin] = useGuidance('anonymous')
    const metaValue = useDocumentMeta(["firefly", "passport"]);
    const passport = useMemo(() => {
        return metaValue??"/";
    }, [metaValue]);
    const {
        isLoading: isAnonymousUserLoading,
        data: anonymousUser
    } = useQuery<User | null>({
        queryKey: ['anonymous-user'],
        queryFn: () => {
            return http.get(`${passport}/api/anonymous/user`, {
            }, {
                withCredentials: true,
            }).then((value: User) => {
                return value??null;
            }, () => {
                //避免跨域错误 或cookie错误等
                return null;
            })
            //     .catch((reason) => {
            //
            // })
        },
        enabled: !isWechat() && !isMiniapp(),
        retryOnMount: false,
    });

    const {
        isPending,
        authenticate
    } = useAuthentication({
        authenticateFn: () => {
            disabledAutoAnonymousLogin();
            const signupPromise = http.post(`${passport}/api/anonymous/signup`, {
            }, {
                withCredentials: true,
            }).catch((reason: any) => {
                //避免跨域错误 或cookie错误等
                return null;
            });
            return signupPromise.then((shared) => {
                const params = new HttpParams({
                    fromObject: shared?.anonymousId ? {
                        anonymousId: shared.anonymousId
                    } : {

                    }
                });
                return http.post(`/api/passport/account/anonymous-login`, params).then(() => {
                    return {
                        type: "anonymous",
                    }
                    //TODO 需要实现的
                    // return refetch();
                })
            })

        }
    })
    // const {
    //     isPending,
    //     mutateAsync: anonymousLogin,
    // } = useMutation({
    //     mutationKey: ['anonymous-login'],
    //     mutationFn: () => {
    //
    //     },
    //     onError: () => {
    //
    //     }
    // });
    return {
        isPending,
        isAnonymousUserLoading,
        authenticate,
        useAutoAnonymousLogin,
        disabledAutoAnonymousLogin
    }
}