
import GuideClickURL from "src/asset/guide/guide-click.png";
import GuideOkButtonURL from "src/asset/guide/guide-ok-button.png";
import "./index.scss";
import {Mask} from "antd-mobile";

interface WechatShareGuidePopupProps {
    visible?: boolean;
    onClose?: () => void;
}
function WechatShareGuidePopup(props: WechatShareGuidePopupProps) {
    const {
        visible = false,
        onClose
    } = props;

    function close() {
        onClose?.();
    }
    return <Mask
        className={"wechat-share-guide-popup"}
        opacity={0.7}
        visible={visible}
        onMaskClick={onClose}
    >

        <div className={"wechat-share-guide-popup-body"} onClick={close}>
            <img className={"guide-click-image"} alt={"点击右上角分享"} src={GuideClickURL} />
            <button className={"btn-close"}>
                <img alt={"我知道了"} src={GuideOkButtonURL} />
            </button>
        </div>
    </Mask>
}

export default WechatShareGuidePopup;

