// import "./index.scss";
// import { default as InternalInspect } from "./Inspect";
// import { default as InspectBody } from "./InspectBody";
// import { default as InspectPanel } from "./InspectPanel";
import styled from "styled-components";

// type InspectType = typeof InternalInspect & {
//     Body: typeof InspectBody;
//     Panel: typeof InspectPanel
// };
// const Inspect: InspectType = InternalInspect as InspectType;
// Inspect.Body = InspectBody;
// Inspect.Panel = InspectPanel;
// export default Inspect;


const InternalInspect = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f6f8;
`

const InspectContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const InspectAction = styled.div<{
    fixed?: boolean
}>`
  border-top-left-radius: 12PX;
  border-top-right-radius: 12PX;
  background-color: #fff;
  height: 280px;
  padding-top: 14px;

  display: flex;
  flex-direction: column;
  box-shadow: 0px -1px 3px 3px #00000009;
  left: 0;
  right: 0;
  bottom: 0;
  
  
  position: ${(props: any) => props.fixed ? `fixed` : 'relative'};
`

type InspectType = typeof InternalInspect & {
    Content: typeof InspectContent;
    Action: typeof InspectAction
};

const Inspect: InspectType = InternalInspect as InspectType;
Inspect.Content = InspectContent;
Inspect.Action = InspectAction;
export default Inspect;
