
import "./index.scss";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useDeliveryView} from "src/hook/useDeliveryView";
import NavBar from "../../component/NavBar";
import {SearchBar, SearchBarRef, Tag} from "antd-mobile";
import {Space} from "@firefly/fly-kda";

function SearchPage() {
    const [searchParams] = useSearchParams();
    const [keyword, seyKeyword] = useState<string>(() => {
        return searchParams.get("keyword")??"";
    });
    const navigate = useNavigate();

    const {
        data: hots = []
    } = useDeliveryView<{value: string}[]>(	"search/hots");

    function search(value: string) {
        seyKeyword(value);
        navigate(`/search/${encodeURIComponent(value)}`, {
            replace: true
        })
    }
    const inputRef = useRef<SearchBarRef>(null);
    useEffect(() => {
        inputRef.current?.focus();
    }, []);
    return <>
        <NavBar
            style={{
                "--padding-left": "52px"
            }}
        >
            <SearchBar
                ref={inputRef}
                placeholder={"搜索您喜欢的"}
                value={keyword}
                onChange={seyKeyword}
                onSearch={search}
            />
        </NavBar>
        {hots ? <div className={"search-section-block"}>
            <div className={"search-section-block-title"}>
                搜索热词
            </div>
            <div className={"search-section-block-body"}>
                <Space gap={18} wrap>
                    {hots.map(({value}, index) => {
                        return <Tag
                            className={"large"}
                            key={index}
                            color="primary"
                            fill="outline"
                            onClick={() => {
                                search(value);
                            }}
                                    // size={"large"}
                                    // plain
                                    // type="primary"

                        >
                            {value}
                        </Tag>
                    })}
                </Space>
            </div>
        </div> : null }

    </>
}

export default SearchPage;
