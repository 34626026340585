import React from "react";
import {CellType} from "../interface";


function convertChildrenToCells(children: React.ReactNode): CellType[] {
  return React.Children.toArray(children)
    .filter(node => React.isValidElement(node))
    .map((element) => {
        const {
            key,
            props
        } = element as any;
        const { width, height, children, ...restProps } = props;
        return {
            key: key,
            width: width,
            height: height,
            ratio: width / height,
            children,
            ...restProps,
        };
    })
}
function useCells(
  {
   children
  } : {
    children?: React.ReactNode
  }
): CellType[] {

  const cells: CellType[] = React.useMemo(() => {
    return convertChildrenToCells(children);
  }, [children]);

  return cells;
}

export default useCells;
