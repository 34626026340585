import {useMutation} from "@tanstack/react-query";
import {http} from "@firefly/fly-http";


export function useJumpTokenCreateMutation() {

    return useMutation({
        mutationFn: () => {
            return http.post(`/api/passport/jump/token`, {

            })
        }
    })
}

