


// const WHILE_HOSTS = [
//     'www.i-firefly.com',
//     'www.invfly.com',
// ];

// const AGENT_TARGET = `https://www.i-firefly.com`;
//
// const host = window.location.host;
//
// /**
//  * 支付代理
//  */
// export function lookupPaymentAgent(method: string) {
//     if (method === 'WECHATPAY:H5') {
//         return WHILE_HOSTS.includes(host) ? undefined : {
//             target: AGENT_TARGET
//         };
//     }
//     return undefined;
// }

export function isBytedanceWebview() {
    return /BytedanceWebview/i.test(navigator.userAgent)
}