import {useQuery} from "@tanstack/react-query";
import gql from "fake-tag";
import {useGraphQLClient} from "@firefly/fly-security";
import {ViewerWorkTemplate} from "@firefly/fly-design-inv-data";

export interface TemplateLabel {
    title: string;
    subtitle: string;
    filters: {[key: string]: any};
    templates: {
        nodes: ViewerWorkTemplate[]
    };
}

export default function useTemplateLabelsQuery() {

    const graphqlClient = useGraphQLClient();
    return useQuery<TemplateLabel[]>({
        queryKey: ['template-labels', {

        }],
        queryFn: () => {
            return graphqlClient.query({
                query: gql`
                    {
                        templateLabels(first: 10) {
                            id,
                            title,
                            subtitle,
                            filters,
                            order,
                            templates(first: 10) {
                                nodes {
                                    id,
                                    title,
                                    type,
                                    doctype,
                                    preview {
                                        width,
                                        height,
                                        url
                                    },
                                    cover {
                                        width,
                                        height,
                                        url
                                    },
                                    attributes {
                                        replaceableImageCount
                                    },
                                    metadata,
                                    score,
                                    pricing {
                                        amount,
                                        originalAmount
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                }
            }).then((output) => {
                return output.templateLabels
            })
        }
    })

}

