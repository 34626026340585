// import {Template} from "src/typing";
import {Image} from "antd-mobile";
import {Template} from "@firefly/fly-design-inv-data";

interface ImageTemplateBodyContentProps {
    value: Template;
}
function ImageTemplateContent(props: ImageTemplateBodyContentProps) {
    const {
        value,
    } = props;


    const previewUrl = value.preview.url;
    const imageUrl = `${previewUrl}?x-oss-process=image/resize,l_1600/format,jpg/quality,q_90`;

    return <Image
        fit={"contain"}
        src={imageUrl}
        width={"88vw"}
        height={"100%"}
        style={{
            padding: 20
        }}
        // showLoading
    />
}

export default ImageTemplateContent;
