import {Order, OrderLineItem} from "@firefly/fly-design-inv-data";


interface UseOrderProjectType {
    templateLineItem?: OrderLineItem;
    templateMakeLineItem?: OrderLineItem;
    makeLineItem?: OrderLineItem;
}
function useOrderProject(order?: Order): UseOrderProjectType {

    const templateLineItem = order?.lineItems.find((item) => {
        return item.type === 'WORK_TEMPLATE';
    });

    const templateMakeLineItem = order?.lineItems.find((item) => {
        return item.type === 'TEMPLATE_MAKE';
    })
    const makeLineItem = order?.lineItems.find((item) => {
        return item.type === 'MAKE';
    })
    return {
        templateLineItem,
        templateMakeLineItem,
        makeLineItem,
    }
}

export default useOrderProject;