
import cls from "classnames";
import {useNavigate} from "react-router";
import {createNamespace, Loading} from "@firefly/fly-kda";
import NavBar from "src/page/component/NavBar";
import OrderList from "./OrderList";
import "./style/index.scss";

const [bem] = createNamespace("viewer-orders");
function MeOrders() {

    const navigate = useNavigate();


    return <div className={cls(bem())}>
        <NavBar>
            我的订单
        </NavBar>
        <div className={cls(bem("body"))}>
            <OrderList />
        </div>
    </div>
}

export default MeOrders;
