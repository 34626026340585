import {useInfiniteQuery, useSuspenseInfiniteQuery} from "@tanstack/react-query";
import gql from "fake-tag";
// import {PageInf} from "src/typing";
import {useGraphQLClient} from "@firefly/fly-security";
import {ViewerWorkTemplate} from "@firefly/fly-design-inv-data";
import {PageInfo} from "src/typing";
import type {InfiniteData} from "@tanstack/query-core";
import {useInfiniteConnectionQuery} from "@firefly/fly-kda-mobile";


interface StarredTemplateEdge {
    cursor: string
    node: ViewerWorkTemplate;
    starredAt: any;
    attributes: {};
}

interface StarredTemplateConnection {
    edges: [StarredTemplateEdge];
    nodes: [ViewerWorkTemplate];
    pageInfo: PageInfo;
}

export default function useStarredTemplateInfiniteQuery({
}: {
} = {}) {
    const graphqlClient = useGraphQLClient();
    return useInfiniteConnectionQuery<ViewerWorkTemplate>({
        initialPageParam: {},
        queryKey: ['viewer', 'starredTemplate'],
        queryFn: (context) => {
            return graphqlClient.query({
                query: gql`
                    query starredTemplates($first: Int, $after: String) {
                        viewer {
                            starredTemplates(first: $first, after: $after) {
                                edges {
                                    starredAt,
                                    node {
                                        id,
                                        title,
                                        type,
                                        cover {
                                            width,
                                            height,
                                            url
                                        },
                                        preview {
                                            width,
                                            height,
                                            url
                                        },
                                        doctype,
                                        createdAt
                                    }
                                },
                                pageInfo {
                                    hasPreviousPage,
                                    hasNextPage,
                                    startCursor,
                                    endCursor
                                }
                            }
                        }
                    }
                `,
                variables: {
                    first: 20,
                    ...context.pageParam,
                }
            }).then(output => {
                return output.viewer.starredTemplates;
            })
        },
        // getNextPageParam: (lastPage) => {
        //     return lastPage.pageInfo?.hasNextPage ? {
        //         after: lastPage.pageInfo.endCursor
        //     } : null
        // }
    })
}
