import React, {useEffect} from "react";
import {Button, Toast} from "antd-mobile";
import useCustomerService from "../../../hook/useCustomerService";


export interface ContactButtonProps {
    children: React.ReactNode;
}
function ContactButton(props: ContactButtonProps): React.ReactElement {
    const {
        children
    } = props;

    const {
        navigate,
        isPending,
    } = useCustomerService();

    return <Button
        className={"fly-button-primary"}
        color={"primary"}
        block
        onClick={navigate}
        loading={isPending}
    >
        {children}
    </Button>
}

export default ContactButton;