import React, {createContext, useMemo} from "react";
import {Restoration} from "./core/restoration";


export interface RestorationContextType {

    restoration: Restoration;
}


const RestorationContext = createContext<RestorationContextType>(null as any)

export default RestorationContext;


interface RestorationProviderProps {
    children: React.ReactNode;
}
export function RestorationProvider(props: RestorationProviderProps) {
    const {
         children
    } = props;

    const ContextValue = useMemo<RestorationContextType>(() => {
        const restoration = new Restoration();
        return {
            restoration
        }
    }, [])
    return <RestorationContext.Provider value={ContextValue}>
        {children}
    </RestorationContext.Provider>
}
