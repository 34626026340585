import styled from "styled-components";


export const DesignFormLayout = styled.div`
    .adm-form {
        ---border-inner: none;
        ---border-top: none;
        ---border-bottom: none;
        --prefix-width: 74px;
    }
    .adm-list-item-content-main {
        padding: 8px 0;
    }
    .adm-list-item-description {
        margin-top: 6px;
    }
    .adm-list-body {
        font-size: 14px;
        --padding-left: 0;
    }
    
    
  .rv-cell {
    padding: 6px 4px;
  }
`
