// import SlsReporter from 'sls-wpk-reporter'
// import {
//     wpkglobalerrorPlugin, // JS异常监控
//     wpkperformancePlugin, // 性能监控
// } from 'sls-wpk-reporter'
// import {UID_SESSION_STORAGE_KEY} from "../config";
import {http} from "@firefly/fly-http";
import {Advert} from "../trace/advert";
import {Logger} from "./logger";

// process.env === 'DEBUG'
const wpk: any = null;
// new SlsReporter({
//     bid: 'sls-f11849a7026feeda4a',
//     project: 'proj-rum-9473a4dcf6ad20671176c70e71736b',
//     endpoint: 'cn-hangzhou-intranet.log.aliyuncs.com',
//     slsParams: {
//         // app_name: '',
//         // user_nick: '',
//         // logon_type: '',
//     },
//     rel: '1.0',
//     uid: () => {
//         return sessionStorage.getItem(UID_SESSION_STORAGE_KEY)
//     },
//     plugins: [
//         [wpkperformancePlugin],
//         [
//             wpkglobalerrorPlugin,
//             {
//                 jsErr: true, // 是否开启JS异常监控。
//                 resErr: true, // 是否开启资源加载异常监控。
//             },
//         ],
//     ],
//     debug: false,
// });
// wpk.install(); // 初始化。


export function report(data: Record<string, any>) {
    if (wpk) {
        wpk.logReport(data)
    }
}


export namespace Reporter {
    export function config(config: {
        metadata: Record<string, string>
    }) {
        http.post(`/api/promotion/tracking/config`, config).catch((reason)=> {
            if (reason) {
                Logger.info('[tracking-config]', reason)
            }
        }).then();
    }
    export function advert(advert: Advert) {
        // Http.post(`/api/tracking/advert`, advert).then();
        http.post(`/api/promotion/tracking/advert`, advert).catch((reason)=> {
            if (reason) {
                Logger.info('[tracking-advert]', reason)
            }
        }).then();
    }
    export function event(input: {

    }) {

    }
}

export function sem(extra?: {
    sem: string,
    link?: string;
    keyword?: string;
    [key: string]: string | undefined;
}) {
    http.post(`/api/events/report`, {
        payload: {
            action: 'SEM',
            label: `sem` + (extra?.sem ? `:` + extra.sem : ''),
            extra: extra,
        },
    }).then()
}
export function view(label: string, extra?: Record<string, string>) {
    http.post(`/api/events/report`, {
        payload: {
            action: 'VIEW',
            label: label,
            extra: extra,
        },
    }).then()
}
export function error(label: string, extra?: Record<string, string>) {
    http.post(`/api/events/report`, {
        payload: {
            action: 'ERROR',
            label: label,
            extra: {
                url: window.location.href,
                ...extra,
            },
        },
    }).then()
}
export function event(action: string, args: {
    category?: string,
    label?: string,
    value?: number,
}) {
    report({
        action: action,
        ...args,
    })
}
