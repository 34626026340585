import "./style/index.scss";
import {Card, CardButton} from "./Card";


export type CardNamespaceType = typeof Card & {
    Button: typeof CardButton;
}

const CardNamespace = Card as CardNamespaceType;
CardNamespace.Button = CardButton;
export default CardNamespace;