import React, {CSSProperties} from "react";
import cls from "classnames";
import {Image} from "antd-mobile";
import {createNamespace} from "@firefly/fly-kda";
import {InvitationSend} from "@firefly/fly-design-inv-data";
import Card from "src/kda/Card";
import {ReactComponent as ShareOutline} from "src/asset/service/share.svg";
import {ReactComponent as EditOutline} from "src/asset/service/edit.svg";
import {useNavigate} from "react-router-dom";


const [bem] =createNamespace("send-card");
export interface SendItemProps {
    className?: string;
    style?: CSSProperties;
    value?: InvitationSend;
    onEditClick?: () => void;
    onShareClick?: () => void;
    onClick?: () => void;
}
function SendCard(props: SendItemProps) {
    const {
        className,
        style,
        value,
        onClick,
        onEditClick,
        onShareClick,
    } = props;
    const navigate = useNavigate();
    return <Card
        className={cls(className, bem())}
        style={style}
        onClick={onClick}
        extra={[

            <Card.Button
                key={"edit"}
                onClick={onEditClick}
            >
                <i>
                    <EditOutline/>
                </i>
                编辑
            </Card.Button>,
            <Card.Button
                type={"primary"}
                key={"share"}
                onClick={onShareClick}
            >
                <i>
                    <ShareOutline />
                </i>
                分享
            </Card.Button>
        ]}
        // actions={[
        //     // {
        //     //     icon: <SeeSVG/>,
        //     //     title: "预览"
        //     // },
        //     {
        //         icon: <EditSVG/>,
        //         title: "编辑"
        //     },
        //     {
        //         icon: <DeleteOutline/>,
        //         title: "删除"
        //     }
        // ]}
    >
        <div className={cls(bem("cover"))}>
            <Image
                className={cls(bem("cover-image"))}
                fit={"cover"}
                src={`https://o.i-firefly.com/doc/6579ba19c4c081440699625/DtJwgRFq3ZuTvi1blV07p.png?x-oss-process=image/resize,w_360/crop,h_720/format,jpg/quality,q_60`}
            />
        </div>
        <div className={cls(bem("meta"))}>
            <div className={cls(bem("meta-item"))}>
                <div className={cls(bem("meta-label"))}>
                    送呈宾客：
                </div>
                <div className={cls(bem("meta-value"), bem("meta-toname"))}>
                    {value?.toName}
                </div>
            </div>
            <div className={cls(bem("meta-item"))}>
                <div className={cls(bem("meta-label"))}>
                    送呈短语：
                </div>
                <div className={cls(bem("meta-value"), bem("meta-explain"))}>
                    {value?.explain}
                    {/*恭请您来与我们共同举杯庆祝*/}
                </div>
            </div>
        </div>

        {/*<div className={cls(bem("actions"))}>*/}
        {/*    {[*/}
        {/*        {*/}
        {/*            icon: <SeeSVG/>,*/}
        {/*            title: "预览"*/}
        {/*        },*/}
        {/*        {*/}
        {/*            icon: <EditSVG/>,*/}
        {/*            title: "编辑"*/}
        {/*        },*/}
        {/*        {*/}
        {/*            icon: <DeleteOutline/>,*/}
        {/*            title: "删除"*/}
        {/*        }*/}
        {/*    ].map(({icon, title}) => {*/}
        {/*        return <div className={cls(bem("action"))}>*/}
        {/*            <div className={cls(bem("action-btn"))}>*/}
        {/*                <i className={cls(bem("action-icon"))}>*/}
        {/*                    {icon}*/}
        {/*                </i>*/}
        {/*                <span className={cls(bem("action-text"))}>*/}
        {/*                    {title}*/}
        {/*                </span>*/}
        {/*            </div>*/}

        {/*        </div>*/}
        {/*    })}*/}
        {/*</div>*/}
    </Card>
}

export default SendCard;