import React from "react";
import DocumentTitle from "react-document-title";
import NavBar from "src/page/component/NavBar";
import styled from "styled-components";
import {useViewerQuery, useSecurity} from "../../../context/security";
import {useSecurityClient} from "@firefly/fly-security";
import {Button, Image, List} from "antd-mobile";
import "./index.scss";
import {useReady, WechatSDK} from "@firefly/fly-bridge";

const Footer = styled.div`
  margin-top: 20px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
function MeProfile() {

    const {
        viewer
    } = useViewerQuery();
    const {

    } = useSecurityClient();

    const {
        logout
    } = useSecurity();

    return <DocumentTitle title={"个人信息"}>
        <>
            <NavBar title={"个人信息"}/>
            <div>
                <List className={"me-profile-list"}>
                    <List.Item
                        title={"ID"}
                        extra={viewer?.uid}
                    />
                    <List.Item
                        title={"头像"}
                        extra={
                            <Image
                                width={44}
                                height={44}
                                style={{
                                    display: "inline-block",
                                    "borderRadius": "22px",
                                }}
                                src={viewer?.avatar}
                            />
                        }
                    />
                    <List.Item
                        title={"昵称"}
                        extra={<>
                            {viewer?.nickname}
                        </>}
                    />
                </List>
                {/*<Cell.Group>*/}
                {/*    <Cell title={"ID"} value={<>*/}
                {/*        {viewer?.uid}*/}
                {/*    </>} />*/}
                {/*    <Cell title={"头像"} value={<>*/}
                {/*        <Image*/}
                {/*            width={44}*/}
                {/*            height={44}*/}
                {/*            style={{*/}
                {/*                display: "inline-block",*/}
                {/*                "borderRadius": "22px",*/}
                {/*            }}*/}
                {/*            src={viewer?.avatar}*/}
                {/*        />*/}
                {/*    </>} />*/}

                {/*    <Cell title={"昵称"} value={<>*/}
                {/*        {viewer?.nickname}*/}
                {/*    </>} />*/}

                {/*    /!*<Cell title={"手机号"} value={*!/*/}
                {/*    /!*    current?.mobile ? <>{current.mobile.identifier}</>*!/*/}
                {/*    /!*        :*!/*/}
                {/*    /!*    <>未绑定</>*!/*/}
                {/*    /!*} isLink onClick={() => {*!/*/}
                {/*    /!*    navigateToURL(`${process.env["REACT_APP_WEBPASSPORT"]}/ac/enter/mobile-modify.html`).then()*!/*/}
                {/*    /!*}} />*!/*/}
                {/*    /!*{bindings.map((binding) => {*!/*/}
                {/*    /!*    const bind = binding.bindAt;*!/*/}
                {/*    /!*    const definition = THIRDPARTY_BINDING_DEFINITIONS[binding.type];*!/*/}
                {/*    /!*    return <Cell key={binding.key} title={definition.title} value={<>*!/*/}
                {/*    /!*        {bind ? <> 解绑 </> : <>未绑定</>}*!/*/}
                {/*    /!*    </>} isLink onClick={() => {*!/*/}
                {/*    /!*        const query= `key=${encodeURIComponent(binding.key)}&return=${encodeURIComponent(window.location.href)}`;*!/*/}
                {/*    /!*        if (bind) {*!/*/}
                {/*    /!*            navigateToURL(`${process.env["REACT_APP_WEBPASSPORT"]}/ac/enter/thirdparty-unbind.html?${query}`).then()*!/*/}
                {/*    /!*        } else {*!/*/}
                {/*    /!*            navigateToURL(`${process.env["REACT_APP_WEBPASSPORT"]}/ac/enter/thirdparty-bind.html?${query}`).then()*!/*/}
                {/*    /!*        }*!/*/}
                {/*    */}
                {/*    /!*    }} />*!/*/}
                {/*    /!*})}*!/*/}
                {/*</Cell.Group>*/}
            </div>
            <Footer>

                <Button color={"danger"} fill={"outline"} block onClick={() => {
                    logout('/');
                }} >
                    退出
                </Button>
            </Footer>
        </>
    </DocumentTitle>
}


export default MeProfile;
