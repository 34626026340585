import React, {CSSProperties} from "react";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {ReactComponent as SettingIcon} from "src/asset/icon/setting.svg";
import {Image} from "antd-mobile";
import "./index.scss";
import {
    Layout, LayoutContent,
    UserAvatar,
    UserMetaDetail,
    UserMetaNickname,
    UserMetaUid,
    UserSettingButton,
    UserToolbar
} from "./styles";
import {Userinfo} from "src/context/security";


export interface MainUserProps {
    className?: string;
    style?: CSSProperties;
    value: Userinfo | undefined;
    onClick?: () => void;
}
function MainUser({
    className,
    style,
    onClick,
    value: current
}: MainUserProps) {



    const navigate = useNavigate();

    function navigateUserSetting() {
        navigate("/me/profile")
    }
    return <Layout className={classNames(className)} onClick={onClick}>
        <LayoutContent>
            {current ? <>
                <UserAvatar>
                    <Image
                        // round
                        src={current.avatar}
                        width={60}
                        height={60}
                        style={{ borderRadius: 30 }}
                    />
                </UserAvatar>
                <UserMetaDetail>
                    <UserMetaNickname>
                        {current.nickname}
                    </UserMetaNickname>
                    <UserMetaUid>
                        用户ID： {current.uid}
                    </UserMetaUid>
                    {/*{extra ?*/}
                    {/*    <div className={"main-user-meta-items"}>*/}
                    {/*        <div className={"main-user-meta-item"} onClick={navigateMeWork}>*/}
                    {/*            <div className={"main-user-meta-item-value"}>*/}
                    {/*                {extra.workTotalCount}*/}
                    {/*            </div>*/}
                    {/*            <div className={"main-user-meta-item-label"}>*/}
                    {/*                作品*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className={"main-user-meta-item"} onClick={navigateMeStarredTemplate}>*/}
                    {/*            <div className={"main-user-meta-item-value"}>*/}
                    {/*                {extra.starredTemplateTotalCount}*/}
                    {/*            </div>*/}
                    {/*            <div className={"main-user-meta-item-label"}>*/}
                    {/*                收藏*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    : null*/}
                    {/*}*/}
                </UserMetaDetail>
                <UserToolbar>
                    <UserSettingButton onClick={navigateUserSetting}>
                        <SettingIcon />
                    </UserSettingButton>

                    {/*<Image  className={"icon main-user-setting"} src={""}*/}
                    {/*/>*/}
                </UserToolbar>
            </> : <>
                <UserAvatar>
                    <Image
                        // round
                        // src={current.avatar}
                        src={`https://o.i-firefly.com/avatar/default_avatar.png`}
                        width={60}
                        height={60}
                        style={{ borderRadius: 30 }}
                    />
                </UserAvatar>
                <UserMetaDetail>
                    <UserMetaNickname>登录</UserMetaNickname>
                    <UserMetaUid>登录后继续更多操作</UserMetaUid>
                </UserMetaDetail>
            </>}
        </LayoutContent>
    </Layout>
}

export default MainUser;
