import styled from "styled-components";


export const Layout = styled.div`
  
  height: 75px;
  //margin-top: 4vw;
  //margin-bottom: 4vw;
  padding: 7.5px 16px 7.5px ;
  //padding-bottom: 3vw;
  
`;

export const LayoutContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserAvatar = styled.div`
  .at-avatar--large {
    width: 60px;
    height: 60px
  }
`;
export const UserMetaDetail = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
`;
export const UserMetaNickname = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
`;
export const UserMetaUid = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: #aaa;
  line-height: 16px;
`;
export const UserToolbar = styled.div`
  position: absolute;
  top: 8px;
  right: 15px;
  display: flex;
  flex-direction: row;
`;
export const UserSettingButton = styled.div`
  width: 26px;
  height: 26px;
  font-size: 26px;
`;