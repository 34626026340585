import cls from "classnames";
import React from "react";
import {Navigate, useNavigate, useNavigationType, useParams} from "react-router-dom";
import {Empty, createNamespace} from "@firefly/fly-kda";
import {InfiniteList} from "@firefly/fly-kda-mobile";
import NavBar from "src/page/component/NavBar";
import {useSendInfiniteConnectionQuery} from "src/hook/send";
import {Button, Dialog} from "antd-mobile";
import SendCard from "../../component/InviationSendList/SendCard";
import {useViewerDesignPageQuery} from "src/hook/design";
import {useNavigateToPageShare2} from "src/suppport/router";
import "./style/index.scss";
import {useBridge} from "@firefly/fly-security";
import {isMiniapp} from "@firefly/fly-defter";
import DocumentTitle from "react-document-title";

const [bem] = createNamespace("send-list")

function SendList(): React.ReactElement {
    const {designId} = useParams();

    const bridge = useBridge();
    const {
        data: page
    } = useViewerDesignPageQuery(designId!);
    const {
        isFetching,
        isLoading,
        items,
        hasMore,
        loadMore,
        data,
        error,
    } = useSendInfiniteConnectionQuery(designId!);

    let connection = data?.pages[data?.pages.length - 1];
    const totalCount = connection?.totalCount;
    const navigate = useNavigate();
    const navigationType = useNavigationType();

    const navigateToPageShare2 = useNavigateToPageShare2();
    return <DocumentTitle title={"送呈列表"}>
        <div className={cls(bem())}>
            {
                isMiniapp() ?
                    <></>
                    :
                    <NavBar className={cls(bem("navbar"))}>
                        送呈列表
                    </NavBar>
            }
            <div className={cls(bem("body"))}>
                <InfiniteList
                    isFetching={isFetching}
                    isLoading={isLoading}
                    dataSource={items}
                    hasMore={hasMore}
                    loadMore={loadMore}
                    error={error ? (
                        <div className={cls(bem("placeholder"))}>
                            <Empty
                                description={error.message}
                            />
                        </div>
                    ) : null}
                    empty={() => {
                        //若未创建送呈 则跳转到删除
                        return <div className={cls(bem("placeholder"))}>
                            {navigationType === 'PUSH' ?
                                <Navigate to={`./create`}/>
                                :
                                <Empty description={"尚未创建送呈"}/>
                            }
                        </div>
                    }}>
                    {(records) => {
                        return <div className={cls(bem("content"))}>
                            {records?.map((item) => {
                                return <div key={item.id} className={cls(bem("item"))}>
                                    <SendCard
                                        value={item}
                                        onEditClick={() => {
                                            navigate(`/designs/${designId}/sends/${item!.id}`);
                                        }}
                                        onShareClick={() => {
                                            if (!page) {
                                                return Dialog.alert({
                                                    content: `您的邀请函还未发布，请先发布后再分享`
                                                })
                                            }
                                            return navigateToPageShare2(page, item.id)
                                            // if (isMiniapp()) {
                                            //     return navigateToMiniapp(`/page/page/share2`, {
                                            //         id: page.id,
                                            //         send: item.id,
                                            //     }, {
                                            //     })
                                            // } else {
                                            //     navigate(`/invitation/pages/${page!.id}/inspect?send=${item.id}`);
                                            // }


                                        }}
                                    />
                                    {/**/}
                                </div>
                            })}
                        </div>
                    }}
                </InfiniteList>
            </div>
            <div className={cls(bem("toolbar"))}>
                {totalCount ? <div className={cls(bem("tip"))}>
                    共{totalCount}人
                </div> : null}
                <Button
                    className={"fly-button-primary"}
                    color={"primary"}
                    shape={"rounded"}
                    block
                    onClick={() => {
                        navigate("./create");
                    }}
                >

                    {totalCount > 0 ?
                        <>
                            继续添加
                        </> :
                        <>
                            添加
                        </>
                    }
                </Button>
            </div>
        </div>
    </DocumentTitle>
}


export default SendList