import React, {useMemo} from "react";
import DocumentTitle from "react-document-title";
import {createNamespace, Empty, Loading} from "@firefly/fly-kda";
import {ViewerTemplate, ViewerWorkTemplate} from "@firefly/fly-design-inv-data";
import {GLOBAL} from "src/config";
import Waterfall from "src/component/Waterfall";
import TemplateCard from "src/component/TemplateCard";
import {SizeType} from "src/typing";
import {useNavigateToTemplate} from "src/suppport/router";
import NavBar from "src/page/component/NavBar";
import cls from "classnames";
import InfiniteScroll from "src/component/InfiniteScroll";
import usePurchasedTemplateInfiniteConnectionQuery from "src/hook/usePurchasedTemplateInfiniteConnectionQuery";
import "./index.scss";
import Indicator from "src/component/Indicator";

const [bem] = createNamespace("viewer-purchased-template")
function MePurchasedTemplateWaterfall() {

    const {
        // data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        loadMore,
        hasMore,
        items,
    } = usePurchasedTemplateInfiniteConnectionQuery();
    const navigateToTemplate = useNavigateToTemplate();
    function onItemClick(item: ViewerTemplate) {
        navigateToTemplate(item);
    }

    const {
        column,
        gutter,
        size,
    } = useMemo<{
        gutter: number;
        column: number;
        size: SizeType;
    }>(() => {
        if (window.matchMedia('(min-width: 1000px)').matches) {
            return {
                gutter: 14,
                column: 6,
                size: 'large',
            };
        }
        if (window.matchMedia('(min-width: 600px)').matches) {
            return {
                gutter: 12,
                column: 4,
                size: 'large',
            };
        }
        return {
            gutter: 10,
            column: 2,
            size: 'large',
        };
    }, []);
    function renderContent() {
        if (isLoading) {
            return <div className={cls(bem("placeholder"))}>
                <Loading />
            </div>
        }
        if (Number(items?.length) === 0) {
            return <div className={cls(bem("placeholder"))}>
                <Empty description={"还没有已购模板"}/>
            </div>
        }
        return <React.Fragment>
            <Waterfall width={window.innerWidth} gutter={gutter} column={column} >
                {items?.map((edge) => {
                    const {
                        node
                    } = edge;
                    const {
                        id,
                        cover,
                    } = node;
                    const coverSize = cover ? cover : {width: 200, height: 200};
                    const width = coverSize.width || 1;
                    const height = coverSize.height || 1;
                    const caleHeight = Math.min(width * 2, height)  + 42;
                    return <Waterfall.Cell
                        key={id}
                        width={width}
                        height={caleHeight}
                    >
                        <TemplateCard
                            value={node as ViewerWorkTemplate}
                            width={"100%"}
                            minFit={0.5}
                            size={size}
                            hiddenPayment={GLOBAL.template.paymentHidden}
                            // blur
                            onClick={() => {
                                navigateToTemplate(node);
                            }}
                        />
                    </Waterfall.Cell>
                })}
            </Waterfall>
            <InfiniteScroll
                hasMore={hasMore}
                loadMore={loadMore}
            />
        </React.Fragment>
    }
    return <div className={cls(bem("waterfall"))} >
        {renderContent()}
    </div>
}

function MePurchaseTemplates() {
    return <DocumentTitle title={"已购模板"}>
        <div className={cls(bem())}>
            <NavBar title={"已购模板"} />
            <div className={cls(bem("body"))}>
                <Indicator.Suspense>
                    <MePurchasedTemplateWaterfall />
                </Indicator.Suspense>
            </div>
        </div>
    </DocumentTitle>
}

export default MePurchaseTemplates;
