import styled from "styled-components";


export const PageContainerWrapper = styled.div`
  position: relative;
  //flex: 1;
  width: 100%;
  height: 100%;
`
export const PageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
