import React from "react";
import {useNavigate} from "react-router-dom";
import Result from "../../../component/Result";
import {Button, Space, ErrorBlock} from "antd-mobile";
import {QuestionCircleOutline} from "antd-mobile-icons";


function TemplateEmpty() {
    const navigate = useNavigate();
    // return <Result>
    //     <ResultBody>
    //         <Empty description="模板不存在" />
    //     </ResultBody>
    //     <ResultFooter>
    //         <Space direction={"vertical"} gap={16} block>
    //             <Button block type={"default"} onClick={() => {
    //                 navigate("/", {
    //                     replace: true
    //                 })
    //             }}>
    //                 回到主页
    //             </Button>
    //         </Space>
    //     </ResultFooter>
    // </Result>
    return (
        <Result
            icon={
                <ErrorBlock
                    image={
                        <QuestionCircleOutline
                            style={{
                                color: 'var(--adm-color-light)',
                                fontSize: 48,
                            }}
                        />
                    }
                    description={"模板不存在"}
                >
                </ErrorBlock>
            }
            extra={
                <Space direction={"vertical"} block style={{
                    "--gap": "16px"
                }}>
                    <Button className={"fly-button-primary"} style={{
                        marginTop: 60
                    }} block color={"primary"} onClick={() => {
                        navigate("/", {
                            replace: true
                        })
                    }}>
                        回到主页
                    </Button>
                </Space>
            }
        />
    )
}

export default TemplateEmpty;
