import {ReactNode, useContext, useMemo} from "react";
import MediaQuery from "./MediaQuery";


interface RemovePaddingMediaQueryProps {
    removeLeft: boolean;
    removeTop: boolean;
    removeRight: boolean;
    removeBottom: boolean;
    children: ReactNode;
}

function RemovePaddingMediaQuery({
    removeLeft, removeTop, removeRight, removeBottom, children
}: RemovePaddingMediaQueryProps) {
    const context = useContext(MediaQuery);

    const contextValue = useMemo(() => {
        const { padding } = context;
        return {
            padding: {
                left: removeLeft ? 0 : padding.left,
                top: removeTop ? 0 : padding.top,
                right: removeRight ? 0 : padding.right,
                bottom: removeBottom ? 0 : padding.bottom,
            }
        }
    }, [context, removeLeft, removeTop, removeRight, removeBottom])
    return <MediaQuery.Provider value={contextValue}>
        {children}
    </MediaQuery.Provider>
}

export default RemovePaddingMediaQuery;
