import {useViewerOrderQuery, useViewerOrderSuspenseQuery} from "src/hook/order";
import {PaymentExecutionOptions, PaymentMethod, usePayment, useSuspensePaymentStorage, SuspensePayment} from "@firefly/fly-payment";
import {useCallback, useEffect} from "react";
import {useNavigateToOrderComplete} from "../../../suppport/router";



function useOrderPayment(orderId: string) {


    const {
        isPending,
        setPending,
        execute,
        check,
        isCheckFetching,
        isCompleted,
        confirmPaymentOrder,
        pendingPayment,
    } = usePayment(['order', orderId])

    const {
        data: order,
        isLoading: isOrderLoading,
    } = useViewerOrderSuspenseQuery(orderId!);

    const {
        query,
        remove
    } = useSuspensePaymentStorage();
    useEffect(() => {
        if (order) {
            const suspensePayments = query();
            const matchPayment = suspensePayments.find((payment: SuspensePayment) => {
                return payment.id === order.paymentOrderId;
            })
            if (matchPayment) {
                remove(matchPayment.identifier)
                confirmPaymentOrder({
                    id: matchPayment?.id,
                    outId: matchPayment?.outId
                })
            }
        }
    }, [order, query, remove]);


    const navigateToOrderComplete = useNavigateToOrderComplete();
    useEffect(() => {
        if (order?.paymentState === 'PAID') {
            navigateToOrderComplete(order).then()
            return;
        }
        if (isCompleted) {
            navigateToOrderComplete({
                id: pendingPayment?.outId
            }).then()
        }
    }, [pendingPayment, isCompleted, order, navigateToOrderComplete]);

    const executeOrderPayment = useCallback((order: {
        id: string,
        paymentOrderId: string
    }, method: PaymentMethod, args: Record<string, any>, options?: PaymentExecutionOptions) => {
        return execute({
            id: order.paymentOrderId,
            outId: order.id
        }, method, args, options).then((status) => {
            if (status === 'completed') {
                navigateToOrderComplete({
                    id: order.id
                }).then()
            }
        })
    }, [execute, navigateToOrderComplete])
    return {
        order,
        isOrderLoading,
        isCheckFetching,
        check,
        execute: executeOrderPayment,
        isPending,
        setPending,
    }
}

export default useOrderPayment;
