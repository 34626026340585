import styled from "styled-components";


export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  padding: 8vw;

  .image {
    height: 100%;
    width: 100%;
    //box-shadow: 1PX 1PX 5PX 4PX #00000008;
  }
`

export const ImageTip = styled.div`
  margin-top: 12px;
  color: #b1b1b1;
`
