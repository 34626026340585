import {useGraphQLClient} from "@firefly/fly-security";
import {useQuery} from "@tanstack/react-query";
import gql from "fake-tag";
import {ViewerTemplate} from "@firefly/fly-design-inv-data";


export type TemplateViewerHasPurchasedType = Pick<ViewerTemplate, 'viewerHasPurchased' | 'viewerHasOwned'>;
export default function useTemplateViewerHasPurchasedQuery(id: string, {
    enabled,
    // onSuccess,
    // suspense
}: {
    enabled?: boolean;
    // onSuccess?: (data: TemplateViewerHasPurchasedType) => void;
    // suspense: boolean
} = {
    // suspense: true
}) {
    const graphqlClient = useGraphQLClient();

    return useQuery<TemplateViewerHasPurchasedType>({
        queryKey: ['template', id, 'viewerHasPurchased'],
        queryFn: () => {
            return graphqlClient.query({
                query: gql`
                    query template($id: ID!) {
                        template(id: $id) {
                            id,
                            viewerHasOwned,
                            viewerHasPurchased
                        }
                    }
                `,
                variables: {
                    id: id
                }
            }).then((output) => {
                return output.template
            })
        },
        enabled: enabled,
        // onSuccess: onSuccess,
        // suspense: suspense,
    })
}
