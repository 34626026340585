// import {Field} from "react-vant";
import {DesignFormLayout} from "./styles";
import {useMergedState} from "@firefly/fly-hook";
import {Input, Form} from "antd-mobile";
// import {} from "react-vant";
// import useMergedState from "@firefly/fly-painter-util/es/hook/useMergedState";

interface DesignFormProps {
    value?: Record<string, string>;
    onChange?: (value: Record<string, string>) => void
}
function DesignForm(props: DesignFormProps) {
    const {
        value,
        onChange
    } = props;
    const [innerValue, setInnerValue] = useMergedState({}, {
        value: value,
        onChange: onChange
    })
    return <>
        <DesignFormLayout>
            <Form layout={"horizontal"}>
                <Form.Item
                    help={"为便于设计师联系请填写手机号"}
                    hasFeedback
                    // extra={`若未填写，请通过主页【我的】-【帮助】点击在线客服，回复"人工"联系 对接资料`}
                    // labelWidth={"4.6em"}
                    // tooltip={{
                    //     message: '为便于设计师联系请填写手机号.',
                    // }}
                    // intro={`若未填写，请通过主页【我的】-【帮助】点击在线客服，回复"人工"联系 对接资料`}
                    name="mobile"
                    label="手机号"
                    description={"若未填写，请通过主页【我的】-【帮助】点击在线客服，回复\"人工\"联系 对接资料"}
                >
                    <Input placeholder="请输入手机号便于设计师联系"
                           value={innerValue.mobile}
                           onChange={(value) => {
                               setInnerValue(innerValue => {
                                   return {
                                       ...innerValue,
                                       mobile: value
                                   }
                               })
                           }}/>
                </Form.Item>
            </Form>
        </DesignFormLayout>
    </>

}

export default DesignForm;
