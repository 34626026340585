import {
    useInfiniteQuery,
    useMutation,
    useQuery,
    useSuspenseInfiniteQuery,
    useSuspenseQuery
} from "@tanstack/react-query";
import gql from "fake-tag";
import {Order, OrderExtra, ProjectVariantIdentifier, TemplateLineItemAttributes} from "@firefly/fly-design-inv-data";
import {NodeConnection, PaymentState} from "src/typing";
import {useGraphQLClient} from "@firefly/fly-security";
import {InfiniteData} from "@tanstack/query-core";
// import {TemplateLineItemAttributes} from "src/typing";


function useViewerOrderQueryFn(id: string) {
    const graphqlClient = useGraphQLClient();
    return () => {
        return graphqlClient.query({
            query: gql`
                query order($id: ID!) {
                    viewer {
                        order(id: $id) {
                            id,
                            subject,
                            userId,
                            originalAmount,
                            amount,
                            paymentOrderId,
                            paymentState,
                            lineItems {
                                title,
                                type,
                                imageUrl,
                                targetId,
                                properties,
                                originalPrice,
                                price
                            }
                        }
                    }
                }
            `,
            variables: {
                id: id,
            }
        }).then((output) => {
            return output.viewer?.order;
        })
    }
}

export function useViewerOrderQuery(id: string, options?: {
    placeholderData?: any;
    // suspense?: boolean;
    enabled?: boolean;
}) {
    return useQuery<Order>({
        queryKey: ['viewer', 'order', id],
        queryFn: useViewerOrderQueryFn(id),
        placeholderData: options?.placeholderData,
        // suspense: options?.suspense,
        enabled: options?.enabled,
    })
}
export function useViewerOrderSuspenseQuery(id: string) {
    return useSuspenseQuery<Order>({
        queryKey: ['viewer', 'order', id],
        queryFn: useViewerOrderQueryFn(id),
    })
}

export function useViewerQueryPaymentStateQuery(id: string, options: {
    enabled?: boolean;
} = {}) {
    const {
        enabled,
    } = options;
    const graphqlClient = useGraphQLClient();
    return useQuery<Pick<Order, 'paymentState'>>({
        queryKey: ['viewer', 'order', id, 'paymentState'],
        enabled: enabled,
        queryFn: () => {
            return graphqlClient.query({
                query: gql`
                    query order($id: ID!) {
                        viewer {
                            order(id: $id) {
                                paymentState,
                            }
                        }
                    }
                `,
                variables: {
                    id: id,
                }
            }).then((output) => {
                return output.viewer?.order;
            })
        }
    })
}

export function useViewerOrdersSuspenseInfiniteQuery(params: {
    paymentState?: PaymentState
}, {
    // suspense = false
}: {
    // suspense?: boolean
} = {}) {
    const graphqlClient = useGraphQLClient();

    return useSuspenseInfiniteQuery<NodeConnection<Order>, any, InfiniteData<NodeConnection<Order>>, any, any>({
        initialPageParam: {},
        queryKey: ["viewer", "order", params],
        queryFn: (context) => {
            const {
                pageParam
            } = context;
            return graphqlClient.query({
                query: gql`
                    query meOrders($first: Int, $after: String, $paymentState: PaymentState) {
                        viewer {
                            orders(first: $first, after: $after, paymentState: $paymentState, sortBy: CREATED_AT) {
                                nodes {
                                    id,
                                    subject,
                                    originalAmount,
                                    amount,
                                    lineItems {
                                        title,
                                        imageUrl,
                                        sku,
                                        targetId,
                                        type,
                                        price,
                                        originalPrice,
                                        properties
                                    },
                                    paymentOrderId,
                                    paymentState,
                                    payment {
                                        method,
                                        paidAt
                                    }
                                    createdAt
                                },
                                pageInfo {
                                    hasPreviousPage,
                                    hasNextPage,
                                    startCursor,
                                    endCursor,
                                }
                            }
                        }
                    }
                `,
                variables: {
                    first: 10,
                    ...pageParam,
                    ...params,
                }
            }).then((output) => {
                return output.viewer.orders;
            })
        },
        getNextPageParam: (lastPage) => {
            const {
                pageInfo
            } = lastPage;
            return pageInfo.hasNextPage ? {
                after: pageInfo.endCursor
            } : undefined
        }
    })
}



export function useCreateOrderOfTemplatePaymentMutation() {

    const graphqlClient = useGraphQLClient();

    return useMutation<Order, any, {
        templateId: string;
        attributes: TemplateLineItemAttributes
        lineItems?: ProjectVariantIdentifier[];
        extra?: OrderExtra;
        extensions?: Record<string, string>
    }>({
        mutationFn: (variables) => {
            return graphqlClient.mutate({
                mutation: gql`
                    mutation createOrderOfTemplatePayment($input: CreateOrderOfTemplatePaymentInput) {
                        createOrderOfTemplatePayment(input: $input) {
                            order {
                                id,
                                paymentOrderId
                            }
                            error {
                                code,
                                message
                            }
                        }
                    }
                `,
                variables: {
                    input: variables
                }
            }).then((output) => {
                const createOrderOfTemplatePayment = output.createOrderOfTemplatePayment;
                if (createOrderOfTemplatePayment.error) {
                    throw createOrderOfTemplatePayment.error;
                }
                return createOrderOfTemplatePayment.order
            })
        },
        onSuccess: () => {

        },
        onSettled: () => {
        }
    })
}



export function useCreateOrderOfProjectMutation() {

    const graphqlClient = useGraphQLClient();

    return useMutation<Order, any, {
        lineItems?: ProjectVariantIdentifier[];
        extra?: OrderExtra;
    }>({
        mutationFn: (variables) => {
            return graphqlClient.mutate({
                mutation: gql`
                    mutation createOrderOfProject($input: CreateOrderOfProjectInput) {
                        createOrderOfProject(input: $input) {
                            order {
                                id,
                                paymentOrderId
                            }
                            error {
                                code,
                                message
                            }
                        }
                    }
                `,
                variables: {
                    input: variables
                }
            }).then((output) => {
                const createOrderOfProject = output.createOrderOfProject;
                if (createOrderOfProject.error) {
                    throw createOrderOfProject.error;
                }
                return createOrderOfProject.order
            })
        },
        onSuccess: () => {

        },
        onSettled: () => {
        }
    })
}



export function useCreateOrderOfTemplateProjectMutation() {

    const graphqlClient = useGraphQLClient();

    return useMutation<Order, any, {
        templateId: string;
        lineItems?: ProjectVariantIdentifier[];
        extra?: OrderExtra;
    }>({
        mutationFn: (variables) => {
            return graphqlClient.mutate({
                mutation: gql`
                    mutation createOrderOfTemplateProject($input: CreateOrderOfTemplatePaymentInput) {
                        createOrderOfTemplateProject(input: $input) {
                            order {
                                id,
                                paymentOrderId
                            }
                            error {
                                code,
                                message
                            }
                        }
                    }
                `,
                variables: {
                    input: variables
                }
            }).then((output) => {
                const createOrderOfTemplateProject = output.createOrderOfTemplateProject;
                if (createOrderOfTemplateProject.error) {
                    throw createOrderOfTemplateProject.error;
                }
                return createOrderOfTemplateProject.order
            })
        },
        onSuccess: () => {

        },
        onSettled: () => {
        }
    })
}