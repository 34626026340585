import {SizeType} from "src/typing";
import {Picture} from "@firefly/fly-design-core";
import {DoctypeMap} from "src/config";
import {
    TemplateCardBox,
    TemplateCardCover,
    TemplateCardMeta,
    TemplateCardMetaDetail,
    TemplateCardMetaDivider,
    TemplateCardMetaDoctype,
    TemplateCardMetaPhotoCount,
    TemplateCardMetaPhotoCountSuffix,
    TemplateCardMetaPhotoCountValue,
    TemplateCardMetaPrice,
    TemplateCardMetaTitle,
    TemplateCoverImage,
    TemplateCoverImageBox
} from "./styles";
import {template2item} from "src/util/format";
import {ViewerWorkTemplate} from "@firefly/fly-design-inv-data";
import {CSSProperties} from "react";


export interface TemplateCardProps {
    className?: string;
    style?: CSSProperties;
    value: Pick<ViewerWorkTemplate, 'id' | 'title' | 'type' | 'cover' | 'metadata' | 'doctype' | 'pricing' | 'attributes'>
    blur?: number | true,
    // radio?: number;
    width?: number | string | 'auto';
    height?: number | string | 'auto';
    minFit?: number;
    maxFit?: number;
    onClick?: () => void;
    hiddenPayment?: boolean;
    size?: SizeType;
}

export interface TemplateCardCoverImageProps {
    size?: SizeType;
    blur?: number | false;
    picture?: Picture;
    alt?: string;
}
export function TemplateCardImage({
    size = 'small',
    blur = 0,
    picture,
    alt,
}: TemplateCardCoverImageProps) {
    const blurUrl = picture? `${picture.url}?x-oss-process=image/resize,w_360/crop,h_720/format,jpg/quality,q_60/blur,r_30,s_35` : "";
    // const coverUrl = picture? `${picture.url}?x-oss-process=image/resize,w_360/crop,h_720/format,jpg/quality,q_60` : "/asset/placeholder.png";
    const coverUrl = template2item(picture, size);
    return <TemplateCoverImageBox style={{
        padding: blur ? blur : undefined,
        backgroundImage: blur ? `url(${blurUrl})` : undefined,
    }}>
        <TemplateCoverImage
            alt={`${alt}封面`}
            src={coverUrl}
        />
    </TemplateCoverImageBox>
}
function TemplateCard(props: TemplateCardProps) {

    const {
        className,
        style,
        value,
        width,
        height,
        maxFit = Infinity,
        minFit = 0,
        blur = false,
        size,
        onClick,
        hiddenPayment,
    } = props;

    const {
        title,
        cover,
        doctype,
        pricing,
        attributes
    } = value;

    const contentAspectRadio = (cover.width) / (cover.height);
    const aspectRadio = Math.max(Math.min(contentAspectRadio, maxFit), minFit);
    let fixWidth = width;
    if (typeof height === 'number') {
        fixWidth = (height - 42)  * aspectRadio;
    }
    return <TemplateCardBox className={className} style={{
        width: fixWidth,
        height: height,
        ...style,
    }} onClick={onClick}>
        <TemplateCardCover style={{
            paddingBottom: `${1/aspectRadio * 100}%`
        }}>
            <TemplateCardImage size={size} blur={blur ? 8 : blur} picture={cover} alt={title} />
            <TemplateCardMetaDoctype>
                {DoctypeMap[doctype]}
            </TemplateCardMetaDoctype>
            {/*{showPrice && pricing?.amount > 0 ? <TemplateCardMetaPrice>*/}
            {/*    ￥{pricing.amount}*/}
            {/*</TemplateCardMetaPrice> : null}*/}
        </TemplateCardCover>
        <TemplateCardMeta>
            <TemplateCardMetaTitle>
                {title}
            </TemplateCardMetaTitle>
            <TemplateCardMetaDetail>
                {!hiddenPayment && pricing && typeof pricing?.amount === 'number' ? <TemplateCardMetaPrice>
                    {pricing.amount > 0 ? `￥${pricing.amount}` : `免费`}
                </TemplateCardMetaPrice>: null}
                {typeof attributes?.replaceableImageCount === 'number' && attributes.replaceableImageCount > 0 ?
                    <TemplateCardMetaPhotoCount>
                        <TemplateCardMetaPhotoCountValue>
                            {attributes.replaceableImageCount}
                        </TemplateCardMetaPhotoCountValue>
                        <TemplateCardMetaPhotoCountSuffix>
                            图
                        </TemplateCardMetaPhotoCountSuffix>
                    </TemplateCardMetaPhotoCount>
                : null}
                {/*<TemplateCardMetaDivider  />*/}

                {/*<TemplateCardMetaDoctype>*/}
                {/*    {DoctypeMap[doctype]}*/}
                {/*</TemplateCardMetaDoctype>*/}
            </TemplateCardMetaDetail>


        </TemplateCardMeta>
    </TemplateCardBox>
}

export default TemplateCard;
