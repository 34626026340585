import React, {useMemo} from "react";
import WebappConfigContext, {WebappConfig, WebappConfigContextType} from "./WebappConfigContext";
import {useDeliveryView} from "../../hook/useDeliveryView";


export interface MiniappConfigProviderProps {
  children?: React.ReactNode;
}
function WebappConfigProvider(props: MiniappConfigProviderProps): React.ReactElement {
  const {
    children
  } = props;
  const {
    isFetching,
    data: value
  } = useDeliveryView<WebappConfig>("webapp", {

  });

  const ContextValue = useMemo<WebappConfigContextType>(() => {
    return {
      isPending: isFetching,
      value: value,
    }
  }, [isFetching, value]);
  return <WebappConfigContext.Provider value={ContextValue}>
    {children}
  </WebappConfigContext.Provider>
}

export default WebappConfigProvider;
