import React, {useMemo} from "react";
import classNames from "classnames";
import {CellType} from "./interface";
import {GridColumnType} from "./hooks/useGridLayout";


interface WaterfallVerticalBodyProps {
  gutter: number,
  layout: GridColumnType[];
  dataSource: CellType[];
}

function WaterfallBody(props: WaterfallVerticalBodyProps) {

  const {
    gutter,
    layout,
    dataSource,
  } = props;

  const keyCellMap: Map<React.Key, React.ReactNode> = useMemo(() => {
    const map = new Map();
    dataSource.forEach((item) => {
      map.set(item.key, item.children);
    })
    return map;
  }, [dataSource])


  return <div className={"watermark-body"} style={{
      paddingBottom: gutter
    }}
  >
    {layout.map((column, index) => {
      return <div key={`columns${index}`} className={"watermark-column"} style={{
        width: column.width
      }}
      >
        {column.cells.map(({key, layout}) => {

          return <div key={key} className={classNames("watermark-cell", {
            [`layout-w-${layout.width}`]: layout.width,
            [`layout-h-${layout.height}`]: layout.height
          })} style={{
            marginTop: gutter
          }}
          >
            {keyCellMap.get(key)}
          </div>
        })}
      </div>
    })}
  </div>
}


export default WaterfallBody;
