import {useMutation, useQueryClient} from "@tanstack/react-query";
import gql from "fake-tag";
import {useGraphQLClient} from "@firefly/fly-security";

export default function useRemoveStarTemplateMutation() {
  const queryClient = useQueryClient();
  const graphqlClient = useGraphQLClient();
  return useMutation({
    mutationFn: (variables: {
      starrableId: string
    }) => {
      return graphqlClient.mutate({
        mutation: gql`
          mutation removeStarTemplate($starrableId: ID!) {
            removeStarTemplate(input: {starrableId: $starrableId}) {
              error {
                code,
                message
              },
              starrable {
                id
              }
            }
          }
        `,
        variables: {
          starrableId: variables.starrableId
        }
      }).then((output) => {
        const removeStarTemplate = output.removeStarTemplate;
        if (removeStarTemplate.error) {
          throw removeStarTemplate.error
        }
        return removeStarTemplate.starrable
      })
    },
    onMutate: (variables) => {
      const optimistic = {
        viewerHasStarred: false,
      }
      queryClient.setQueryData(['template', variables.starrableId], (data: any) => {
        return {
          ...data,
          ...optimistic,
        }
      })
      return {
        optimistic
      }
    },
    onSuccess: (_, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: ['me', 'extra'],
      });
    },
    onError: (error, variables, context) => {
      queryClient.defaultMutationOptions().onError?.(error, variables, context);
      queryClient.setQueryData(['template', variables.starrableId], (data: any) => {
        return {
          ...data,
          viewerHasStarred: true
        };
      })
    },
    onSettled: (data, error, variables, context) => {
      // queryClient.invalidateQueries(['template', variables.starrableId])
    }
  })
}
