import React from "react";
import Inspect from "src/page/component/Inspect";
import {isWechat} from "@firefly/fly-defter";
import CopyLinkGridItem from "src/page/component/grid/CopyLinkGridItem";
import WechatShareGridItem from "src/page/component/grid/WechatShareGridItem";
import {Share, WechatAppBridge} from "@firefly/fly-bridge";
import {useParams} from "react-router";
import {cover2share} from "src/util/format";
import {useNavigate} from "react-router-dom";
import WeappGridItem from "../../component/grid/WeappGridItem";
import ActionPanel from "../../component/ActionPanel";
import {ImageContainer, ImageTip} from "./styles";
import {useViewerDesignQuery, useShareDesignMutation} from "../../../hook/design";
import {share2Link} from "src/util/web";
import {Image, Button, Grid} from "antd-mobile";

function ImageInspect() {
    const {
        id
    } = useParams();
    const {
        isLoading,
        data: work
    } = useViewerDesignQuery(id!);

    const [share, setShare] = React.useState<any>();
    const previewUrl = work?.preview.url;

    const {
        mutateAsync: shareMutate
    } = useShareDesignMutation();
    React.useEffect(() => {
        shareMutate({
            id: id!,
        }).then((target) => {
            setShare(target)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, setShare]);

    const navigate = useNavigate();
    function navigateToHome() {
        navigate('/')
    }

    const shareLink = share ? share2Link(share!.id) : undefined;
    const coverUrl = work ? cover2share(work.preview.url) : undefined;
    return <WechatAppBridge>
        <Share
            title={work?.title}
            description={""}
            coverUrl={coverUrl}
            link={shareLink!}
        >
            <Inspect className={"image-inspect"}>
                <Inspect.Content>
                    <ImageContainer>
                        <Image
                            // showLoading={isLoading}
                            fit={"contain"}
                            src={previewUrl}
                            onClick={() => {
                                if (isWechat()) {
                                    //TODO 未完成得
                                    // wx.previewImage({
                                    //     current: '', // 当前显示图片的 http 链接
                                    //     urls: [] // 需要预览的图片 http 链接列表
                                    // })
                                }
                            }}
                        />
                        <ImageTip>
                            长按图片保存到本地
                        </ImageTip>
                    </ImageContainer>
                </Inspect.Content>
                <Inspect.Action>
                    <ActionPanel>
                        <ActionPanel.Header>
                            继续操作/分享
                        </ActionPanel.Header>
                        <ActionPanel.Body>
                            <Grid
                                className={"image-action-grid"}
                                columns={5}
                            >
                                {isWechat() ? <WechatShareGridItem /> : null}
                                <CopyLinkGridItem link={shareLink}/>
                                {!isWechat() ? <WeappGridItem jump={{
                                    path: "/page/share/design-share",
                                    query: `id=${share?.id}`
                                }}  enabled={share} /> : null}
                            </Grid>
                        </ActionPanel.Body>
                        <ActionPanel.Footer>
                            <Button block color={"primary"} onClick={navigateToHome}>
                                回到主页
                            </Button>
                        </ActionPanel.Footer>
                    </ActionPanel>
                </Inspect.Action>
            </Inspect>
        </Share>
    </WechatAppBridge>
}

export default ImageInspect;
