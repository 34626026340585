import GridItem from "./GirdItem";
import Grid from "./Grid";


export type GridNamespaceType = typeof Grid & {
    Item: typeof GridItem
}

const GridNamespace: GridNamespaceType = Grid as GridNamespaceType;
GridNamespace.Item = GridItem;

export default GridNamespace;