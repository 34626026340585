import styled, {css} from "styled-components";
import {Button} from "antd-mobile";


const themeColor = `#2489ff`;

export const Page = styled.div`
`;


export const PageHeader = styled.div<{
    backgroundUrl: string
}>`
  
  width: 100%;
  background: ${props => `url(${props.backgroundUrl}) 50% 50%/cover no-repeat!important`};
  //height: 140px;
`;
export const PageHeaderContent = styled.div`
  padding: 0 16px 0;
`
export const PageBody = styled.div`
`;

export const PVipUser = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 75px;
  align-items: center;
  margin-bottom: 8px;
`;
export const PVipUserAvatar = styled.div`
  .at-avatar--large {
    width: 60px;
    height: 60px
  }
`;
export const PVipUserMetaDetail = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
export const PVipUserMetaNickname = styled.div`
  font-size: 18px;
`
export const PVipUserMetaVip = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: #666;
`

export const PVipTabs = styled.div`
  height: 48px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: linear-gradient(180deg,hsla(0,0%,100%,.5),#fff);
  backdrop-filter: blur(.53333rem);
  overflow: hidden;
`
export const VipPackageSelect = styled.div`
  
`;
export const VipPackageSelectContent = styled.div`
  padding-left: 16px;
  padding-top: 26px;
  padding-right: 10px;
  white-space: nowrap;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;


export const VipPackageOptionInner = styled.div`
  position: relative;
  width: 108px;
  height: 108px;
  border-radius: 8px;
  border: 3px solid transparent;
  //box-sizing: border-box;
  //background-color: #fff;
  
`;
export const VipPackageOption = styled.div<{
    selected: boolean
}>`
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
  border: 1px solid #e8eaec;
  border-radius: 8px;
  
  
  ${props => {
      if (props.selected) {
          return css`
            ${VipPackageOptionInner} {
              border-color: ${themeColor};
            }
          `
      }
  }}
  
`

export const VipPackageOptionBadge = styled.div`
  position: absolute;
  left: -2px;
  top: -10px;
  line-height: 20px;
  font-size: 12px;
  color: rgb(135, 71, 7);
  background: rgb(255, 214, 133);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 4px;
  border-radius: 4px;
`;
export const VipPackageOptionTitle = styled.div`
  padding-top: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #33383e;
  text-align: center;
`
export const VipPackageOptionAmount = styled.div`
  margin-top: 8px;
  color: #33383e;
  text-align: center;
  font-size: 12px;
`

export const VipPackageOptionAmountValue = styled.span`
  font-weight: 700;
  font-size: 28px;
  color: #33383e;
  
`
export const VipPackageOptionDescription = styled.div`
  margin-top: 8px;
  text-align: center;
  font-size: 12px;
  color: #333;
`


export const VipExplain = styled.div`
  padding: 10px 20px 0;
  color: #878787;
  line-height: 2;
  font-size: 12px;
`
export const CheckoutCounterBox = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 -2px 12px rgb(0 0 0 / 8%);
  padding-bottom: 20px;
`
export const CheckoutCounterBoxContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  
`;
export const CheckoutTotal = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  align-items: flex-end;
`
export const CheckoutTotalLabel = styled.span`
    line-height: 1.4;
`
export const CheckoutAmount = styled.div`
  color: #ff4733;
  font-size: 14px;
  
`;

export const CheckoutAmountValue = styled.span`
  font-weight: bold;
  font-size: 24px;
`
export const CheckoutAction = styled.div``;
export const CheckoutPaymentButton = styled(Button).attrs({
    color: "primary"
})`
  width: 200px;
  background: rgb(36, 137, 255);
  border: none;
`;
export const CheckoutAgreement = styled.div`
  color: rgb(127, 135, 146);
  padding: 0 16px;
  font-size: 12px;
  //text-align: center;
`;
