import {Flex} from "@firefly/fly-kda";
import React from "react";


export interface LayoutProps {
    children?: React.ReactNode;
}
function Layout(props: LayoutProps): React.ReactElement {
    const {
        children
    } = props;
    return <Flex
        align={"center"}
        justify={"center"}
        style={{height: "100%"}}>
        {children}
    </Flex>
}


export interface LayoutCenterProps {
    children?: React.ReactNode;
}
Layout.Center = function(props: LayoutCenterProps): React.ReactElement {
    const {
        children
    } = props;
    return <Flex.Item>
        {children}
    </Flex.Item>
}

export default Layout;