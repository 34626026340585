import React from "react";
import {useNavigate} from "react-router";
import RecommendTabPane from "./RecommendTabPane";
import TemplateTabPane from "./TemplateTabPane";
import {useSearchParams} from "react-router-dom";
import {Doctypes} from "src/config";
import GuideFloatingAdvert from "../../component/GuideFloatingAdvert";
import {SearchBar, Tabs} from "antd-mobile";
import "./index.scss";

function IndexPage() {

    const [searchParams, next] = useSearchParams();
    const active = searchParams.get("active")??undefined;
    const navigate = useNavigate();
    const tabs =  [
        {
            key: Doctypes.PAGE,
            title: "H5"
        },
        {
            key: Doctypes.IMAGE,
            title: "图片"
        },
    ];

    return <>
        <SearchBar
            className={"index-search-bar"}
            placeholder={"搜索您喜欢的"}
            onFocus={() => {
                navigate("/search")
            }}
            // readOnly
            // onClickInput={() => {
            //     navigate("/search")
            // }}
            style={{paddingBottom: 4}}
        />
        <div className={"homepage-content"}>
            <Tabs
                className={"homepage-tabs"}
                activeKey={active}
                activeLineMode={"fixed"}
                onChange={(key) => {
                    next({
                        active: key
                    }, {
                    });
                }}
                // onClickTab={({name}) => {
                //     next({
                //         active: `${name}`
                //     }, {
                //     });
                // }}
            >
                <Tabs.Tab key={"1"} title={"推荐"}>
                   <RecommendTabPane />
                </Tabs.Tab>
                {tabs.map(({key, title}) => {
                    return <Tabs.Tab key={key} title={title}>
                        <TemplateTabPane doctype={key as any} />
                    </Tabs.Tab>
                })}
            </Tabs>
        </div>

        <GuideFloatingAdvert />
    </>
}

export default IndexPage;
