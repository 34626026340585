import gql from "fake-tag";
import {useMutation, useQueryClient, useSuspenseQuery} from "@tanstack/react-query";
import {useInfiniteConnectionQuery} from "@firefly/fly-kda-mobile";
import {InvitationSend} from "@firefly/fly-design-inv-data";
import {Toast} from "antd-mobile";
import {isGraphQLException, useGraphQLClient} from "@firefly/fly-security";

export interface SendCreateInput extends Pick<InvitationSend, 'designId' | 'toName' | 'explain' | 'envelopeId'>{

    
}
export interface SendSaveInput {
    toName: string;
    explain: string;
}

export function useSendInfiniteConnectionQuery(designId: string) {
    const queryClient = useQueryClient();
    const graphqlClient = useGraphQLClient();
    return useInfiniteConnectionQuery<InvitationSend, any, any>({
        initialPageParam: {

        },
        queryKey: ["viewer", "design", designId, "send"],
        queryFn: (context) => {
            return graphqlClient.query({
                query : gql`
                    query sends($first: Int, $after: String, $designId: ID!) {
                        viewer {
                            design(id: $designId) {
                                sends(first: $first, after: $after, sortBy: CREATED_AT) {
                                    nodes {
                                        id,
                                        designId,
                                        toName,
                                        explain,
                                        createdAt
                                    }
                                    totalCount,
                                    pageInfo {
                                        hasPreviousPage,
                                        hasNextPage,
                                        startCursor,
                                        endCursor,
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    first: 10,
                    designId: designId,
                    ...context.pageParam
                }
            }).then((output) => {
                return output.viewer.design.sends;
            })
        }
    })
}

export function useSendSuspenseQuery(designId: string , sendId: string ) {
    const graphqlClient = useGraphQLClient();
    return useSuspenseQuery({
        queryKey: ["viewer", "design", designId, "send", sendId],
        queryFn: () => {
            return graphqlClient.query({
                query: gql`
                    query send($id: ID!) {
                        send(id: $id) {
                            id,
                            designId,
                            toName,
                            explain,
                            createdAt
                        }
                    }
                `,
                variables: {
                    designId: designId,
                    id: sendId,
                }
            }).then((value) => {
                return value.send
            })
        }
    })
}
export function useCreateSendByMutation() {
    const queryClient = useQueryClient();
    const graphqlClient = useGraphQLClient();
    return useMutation({
        mutationFn: (variables: SendCreateInput) => {
            return graphqlClient.mutate({
                mutation: gql`
                    mutation createSend($input: InvitationSendInput) {
                        createSend(input: $input) {
                            send {
                                id,
                                toName,
                                explain,
                                createdAt,
                            }
                            error {
                                code,
                                message
                            }
                        }
                    }
                `,
                variables: {
                    input: variables
                }
            }).then((output) => {
                const mutate = output.createSend
                if (mutate.error) {
                    throw mutate.error;
                }
                return mutate.design
            })
        },
        onSuccess: () => {

        },
        onSettled: (_1, _2, variables) => {
            queryClient.resetQueries({
                queryKey: ["viewer", "design", variables.designId]
            }).then()
        },
        meta: {
            onError: (error: any) => {
                if (isGraphQLException(error)) {
                    if (error.message === 'INVITATION_ERR_SEND_TO_NAME_REPEAT') {
                        Toast.show({
                            content: "当前宾客称呼已存在，请勿填写重复数据"
                        })
                        return true;
                    }
                }
            }
        }
    })
}

export function useSaveSendByMutation(designId: string) {
    const queryClient = useQueryClient();
    const graphqlClient = useGraphQLClient();
    return useMutation({
        mutationFn: (variables: Partial<InvitationSend>) => {
            return graphqlClient.mutate({
                mutation: gql`
                    mutation saveSend($input: InvitationSendInput) {
                        saveSend(input: $input) {
                            send {
                                id,
                                toName,
                                explain,
                                createdAt,
                            }
                            error {
                                code,
                                message
                            }
                        }
                    }
                `,
                variables: {
                    input: variables
                }
            }).then((output) => {
                const mutate = output.saveSend
                if (mutate.error) {
                    throw mutate.error;
                }
                return mutate.send
            })
        },
        onSuccess: () => {

        },
        onSettled: (_1, _2, variables) => {
            queryClient.invalidateQueries({
                queryKey: ["viewer", "design", designId]
            }).then()
        }
    })
}

export function useRemoveSendByMutation(designId: string) {
    const queryClient = useQueryClient();
    const graphqlClient = useGraphQLClient();
    return useMutation({
        mutationFn: (variables: Pick<InvitationSend, 'id'>) => {
            return graphqlClient.mutate({
                mutation: gql`
                    mutation removeSend($input: IdInput) {
                        removeSend(input: $input) {
                            error {
                                code,
                                message
                            }
                        }
                    }
                `,
                variables: {
                    input: variables
                }
            }).then((output) => {
                const mutate = output.removeSend
                if (mutate.error) {
                    throw mutate.error;
                }
                return mutate.send
            })
        },
        onSuccess: () => {

        },
        onSettled: (_1, _2, variables) => {
            queryClient.resetQueries({
                queryKey: ["viewer", "design", designId]
            }).then()
        }
    })
}