import { createScriptLoader } from "@firefly/fly-bridge";


declare global {
    const wx: any;
    const tt: any;
}


export namespace VConsoleSDK {
    let loadedPromise: Promise<void> | null;
    function load(): Promise<void> {
        return createScriptLoader("https://unpkg.com/vconsole@latest/dist/vconsole.min.js").then(() => {
            new window.VConsole();
        })
    }
    export function init() {
        if (loadedPromise) {
            return loadedPromise.catch(() => {
                return loadedPromise = load();
            })
        }
        return loadedPromise = load();
    }
}

