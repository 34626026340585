import {createContext} from "react";





export interface WebappConfig {
  /**
   * qiyukf:cff9919c088ac04268b5aab99adaca22
   * https://work.weixin.qq.com/kfid/kfc9b520c76bd0d154a
   */
  cs: string
}

export interface WebappConfigContextType {
  value: WebappConfig | undefined;
  isPending: boolean;
}


const WebappConfigContext = createContext<WebappConfigContextType>({
  value: {
    cs: "https://work.weixin.qq.com/kfid/kfc9b520c76bd0d154a"
  },
  isPending: false
});

export default WebappConfigContext;
