import {useMemo} from "react";
import {omitUndefined} from "@firefly/fly-defter";
import { Advert } from "./advert";

export const [landingUri, landing] =  (() => {
    const location = window.location;
    return [
        location.href,
        location.pathname
    ]
})();

const params = new URLSearchParams(window.location.search);
const {
    sem,
    ad,
    keyword,
} = (() => {

    const sem = params.get("sem");
    const ad = params.get("ad");
    const keyword = params.get("keyword");
    return {
        sem: sem??undefined,
        ad: ad??undefined,
        keyword: keyword??undefined,
    }
})();


function useAdvert(): Advert | null {


    return useMemo<Advert | null>(() => {
        return sem ? omitUndefined({
            landingUri: landingUri,
            landing: landing,
            sem,
            identifier: ad,
            keyword,
        }) as Advert : null;
    }, [])
}

export default useAdvert;