import React, {CSSProperties} from "react";
import cls from "classnames";
import {createNamespace} from "@firefly/fly-kda";
import {ReactComponent as ShareOutline} from "src/asset/service/share.svg";

const [bem] = createNamespace("card");

export interface CardActionType {
    icon: React.ReactNode;
    title: React.ReactNode;
    onClick?: () => void;
}

export interface CardProps {
    className?: string;
    style?: CSSProperties;
    actions?: CardActionType[];
    onClick?: () => void;
    extra?: React.ReactNode;
    children?: React.ReactNode;

}

export function Card(props: CardProps): React.ReactElement {
    const {
        className,
        style,
        actions,
        onClick,
        extra,
        children
    } = props;
    function render(action: CardActionType, index: number): React.ReactElement {
        const {
            icon,
            title
        } = action;
        return <div key={index} className={cls(bem("action"))} style={style}>
            <div className={cls(bem("action-btn"))}>
                <i className={cls(bem("action-icon"))}>
                    {icon}
                </i>
                <span className={cls(bem("action-title"))}>
                            {title}
                        </span>
            </div>
        </div>
    }

    return <div className={cls(bem())} onClick={onClick}>
        <div className={cls(bem("body"))}>
            <div className={cls(bem("extra"))}>
                {extra}
            </div>
            {children}
        </div>
        {actions ? <div className={cls(bem("actions"))}>
            {actions.reduce((value, action, index) => {
                if (index > 0) {
                    value.push(<div key={`${index}-divider`} className={cls(bem("action-divider"))} />)
                }
                value.push(render(action, index));
                return value;
            }, [] as React.ReactElement[])}
        </div> : null}
    </div>
}

export interface CardButtonProps {
    type?: string;
    onClick?: () => void;
    children?: React.ReactNode;
}
export function CardButton(props: CardButtonProps): React.ReactElement {
    const {
        type = "default",
        onClick,
        children,
    } = props;
    return <button className={cls(bem("btn", {
        [type!]: !!type
    }))} onClick={onClick}>

        {children}
        {/*<span className={cls(bem("btn-title"))}>*/}
        {/*    分享*/}
        {/*</span>*/}
    </button>
}