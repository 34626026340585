import {useEffect, useState} from "react";
import DocumentTitle from "react-document-title";
import {useSearchParams} from "react-router-dom";
import {useAuthenticationPrincipal} from "@firefly/fly-security";
import {useSecurity} from "../../../context/security";
import {Flex, Empty} from "@firefly/fly-kda";
import {Button} from "antd-mobile";

/**
 * 登录过期
 * @constructor
 */
function LoginExpired() {

    const [searchParams] = useSearchParams()
    const returnUri = searchParams.get("return")??`//${window.location.host}/`;
    // const navigateToLogin = useNavigateToLogin();

    const {
        isAuthenticated,
    } = useAuthenticationPrincipal();
    const {
        authenticate
    } = useSecurity();
    useEffect(() => {
        if (isAuthenticated) {
            window.location.href = returnUri;
        }
    }, [isAuthenticated, returnUri])
    const [loading, setLoading] = useState<boolean>();
    function handleLoginClick() {
        setLoading(true)
        authenticate({
            return: returnUri
        }).finally(() => {
            setLoading(false)
        });
    }
    return <DocumentTitle title={"登录已过期"}>
        <Flex direction={"column"}
              style={{ height: "100%", width: "100%"}}
              justify={"center"}
              align={"center"}
        >
            <Empty description={`登录已过期，请重新登录`}>
                <Button
                    className={"fly-button-primary"}
                    loading={loading}
                    color="primary"
                    shape={"rounded"}
                    style={{ width: "200px" }}
                    onClick={handleLoginClick}
                >
                    登录
                </Button>
            </Empty>
        </Flex>
    </DocumentTitle>
}


export default LoginExpired;