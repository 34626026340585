import {useRemoveSendByMutation, useSaveSendByMutation, useSendSuspenseQuery} from "../../../hook/send";
import {useNavigate, useParams} from "react-router-dom";
import React from "react";
import SendForm from "../../component/SendForm";
import {Button, Dialog} from "antd-mobile";
import {useBridge} from "@firefly/fly-security";
import Indicator from "src/component/Indicator";


function SendDetail() {
    const {designId: _designId, id} = useParams();
    const designId = _designId!;
    const bridge = useBridge();
    const {
        data
    } = useSendSuspenseQuery(designId, id!);

    const {
        mutateAsync: save
    } = useSaveSendByMutation(designId);
    const {
        mutateAsync: remove
    } = useRemoveSendByMutation(designId);

    const navigate = useNavigate()
    return <SendForm
        initialValues={data}
        submit={`保存`}
        onFinish={
            (values) => {
                return save({
                    id: id!,
                    toName: values.toName,
                    explain: values.explain,
                    envelopeId: "6579ba0fc4c081440699622a",
                }).then(() => {
                    navigate(-1);
                })
            }
        }
        extra={
            <Button
                className={"send-design__cancel"}
                color={"default"}
                shape={"rounded"}
                block
                onClick={() => {
                    return Dialog.confirm({
                        content: `确认是否删除`,
                        onConfirm: async () => {
                            return remove({
                                id: id
                            }).then(() => {
                                navigate(-1);
                            })
                        },
                    }).then()
                }}
            >
                删除
            </Button>
        }
    />
}

export default Indicator.withSuspense(SendDetail);