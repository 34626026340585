import React, {ReactNode, useMemo} from "react";
import DocumentTitle from "react-document-title";
import Panel from "./component/Panel";
import TemplateGrid from "./component/TemplateGrid";
import useTemplateLabelsQuery from "src/hook/useTemplateLabelsQuery";
import {useNavigateToTemplateList} from "src/suppport/router";
import {useDeliveryView} from "src/hook/useDeliveryView";
import styled from "styled-components";
import Footer from "../component/Footer";
import GridItem from "../component/grid/GirdItem";
import {Grid, Space} from "antd-mobile";
import {Loading} from "@firefly/fly-kda";

interface GridItem {
    icon: ReactNode;
    text: ReactNode;
    filters: Record<string, any>;
}

function RecommendTabPane() {

    // const [searchParams] = useSearchParams();
    // // const entrance = useMemo(() => {
    // //     return searchParams.get("entrance")??undefined
    // // }, [searchParams])
    const {
        isLoading,
        data: labels = [],
    } = useTemplateLabelsQuery();

    const navigateToTemplateList = useNavigateToTemplateList();

    const {
        data: scenes
    } = useDeliveryView<any[]>("scenes", {

    })
    const grids = useMemo<GridItem[]>(() => {
        return (scenes?.map((scene) => {
            return {
                icon: <img alt={scene.title} src={scene.icon} />,
                text: scene.title,
                filters: {
                    tags: scene.title
                },
            }
        })??[])
    }, [scenes]);
    return <DocumentTitle title={"推荐"}>
        <div className={"recommend-tab-pane"}>
            <Grid columns={5}>
                {grids.map(({
                    icon,
                    text,
                    filters
                }: any, i) => {
                    return <GridItem
                        key={i}
                        icon={icon}
                        text={text}
                        onClick={() => {
                            navigateToTemplateList({
                                filters: filters
                            })
                        }}
                    />
                })}
            </Grid>
            <Loading loading={isLoading}>
                <Space className={"pane-space"} direction={"vertical"} style={{
                    minHeight: 60,
                    "--gap": "20px"
                }}>
                    {labels.map(({
                        title, subtitle, filters, templates
                    }, i) => {
                        const nodes = templates?.nodes;
                        return <Panel
                            key={i}
                            title={title}
                            subtitle={subtitle}
                            onMoreClick={() => {
                                navigateToTemplateList({
                                    filters: filters
                                })
                            }}
                        >
                            <TemplateGrid gutter={6} data={nodes} />
                        </Panel>
                    })}
                </Space>
            </Loading>
            {!isLoading ? <Footer /> : null}
        </div>
    </DocumentTitle>
}

export default RecommendTabPane;
