import React, {useState} from "react";
import {useParams} from "react-router";
import {PaymentMethod} from "@firefly/fly-payment";
import {useViewerTemplateQuery} from "src/hook/template";
import SafeAreaPadding from "src/component/SafeArea/SafeAreaPadding";
import {
    Body,
    Footer, FooterContent,
    OrderItemCard,
    OrderItemCardBody,
    OrderItemCardHeader,
    OrderLineItem,
    OrderLineItemMetaCover,
    OrderLineItemMetaDetail,
    OrderLineItemMetaTitle,
    Page,
    PaymentButtonAmount
} from "../../payment/styles";
import DesignerMakeForm from "src/page/payment/component/DesignerMakeForm";
import DesignForm from "src/page/payment/component/DesignForm";
import useTemplateMakePayment from "./useTemplateMakePayment";
import PaymentMethodSelect from "src/page/payment/component/PaymentMethodSelect";
import {useNavigate} from "react-router-dom";
import NavBar from "src/page/component/NavBar";
import {HomeO} from "@react-vant/icons";
import {useViewReport} from "src/hook/view";
import PaymentLoadingOverlay from "src/page/payment/component/PaymentCheckLoadingOverlay";
import useConfigPaymentMethods from "../../../hook/useConfigPaymentMethods";
import {ProjectVariant} from "@firefly/fly-design-inv-data";
import {Button, Image} from "antd-mobile";
import {Divider, Loading} from "@firefly/fly-kda";
import {error as reportError} from "src/util/reporter";
import Indicator from "src/component/Indicator";
import PaymentConfirmDialog from "../../payment/component/PaymentConfirmDialog";

export interface TemplateMakePaymentProps {

}
function TemplateMakePayment(props: TemplateMakePaymentProps) {

    const {
        templateId
    } = useParams();
    const {
        isFetching: isTemplateLoading,
        data: template
    } = useViewerTemplateQuery(templateId!);

    const {
        variants,
        variantsLoading,
        selectedVariant,
        setSelectedVariant: _setSelectedVariant,
        execute,
        isPending,
        setPending,
        isCheckFetching,
        check,
    } = useTemplateMakePayment(templateId!);

    useViewReport(() => {
        return {
            label: 'TemplateMakePayment',
            extra: {
                templateId: templateId!
            }
        }
    }, [templateId])


    const [extra, setExtra] = useState<Record<string, string>>();

    const paymentMethods = useConfigPaymentMethods();
    const [paymentMethod, _setPaymentMethod] = useState<PaymentMethod>(() => {
        return paymentMethods[0]
    });
    const [paying, setPaying] = useState<boolean>(false);

    function setSelectedVariant(v: ProjectVariant | null) {
        if (paying) {
            return;
        }
        _setSelectedVariant(v);
    }
    function setPaymentMethod(v: PaymentMethod) {
        if (paying) {
            return;
        }
        _setPaymentMethod(v);
    }

    const amount = selectedVariant?.pricing.amount;
    function executePayment() {
        setPaying(true)
        execute(paymentMethod, {
            ...extra
        }).catch((reason) => {
            if (reason === 'cancel') {
                return;
            }
            reportError(`executePayment:`, reason);
        }).finally(() => {
            setPaying(false)
        })
    }
    const coverUrl = template?.cover?.url;
    const formatCoverUrl = coverUrl ? `${coverUrl}?x-oss-process=image/resize,w_360/crop,h_720/format,jpg/quality,q_60` : null;
    // const navigateToWeappp = useNavigateToWeappJumpAuthorize();
    const navigate = useNavigate();
    // function next() {
    //     //没什么影响这边就不检查成功了
    //     // return new Promise<void>((resolve) => {
    //     //     resolve(navigate(-1))
    //     // })
    //     return new Promise<void>((resolve) => {
    //         resolve(navigate("/homepage/me", {
    //             // replace: true
    //         }))
    //     })
    // }

    return <SafeAreaPadding>
        <Page>
            <NavBar
                title={"设计师服务"}
                right={<>
                    <HomeO fontSize={22} />
                </>}
                onRightClick={() => {
                    navigate("/")
                }}
            />
            {isTemplateLoading ? <Loading /> : null}
            {template ? <>
                <Body>
                    <OrderItemCard>
                        <OrderItemCardHeader>
                            模板
                        </OrderItemCardHeader>
                        <OrderItemCardBody>
                            <OrderLineItem>
                                <OrderLineItemMetaCover>
                                    <Image
                                        src={formatCoverUrl??""}
                                        fit={"contain"}
                                    />
                                </OrderLineItemMetaCover>
                                <OrderLineItemMetaDetail>
                                    <OrderLineItemMetaTitle>
                                        {template.title}
                                    </OrderLineItemMetaTitle>
                                </OrderLineItemMetaDetail>
                            </OrderLineItem>
                        </OrderItemCardBody>
                    </OrderItemCard>
                    <OrderItemCard>
                        <DesignerMakeForm
                            subtitle={"服务推荐"}
                            selectedVariantIdentifier={selectedVariant?.identifier}
                            variants={variants}
                            onSelect={(_, variant) => {
                                setSelectedVariant(variant)
                            }}
                        >
                            {selectedVariant ? <>
                                <Divider style={{margin: "4px 0"}} />
                                <DesignForm value={extra} onChange={v => setExtra(v)} />
                            </> : null}
                        </DesignerMakeForm>
                    </OrderItemCard>
                    <OrderItemCard padding={false}>
                        <PaymentMethodSelect
                            selectedValue={paymentMethod}
                            onSelect={(method) => {
                                setPaymentMethod(method)
                            }}
                            methods={paymentMethods}
                        />
                    </OrderItemCard>
                </Body>
                <Footer>
                    <FooterContent>
                        <Button
                            className={"fly-button-primary"}
                            loading={isTemplateLoading || paying}
                            color={"primary"}
                            onClick={executePayment}
                            disabled={!amount}
                            block
                        >
                            立即支付 {amount && amount > 0 ? <PaymentButtonAmount>￥{amount}</PaymentButtonAmount> : null}
                        </Button>
                    </FooterContent>
                </Footer>
            </>: null}
            {/*{completed ? <MakePaymentResult>*/}
            {/*    <Result*/}
            {/*        title={"完成"}*/}
            {/*        subtitle={template?.title}*/}
            {/*        extra={*/}
            {/*            <Space direction={"vertical"} gap={16} block>*/}
            {/*                <Button block plain type={"primary"} onClick={() => {*/}
            {/*                    return navigateToContactMake().then();*/}
            {/*                }}>*/}
            {/*                    联系设计师制作*/}
            {/*                </Button>*/}
            {/*                <Button block type={"primary"} onClick={next}>*/}
            {/*                    完成*/}
            {/*                </Button>*/}
            {/*            </Space>*/}
            {/*        }*/}
            {/*    />*/}
            {/*</MakePaymentResult> : null}*/}
            <PaymentLoadingOverlay
                visible={!isPending && isCheckFetching}
            />
            <PaymentConfirmDialog
                // message={"请确认支付是有已完成"}
                // confirmButtonText={"已完成支付"}
                // confirmButtonColor={"#f55"}
                // cancelButtonText={"遇到问题，重新支付"}
                // cancelButtonColor={"#777"}
                // showCancelButton
                visible={isPending}
                onCancel={() => {
                    setPending(false);
                }}
                onOk={() => {
                    check().then();
                    setPending(false)
                }}
            />
        </Page>
    </SafeAreaPadding>
}


export default TemplateMakePayment;
