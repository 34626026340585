import styled from "styled-components";


export const Page = styled.div`
    
`;
export const PageBody = styled.div`
    
`;

export const Section = styled.div`
  padding: 20px 10px;
`
export const SectionHeader = styled.h3`
  font-size: 16px;
  margin-bottom: 22px;    
  border-left: 3px solid #ff1c74;
  padding-left: 10px;
`
export const SectionBody = styled.div`
`

export const ServiceDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
`
export const ServiceAvatar = styled.div`
    font-size: 52px;
`
export const ServiceTitle = styled.h3`
    font-size: 18px;
  margin-bottom: 36px;
`
export const ServiceBody = styled.div`
  font-size: 18px;
  text-align: center;
`
export const ServiceTime = styled.div`
  margin-bottom: 10px;
  svg {
    vertical-align: bottom;
  }
`
export const ContactGroup = styled.div`
  width: 100%;
  padding: 10px 16px;
  
`

export const DesignerQRCodeImage = styled.img`
  width: 300px;
  height: 300px;
`;
