import React from "react";
import {createNamespace} from "@firefly/fly-kda";
import "./style/index.scss";
import cls from "classnames";
import { Grid } from "antd-mobile";

const [bem] =createNamespace("grid-item");
export interface GridItemProps {
    icon: React.ReactNode;
    text: React.ReactNode;
    onClick?: () => void;
}
function GridItem(props: GridItemProps) {
    const {
        icon,
        text,
        onClick,
    } = props;

    return <Grid.Item className={cls(bem())} onClick={onClick}>
        <div className={cls(bem("content", {
            "center": true,
            "clickable": true,
        }))}>
            <div className={cls(bem("icon"))}>
                {icon}
            </div>
            <div className={cls(bem("text"))}>
                {text}
            </div>
        </div>
    </Grid.Item>
}

export default GridItem;