import {Ball2, MainButton,} from "./styles";
import {Service} from "@react-vant/icons"
import useCustomerService from "../../hook/useCustomerService";

export interface HelpFloatingBallProps {
}
function HelpFloatingBall(props: HelpFloatingBallProps) {
    const {
    } = props;

    const {
        navigate,
        isPending,
    } = useCustomerService();
    // function navigateToHelp() {
    //     return navigateToCustomerService()
    // }
    return <>
        <Ball2 style={{
            right: 12,
            bottom: 200
        }}>
            <MainButton onClick={navigate} >
                {/*<Chat />*/}
                <Service  />
            </MainButton>
        </Ball2>
        {/*<Ball offset={{*/}
        {/*    right: 12,*/}
        {/*    bottom: 120*/}
        {/*}} adsorb={{*/}
        {/*    distance: 6*/}
        {/*}}>*/}
        {/*    {({}) => {*/}
        {/*        return <MainButton onClick={navigate} >*/}
        {/*            /!*<Chat />*!/*/}
        {/*            <Service  />*/}
        {/*        </MainButton>*/}
        {/*    }}*/}
        {/*</Ball>*/}
    </>
}

export default HelpFloatingBall;
