import {CloseButton, GuideContent, GuideImage, GuidePopup} from "./styles";
import GuideAddMyWeappURL from "src/asset/guide/guide-add-my-weapp.png";
import GuideOkButtonURL from "src/asset/guide/guide-ok-button.png";
import {Mask} from "antd-mobile";


export interface GuideAddMyWeappPopupProps {
    visible?: boolean;
    onClose?: () => void;
}

function GuideAddMyWeappPopup(props: GuideAddMyWeappPopupProps) {
    const {
        visible,
        onClose
    } = props;

    return <Mask
        // position={"top"}
        visible={visible}
        opacity={0.8}
        style={{
            "--z-index": "1010"
        }}
        onMaskClick={onClose}

        // onClose={onClose}
        // onClick={onClose}

    >
        <GuideContent>
            <GuideImage alt={"添加到我的小程序"} src={GuideAddMyWeappURL} />
            <CloseButton>
                <img alt={"我知道了"} src={GuideOkButtonURL} />
            </CloseButton>
        </GuideContent>
    </Mask>
}

export default GuideAddMyWeappPopup;