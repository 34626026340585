import React from "react";


interface WaterfallCellProps {
  key?: React.Key;
  width: number;
  height: number;
  children?: React.ReactNode | ((width: number, height: number) => React.ReactNode);
}

function WaterfallCell(props: WaterfallCellProps) {
  return null;
}


export default WaterfallCell;
