import React, {useEffect, useMemo, useRef} from "react";
import classNames from "classnames";
import cls from "classnames";
import {useMergedState} from "@firefly/fly-hook";
import {Doctype} from "@firefly/fly-design-core";
import {Empty, Loading} from "@firefly/fly-kda";
import {ViewerWorkTemplate} from "@firefly/fly-design-inv-data";
import "./style/index.scss";
import {SizeType} from "src/typing";
// import {Doctype} from "@firefly/fly-painter-view";
import TemplateCard from "src/component/TemplateCard";
import {useTemplatesInfiniteQuery} from "src/hook/template";
import {useNavigateToTemplate} from "src/suppport/router";
import TemplateQueryFilters, {TemplateQueryFiltersValue} from "./TemplateQueryFilters";
import Waterfall from "src/component/Waterfall";
import {ScrollRestoration} from "src/restoration";
import {event} from "src/util/reporter";
import {GLOBAL} from "../../../config";
import InfiniteScroll from "../../../component/InfiniteScroll";


export interface TemplateListProps {
    className?: string;
    doctype?: Doctype;
    keyword?: string;
    hiddenFilters?: boolean;
    filters?: TemplateQueryFiltersValue;
    onFiltersChange?: (value: TemplateQueryFiltersValue) => void;
}
function TemplateList({
    className,
    doctype,
    keyword,
    hiddenFilters,
    filters,
    onFiltersChange,
}: TemplateListProps) {

    const [innerFilters, setInnerFilters] = useMergedState<TemplateQueryFiltersValue>({}, {
        value: filters,
        onChange: onFiltersChange,
    });

    const filterDoctype = doctype??(innerFilters?.doctype as Doctype);
    const filterTag = innerFilters?.tags ? [innerFilters.tags] : undefined;
    const {
        data,
        items,
        isLoading,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
        hasMore,
        loadMore,
    } = useTemplatesInfiniteQuery({
        keyword: keyword,
        doctype: filterDoctype,
        tags: filterTag,
    });
    const total = (data?.pages??[]).reduce((value, page) => {
        return value + page.nodes.length
    }, 0)
    const navigateToTemplate = useNavigateToTemplate();
    const {
        column,
        gutter,
        size,
    } = useMemo<{
        gutter: number;
        column: number;
        size: SizeType;
    }>(() => {
        if (window.matchMedia('(min-width: 1000px)').matches) {
            return {
                gutter: 14,
                column: 6,
                size: 'large',
            };
        }
        if (window.matchMedia('(min-width: 600px)').matches) {
            return {
                gutter: 12,
                column: 5,
                size: 'middle',
            };
        }
        return {
            gutter: 10,
            column: 3,
            size: 'small',
        };
    }, []);


    const filtersRef = useRef<any>();
    filtersRef.current = innerFilters;

    useEffect(() => {
        if (hasNextPage === false) {
            const label =  `doctype:${filterDoctype},tag:${filterTag}`;
            //没有下页了 上报
            event('view_end', {
                "category": "templates",
                "label": label,
                "value": data?.pages.length
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasNextPage]);


    function renderContent() {

        if (isLoading) {
            return <div className={cls("template-list__placeholder")}>
                <Loading />
            </div>
        }
        if (Number(total) === 0) {
            return <div className={cls("template-list__placeholder")}>
                <Empty description={"没有找到您所需的模板"}/>
            </div>
        }
        return <React.Fragment>
            <Waterfall width={window.innerWidth} gutter={gutter} column={column} >
                {data?.pages.map(({nodes}) => {
                    return nodes.map((node) => {
                        const {
                            id,
                            cover,
                        } = node;
                        const coverSize = cover ? cover : {width: 200, height: 200};
                        const width = coverSize.width || 1;
                        const height = coverSize.height || 1;
                        const caleHeight = Math.min(width * 2, height)  + 42;
                        return <Waterfall.Cell
                            key={id}
                            width={width}
                            height={caleHeight}
                        >
                            <TemplateCard
                                value={node as ViewerWorkTemplate}
                                width={"100%"}
                                minFit={0.5}
                                size={size}
                                hiddenPayment={GLOBAL.template.paymentHidden}
                                // blur
                                onClick={() => {
                                    navigateToTemplate(node);
                                }}
                            />
                        </Waterfall.Cell>
                    })
                })}
            </Waterfall>
            <InfiniteScroll
                hasMore={hasMore}
                loadMore={loadMore}
            />
            {/*{total > 0 ?*/}
            {/*    <Footer*/}
            {/*        enabled={!isFetchingNextPage}*/}
            {/*        isFetchingNextPage={isFetchingNextPage}*/}
            {/*        hasNextPage={hasNextPage}*/}
            {/*        fetchNextPage={fetchNextPage}*/}
            {/*    /> : null*/}
            {/*}*/}
        </React.Fragment>
    }
    return <div className={classNames("template-list", className)}>
        {!hiddenFilters ? <>
            <TemplateQueryFilters
                doctype={doctype}
                value={innerFilters}
                onChange={setInnerFilters}
                // hiddenUseOption={isPageDoctype(doctype)}
            />
        </> : null}
        <ScrollRestoration restorationKey={`templates:${doctype}`}>
            <div className={"template-list-content"}>
                {renderContent()}
            </div>
        </ScrollRestoration>
    </div>

}

export default TemplateList;
