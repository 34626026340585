import {isMiniapp, isWechat, omitUndefined} from "@firefly/fly-defter";
import {navigateToMiniapp} from "@firefly/fly-bridge";
import {useDocumentMeta} from "@firefly/fly-hook";
import {useAuthentication} from "@firefly/fly-security";


export interface WebAuthenticationVariables {
    return?: string
}
export function useWebAuthentication() {

    const passport = useDocumentMeta(["firefly", "passport"]);

    const {
        authenticate
    } = useAuthentication<WebAuthenticationVariables>({
        authenticateFn: (variables) => {
            const {
                return: returnUri = window.location.href
            } = variables;
            if (isMiniapp()) {
                return navigateToMiniapp(`/page/passport/quick-login`, {

                }, {
                    replace: false
                })
            } else if (isWechat()) {
                window.location.href = `/passport/sns-oauth-login.html?type=WECHAT_OFFIACCOUNT&scope=USERINFO&return=${encodeURIComponent(returnUri)}`;
            } else {
                const query = new URLSearchParams(omitUndefined<any>({
                    "lang": "zh-CN",
                    "return": returnUri,
                }))
                const service = `${window.location.origin}/passport/cas-login.html?${query}`;
                window.location.href = `${passport}/cas/login?service=${encodeURIComponent(service)}`
                // window.location.href = `/login?return=${encodeURIComponent(returnUri)}`;
            }

            return new Promise<void>((resolve) => {
                //等待页面刷新，这边无意义忽略
                setTimeout(resolve, 3000)
            })
        }
    })
    // const authenticate = useCallback<(returnUri?: string) => Promise<void>>((returnUri: string = window.location.href) => {
    //
    // }, [passport])
    return {
        authenticate
    }
}