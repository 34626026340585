import InternalWaterfall from "./Waterfall";
import WaterfallCell from "./WaterfallCell";
import "./index.scss";

type WaterfallType = typeof InternalWaterfall & {
  Cell: typeof WaterfallCell
};

const Waterfall = InternalWaterfall as WaterfallType;
Waterfall.Cell = WaterfallCell;
export default Waterfall;
