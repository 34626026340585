import {useEffect, useState} from "react";
import {PaymentMethod, usePayment} from "@firefly/fly-payment";
import {Order, OrderExtra, ProjectVariant, TemplateLineItemAttributes} from "@firefly/fly-design-inv-data";
import {useViewerTemplateQuery} from "src/hook/template";
import {useCreateOrderOfTemplatePaymentMutation} from "src/hook/order";
import {useNavigateToOrderComplete, useNavigateToTemplate} from "../../../suppport/router";
import {useProjectVariantsQuery} from "src/hook/project-variant";
import {useSecurity} from "../../../context/security";

export default function useTemplatePayment(templateId: string, {
    attributes
}: {
    attributes: TemplateLineItemAttributes
}) {

    const {
        isPending,
        setPending,
        execute,
        check,
        isCheckFetching,
        isCompleted,
        pendingPayment,
    } = usePayment(['template', templateId]);

    const {
        requestAuthenticated,
    } = useSecurity();

    const {
        isPending: isTemplateLoading,
        data: template
    } = useViewerTemplateQuery(templateId!, {
        enabled: !!templateId,
    });
    const {
        isFetching: isVariantsLoading,
        data: variants = []
    } = useProjectVariantsQuery("TEMPLATE_MAKE", ["in24h", "in3h", "in1h"])
    const [selectedVariant, setSelectedVariant] = useState<ProjectVariant | null>(null)
    const {
        mutateAsync: createOrderOfTemplatePayment,
    } = useCreateOrderOfTemplatePaymentMutation();
    const [templateOrder, setTemplateOrder] = useState<Order>();


    useEffect(() => {
        createOrderOfTemplatePayment({
            templateId: templateId,
            attributes: attributes,
            lineItems: undefined,
            extra: {},
        }).then((order) => {
            setTemplateOrder(order);
        })

    }, [createOrderOfTemplatePayment, templateId, attributes])

    function executeOrderPayment(order: Order, method: PaymentMethod) {
        const location = window.location;
        const url = new URL(`${location.protocol}//${location.host}/order-payment`);
        url.searchParams.set("orderId", order.id);
        url.searchParams.set("redirect", "template");
        url.searchParams.set("extra", JSON.stringify({
            templateId: templateId
        }));
        return execute({
            id: order.paymentOrderId,
            outId: order.id,
        }, method, {
            returnUrl: url.toString()
        }).then((status) => {
            if (status === 'completed') {
                navigateToOrderComplete({
                    id: order.id
                }).then()
                return;
            }
            if (status === 'pending') {
                return
            }
        });
    }
    function executeTemplatePayment(method: PaymentMethod, extra: OrderExtra = {}) {
        return requestAuthenticated().then(() => {
            if (templateOrder && Object.keys(extra).length <= 0 && !selectedVariant) {
                return executeOrderPayment(templateOrder, method);
            }
            const selectedProjectIdentifier = selectedVariant? {
                project: selectedVariant.project,
                identifier: selectedVariant.identifier,
            } : undefined;
            return createOrderOfTemplatePayment({
                templateId: templateId,
                attributes: attributes,
                lineItems: selectedProjectIdentifier ? [selectedProjectIdentifier] : undefined,
                extra: extra,
                // extensions: attributes,
            }).then((order) => {
                return executeOrderPayment(order, method);
            })
        })

    }
    async function createOrder(extra: OrderExtra = {}) {
        await requestAuthenticated()
        const selectedProjectIdentifier = selectedVariant? {
            project: selectedVariant.project,
            identifier: selectedVariant.identifier,
        } : undefined;
        return createOrderOfTemplatePayment({
            templateId: templateId,
            attributes: attributes,
            lineItems: selectedProjectIdentifier ? [selectedProjectIdentifier] : undefined,
            extra: extra,
            // extensions: attributes,
        });
    }
    const amount = template ? template.pricing.amount +  (selectedVariant ? selectedVariant?.pricing.amount : 0) : null;


    // const enabledCheckState = template && template.viewerHasPurchased;
    const viewerHasPurchased = template && template.viewerHasPurchased;

    const navigateToOrderComplete = useNavigateToOrderComplete();
    const navigateToTemplate = useNavigateToTemplate();

    useEffect(() => {
        if (pendingPayment && isCompleted) {
            navigateToOrderComplete({
                id: pendingPayment?.outId,
            }).then()
        }
        if (viewerHasPurchased) {
            return navigateToTemplate({
                id: templateId,
            }, {
                replace: true,
            })
        }
    }, [templateId, pendingPayment, isCompleted, viewerHasPurchased, navigateToOrderComplete, navigateToTemplate])

    return {
        isTemplateLoading,
        template: template,
        isVariantsLoading,
        variants: variants,
        selectedVariant,
        setSelectedVariant,
        amount: amount,
        checkEnabled: template && template.viewerHasPurchased === false,
        viewerHasPurchased: viewerHasPurchased,
        isCheckFetching,
        check: check,
        execute: executeTemplatePayment,
        isPending: isPending && !viewerHasPurchased,
        setPending,
        isCompleted: isCompleted || viewerHasPurchased,
        createOrder,
    }
}
