import {Doctype} from "@firefly/fly-design-core";
import {ScrollRestoration} from "src/restoration";
import TemplateList from "../component/TemplateList";
import {atom, useAtom} from "jotai";

interface TemplateTabPaneProps {
    doctype?: Doctype;
}

const filtersAtom = atom<{
    doctype?: string | null;
    style?: string | null;
    use?: string | null;
}>({});

function TemplateTabPane(props: TemplateTabPaneProps) {
    const {
        doctype
    } = props;
    const [filters, setFilters] = useAtom(filtersAtom, `template-tab-pane:doctype-${doctype}`);

    return <div className={"template-tab-pane"}>
        <TemplateList
            doctype={doctype}
            filters={filters}
            onFiltersChange={setFilters}
        />
    </div>
}

export default TemplateTabPane;
