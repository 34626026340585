

declare global {
    const gtag: Function;
}


export namespace TagEvent {


    function event(name: string, parameters: Record<string, any>) {
        if (window.gtag) {
            gtag(`event`, name, parameters)
        }
    }



    interface ContentType {
        type: string;
        id: string;
    }
    export function selectContent(content: ContentType) {
        event(`select_content`, {
            content_type: content.type,
            content_id: content.id,
        })
    }
    interface SelectItemType {
        id: string;
    }
    export function selectItem(parameters: SelectItemType) {
        event(`select_item`, {
            items: [
                {
                    item_id: parameters.id,
                }
            ]
        })
    }
    interface ViewItemType {
        id: string;
    }
    /**
     * 查看模板
     * @param parameters
     */
    export function view_item(parameters: ViewItemType) {
        event(`view_item`, {
            items: [
                {
                    item_id: parameters.id,
                }
            ]
        })
    }


}