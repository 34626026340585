import styled from "styled-components";
import {ReactComponent as WeappSVG} from "src/asset/icon/weapp.svg";


export const Affix = styled.div<{
    visible?: boolean
}>`
   position: fixed;
  left: 24px;
  top: 24px;
  z-index: 100;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 50%;
  width: 38px;
  height: 38px;
  align-items: center;
  justify-content: center;
  color: #000;
  display: ${props => props.visible ? 'flex' : 'none'};
    box-shadow: 2px 2px 4px 1px #8f8f8fb5;
`;


export const EndPageAction = styled.div`
    margin-top: 10px;
`

// export const WeappButton = styled(Button)`
//   background: #00b40b;
//   border: 1px solid #00b40b;
//
//   padding: 0 30px;
//   font-size: 16px;
//   height: 42px;
//   border-radius: 18px;
// `
export const WeappButtonIcon = styled(WeappSVG)`

`;

export const TemplateIndicatorLayout = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const Layout = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
