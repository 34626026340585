import React, {Suspense} from "react";
import {Loading} from "@firefly/fly-kda";
import Layout from "../Layout";


export interface IndicatorProps {
    fullscreen?: boolean;
}
function Indicator(props: IndicatorProps): React.ReactElement {
    const {
        fullscreen
    } = props;

    const loading = <Loading />;
    if (fullscreen) {
        return <Layout>
            <Layout.Center>
                {loading}
            </Layout.Center>
        </Layout>
    }
    return loading
}

export interface IndicatorSuspenseProps {
    children?: React.ReactNode;
}
Indicator.Suspense = function(props: IndicatorSuspenseProps): React.ReactElement {
    const {
        children
    } = props;
    return <Suspense fallback={<Indicator fullscreen />}>
        {children}
    </Suspense>
}

Indicator.withSuspense = function<P extends {}>(Component: React.ComponentType<P>): React.ComponentType<P> {
    return function(props: P) {
        return <Indicator.Suspense>
            {React.createElement(Component, props)}
        </Indicator.Suspense>
    }
}

export default Indicator;