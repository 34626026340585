import styled from "styled-components";
// import { Dialog } from "react-vant";
import { Dialog as AntDialog, Button} from "antd-mobile";

export const Page = styled.div`
  background: #f2f1f6;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;


export const Body = styled.div`
  padding: 10px 10px 86px;
  flex: 1;
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  
  background: #fff;
  //box-shadow: 0 0 3PX 4PX #0000000d;
  box-shadow: 0 -2px 12px rgb(0 0 0 / 8%);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;
export const FooterContent = styled.div`
  width: 100%;
  padding: 15px;
  gap: 10px;
  display: flex;
  flex-direction: row;
`;


export const PVipButton = styled(Button)`
  flex: 2;
  height: 42px;
  font-size: 12px;
  border-radius: 6px;
  background: linear-gradient(90deg,#fdf4e4,#ffe1b9 100%,#ffe1b9 0);
  color: #ed8d22;
  border-width: 0;    
  font-weight: 600;
`
export const PaymentButton = styled(Button)`
  flex: 3;
  height: 42px;
  border-radius: 6px;
  border-width: 0;
  
`
export const PaymentButtonAmount = styled.span`
`

export const OrderItemCard = styled.div<{
    padding?: boolean
}>`
  border-radius: 4px;
  background: #fff;
  margin-bottom: 10px;
  padding: ${props => props.padding !== false ? 10 : 0}px;
`;
export const OrderItemCardHeader = styled.div`
  color: #b0acac;
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 6px;
`;
export const OrderItemCardBody = styled.div`
`
export const OrderLineItem = styled.div`
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f5f3f3;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
  &:first-child {
    padding-top: 0;
  }
`
export const OrderLineItemMetaCover = styled.div`
  //width: 75px;
  //height: 75px;
  width: 60px;
  height: 60px;
  background: #f5f5f5;
  flex-shrink: 0;
  .adm-image {
    width: 100%;
    height: 100%;
  }
`
export const OrderLineItemMetaDetail = styled.div`
  margin-left: 10px;
  overflow: hidden;
`
export const OrderLineItemMetaTitle = styled.div`
  font-size: 16px;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
export const OrderLineItemMetaAmount = styled.div`
  margin-top: 14px;
  //color: #f9a473;
  color: #d71616;
  font-size: 18px;
`;
export const OrderLineItemMetaOriginalAmount = styled.span`
  //color: #df0000;
  //font-size: 18px;
  color: #555;
  font-size: 14px;
  text-decoration: line-through;
  margin-left: 4px;
`;


//
// export const Result = styled.div`
// `
// export const ResultIcon = styled.div`
//   text-align: center;
// `
// export const ResultTitle = styled.div`
//   color: #333;
//   font-size: 16px;
//   line-height: 1.8;
//   text-align: center;
// `
// export const ResultBody = styled.div`
//   display: flex;
//   flex-direction: column;
//   padding: 40px 15px 30px;
//   background: #fff;
// `
// export const ResultFooter = styled.div`
//   width: 100%;
//   padding: 20px;
// `
export const PaymentConfirmDialog2 = styled(AntDialog)`
    .adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button  {
        padding: 6px;
        --adm-font-size-10: 15px;
        line-height: 38px;
        color: rgb(119, 119, 119);
    }
`

// export const PaymentConfirmDialog = styled(Dialog)`
//   .rv-dialog__content--isolated {
//     min-height: 72px;
//   }
//   .rv-dialog__message {
//     color: #666;
//     font-size: 16px;
//   }
// `

