import styled from "styled-components";


export const Page = styled.div`
  background: #f2f1f6;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
    
`
