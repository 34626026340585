import {Button, Dialog, Popup} from "antd-mobile";
import {InvitationDesign} from "@firefly/fly-design-inv-data";
import React from "react";
import {useMergedState} from "@firefly/fly-hook";
import {useCreateDesignByCloneMutation, useDesignRemoveMutation} from "../../../hook/design";
import Grid from "../grid";
import {EditSOutline} from "antd-mobile-icons";
import {createNamespace} from "@firefly/fly-kda";
import cls from "classnames";
import {ReactComponent as CopyOutline} from "src/asset/service/copy.svg";
import {ReactComponent as ShareOutline} from "src/asset/service/share.svg";
import {ReactComponent as DeleteOutline} from "src/asset/service/delete.svg";
import SafeAreaPadding from "../../../component/SafeArea/SafeAreaPadding";
import {useNavigateToDesignDesigner} from "../../../navigation/useNavigateToDesignDesigner";
import {useNavigate} from "react-router-dom";
import {Documents} from "@firefly/fly-design-core";

const [bem] = createNamespace("design-action-sheet");
export interface DesignActionSheetProps {
    value?: InvitationDesign | null;
    onClose?: () => void;
}
function DesignActionSheet(props: DesignActionSheetProps): React.ReactElement {
    const {
        value,
        onClose,
    } = props;

    function close() {
        onClose?.();
    }
    const navigate = useNavigate();

    const navigateToDesignDesigner = useNavigateToDesignDesigner();
    const {
        mutateAsync: removeMutate
    } = useDesignRemoveMutation();

    const {
        mutateAsync: createDesignByCloneMutate
    } = useCreateDesignByCloneMutation();
    function removeDesign(design: InvitationDesign) {
        return Dialog.confirm({
            content: `确认是否删除'${design.title}'`,
            onConfirm: async () => {
                return removeMutate({
                    id: design.id
                }).then(() => {
                    return close();
                })
            },
        })
    }
    function clone() {
        return Dialog.confirm({
            content: `确认复制一份新邀请函`,
            onConfirm: async () => {
                return createDesignByCloneMutate({
                    designId: value?.id
                }).then(() => {
                    return close();
                })
            },
        })
    }
    const isPage = Documents.isPageDoctype(value?.doctype);

    return <Popup
        className={cls(bem())}
        visible={!!value}
        onMaskClick={() => {
            close();
        }}
    >
        <div className={cls(bem("header"))}>
            <div className={cls(bem("title"))}>
                {value?.title}
            </div>
        </div>
        <div className={cls(bem("content"))}>
            <Grid columns={4}>
                <Grid.Item
                    icon={
                        <EditSOutline/>
                    }
                    text={"编辑"}
                    onClick={() => {
                        navigateToDesignDesigner(value!).then();
                    }}
                />
                {/*{isPageDesign ? <Grid.Item*/}
                {/*    icon={*/}
                {/*        <AtIcon value={"share"} size={24} customStyle={{*/}
                {/*            padding: 1,*/}
                {/*        }}*/}
                {/*        />*/}
                {/*    }*/}
                {/*    text={"分享"}*/}
                {/*    onClick={() => {*/}
                {/*        return navigationShare(value!).then(() => {*/}
                {/*            close();*/}
                {/*        });*/}
                {/*    }}*/}
                {/*/> : null}*/}

                <Grid.Item
                    icon={
                        <CopyOutline />
                    }
                    text={"复制"}
                    onClick={() => {
                        return clone().then((value) => {
                            if (value) {
                                close();
                            }
                        });
                    }}
                />
                {isPage ? <Grid.Item
                    icon={
                        <ShareOutline />
                    }
                    text={"送呈"}
                    onClick={() => {
                        navigate(`/designs/${value!.id}/sends`);
                    }}
                /> : null}
                <Grid.Item
                    icon={
                        <DeleteOutline/>
                    }
                    text={"删除"}
                    onClick={() => {
                        return removeDesign(value!);
                    }}
                />
            </Grid>
        </div>
        <div className={cls(bem("cancel"))}>
            <Button block onClick={close}>
                取消
            </Button>
        </div>
        <SafeAreaPadding>
            <div />
        </SafeAreaPadding>
    </Popup>
}

export default DesignActionSheet;