import styled from "styled-components";


const InternalActionPanel = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`
const ActionPanelHeader = styled.div`
  margin-left: 20px;
  font-size: 15px;
  font-weight: bold;
`
const ActionPanelBody = styled.div`
  margin-top: 4px;
  font-size: 12PX;
  color: #7b7b7b;
  padding: 0 6px;
  flex: 1;
  
  .rv-badge__wrapper {
    padding: 8px;
  }
//"--rv-grid-item-icon-size": "30px"
  
`
const ActionPanelFooter = styled.div`
    padding: 0 10px 20px;
`

type ActionPanelType = typeof InternalActionPanel & {
    Header: typeof ActionPanelHeader;
    Body: typeof ActionPanelBody;
    Footer: typeof ActionPanelFooter
};

const ActionPanel: ActionPanelType = InternalActionPanel as ActionPanelType;
ActionPanel.Header = ActionPanelHeader;
ActionPanel.Body = ActionPanelBody;
ActionPanel.Footer = ActionPanelFooter;
export default ActionPanel;
