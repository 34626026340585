import React from "react";
import {CellLayout, CellType} from "../interface";


export interface GridColumnType {
  height: number;
  width: number;
  cells: {
    key: React.Key,
    layout: CellLayout
  }[];
}
export default function useGridLayout(
  width: number,
  cells: CellType[],
  columnCount: number): GridColumnType[] {

  // const grid: CellType[][] = [];

  const columns: GridColumnType[] = [];
  const columnWidth = width / columnCount;

  for (let i = 0; i< columnCount; i++) {
    columns.push({
      height: 0,
      width: columnWidth,
      cells: []
    })
  }



  cells.forEach((cell) => {
    const column = columns.reduce((value, column) => {
      if (!value) {
        return column;
      }
      if (value.height > column.height) {
        return column;
      }
      return value;
    }, null as (GridColumnType | null));

    if (column) {
      const layout = {
        width: columnWidth,
        height: (columnWidth / cell.ratio) || 0,
      }
      column.height += layout.height;
      column.cells.push({
        key: cell.key!,
        layout: layout
      })
    }
  })

  return columns;
}
