import React, {useMemo} from "react";
import {
  useAuthenticationPrincipal,
  GraphQLClient,
  GraphQLClientProvider,
  HttpClient,
  HttpClientProvider, useBridge, useSecurityClient
} from "@firefly/fly-security";
import {
  BrowserXhr,
  HttpEvent, HttpHandler,
  HttpRequest,
  HttpXhrBackend,
  interceptingHandler,
} from "@firefly/fly-http";
import {Observable} from "rxjs";
import {SecurityPrincipal} from "./typing";

export interface RequestClientContextProps {
  children: React.ReactNode;
}

function RequestClientContext(props: RequestClientContextProps): React.ReactElement {
  const {
    children
  } = props;
  const securityClient = useSecurityClient();
  const {
    principal
  } = useAuthenticationPrincipal<SecurityPrincipal>();
  const bridge = useBridge();
  const handler = useMemo<HttpHandler>(() => {
    let handler: HttpHandler = new HttpXhrBackend(new BrowserXhr());
    return interceptingHandler(handler, [
      {
        intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
          let headers = req.headers;
          // if (tracking) {
          //   headers.set("P-Tracking", tracking.gid)
          // }
          if (bridge) {
            if (bridge.token) {
              headers = headers.set("X-U-Token", bridge.token)
            }
            if (bridge.snsUserToken) {
              headers = headers.set("X-SNS-U-Token", bridge.snsUserToken);
            }

          } else if (principal) {
            if (principal.token) {
              headers = headers.set("X-U-Token", principal.token)
            }
            if (principal.snsUserToken) {
              headers = headers.set("X-SNS-U-Token", principal.snsUserToken);
            }
          }
          req = req.clone({
            headers: headers,
          })
          return next.handle(req);
        }
      }
    ])
  }, [bridge, principal])
  const httpClient = useMemo(() => {
    return new HttpClient({
      securityClient,
      handler
    });
  }, [securityClient, handler]);
  const graphQLClient = useMemo(() => {
    return new GraphQLClient({
      handler,
      target: `/graphql`,
    });
  }, [handler]);
  return <GraphQLClientProvider client={graphQLClient}>
    <HttpClientProvider client={httpClient}>
      {children}
    </HttpClientProvider>
  </GraphQLClientProvider>
}

export default RequestClientContext;
