import {useGraphQLClient} from "@firefly/fly-security";
import gql from "fake-tag";
import {PageInfo} from "src/typing";
import {ViewerTemplate} from "@firefly/fly-design-inv-data";
import {useInfiniteConnectionQuery} from "@firefly/fly-kda-mobile";

interface PurchasedTemplateEdge {
    cursor: string;
    node: ViewerTemplate;
    purchasedAt: any;
}

interface PurchasedTemplateConnection {
    edges: [PurchasedTemplateEdge];
    pageInfo: PageInfo;
}
export default function usePurchasedTemplateInfiniteConnectionQuery() {

    const graphqlClient = useGraphQLClient();
    return useInfiniteConnectionQuery<PurchasedTemplateEdge>({
        initialPageParam: {

        },
        queryKey: ['viewer', 'purchased-templates'],
        queryFn: (context) => {
            return graphqlClient.query({
                query: gql`
                    query purchasedTemplates($first: Int, $after: String) {
                        viewer {
                            purchasedTemplates(first: $first, after: $after) {
                                edges {
                                    purchasedAt,
                                    node {
                                        id,
                                        title,
                                        type,
                                        cover {
                                            width,
                                            height,
                                            url
                                        },
                                        preview {
                                            width,
                                            height,
                                            url
                                        },
                                        doctype,
                                        createdAt
                                    }
                                },
                                pageInfo {
                                    hasPreviousPage,
                                    hasNextPage,
                                    startCursor,
                                    endCursor
                                }
                            }
                        }
                    }
                `,
                variables: {
                    first: 20,
                    ...context.pageParam,
                }
            }).then(output => {
                return output.viewer.purchasedTemplates;
            })
        },
        select: (connection) => {
            return connection.edges as any
        }
    })
}
