import React from "react";
// import {Space, Button} from "react-vant";
import {
    useNavigateToContactDesigner,
} from "src/suppport/router";
import {useNavigate} from "react-router-dom";
import {Space} from "@firefly/fly-kda";
import {Button} from "antd-mobile";


interface MakeOrderResultExtraProps {
}
function MakeOrderResultExtra(props: MakeOrderResultExtraProps) {
    const {
    } = props;

    const navigate = useNavigate();

    const navigateToContactMake = useNavigateToContactDesigner();
    return <>
        <Space direction={"vertical"} gap={16} block>
            {/*{!isWechat() ?*/}
            <Button
                className={"fly-button-primary"}
                fill={"outline"}
                block color={"primary"} onClick={() => {
                return navigateToContactMake().then();
            }}>
                联系设计师制作
            </Button>
            {/*: null}*/}
            <Button
                className={"fly-button-primary"}
                block
                color={"primary"}
                onClick={() => {
                navigate(`/homepage/templates`)
            }}>
                去挑选模板
            </Button>
        </Space>
    </>
}

export default MakeOrderResultExtra;