import React from "react";
import {HomeO, LabelO, UserO, EnvelopO} from "@react-vant/icons";
import {useMatch, useNavigate} from "react-router-dom";
import {TabBar} from "antd-mobile";
import SafeAreaPadding from "../../component/SafeArea/SafeAreaPadding";

function MainTabBar() {
    const match = useMatch("/homepage/:active/*");
    const active = match?.params.active;
    const navigate = useNavigate();
    return (
        <TabBar
            activeKey={active}
            onChange={(value) => {
                navigate(`${value}`, {

                })
            }}
        >
            <TabBar.Item
                key={`index`}
                icon={<HomeO />}
                title={`首页`}
            />
            <TabBar.Item
                key={`templates`}
                icon={<LabelO />}
                title={`模板`}
            />
            {/*<TabBar.Item*/}
            {/*    key={`design`}*/}
            {/*    icon={<EnvelopO />}*/}
            {/*    title={`邀请函`}*/}
            {/*/>*/}
            <TabBar.Item
                key={`me`}
                icon={<UserO />}
                title={`我的`}
            />
        </TabBar>
    )
}

export default MainTabBar;
