import {useQuery} from "@tanstack/react-query";
import {http, Http} from "@firefly/fly-http";
import {omitUndefined} from "@firefly/fly-defter";


export function useDeliveryView<View = any>(name: string, {
    refetchOnMount = false,
    placeholderData = undefined,
    enabled = true
}: {
    refetchOnMount?: boolean;
    placeholderData?: any;
    enabled?: boolean
} = {
}) {
    return useQuery<View>({
        queryKey: ['delivery', 'view', name],
        queryFn: () => {
            return http.get(`/api/delivery/view`, omitUndefined({
                name: name
            })).then((view) => {
                return view.value??null;
            })
        },
        refetchOnMount: refetchOnMount,
        enabled: enabled,
        placeholderData: placeholderData,
    })
}
