import React, {useMemo} from "react";
import {
    SecurityClient,
    SecurityClientProvider,
    AuthenticationException,
    useBridge, GraphQLClient
} from "@firefly/fly-security";
import {BrowserXhr, HttpXhrBackend} from "@firefly/fly-http";
import {omitUndefined} from "@firefly/fly-defter";
import RequestClientContext from "./RequestClientContext";
import {executeQuery} from "./useViewerQuery";
import {SecurityPrincipal} from "./typing";


export interface SecurityAppClientProviderProps {
    children: React.ReactNode;
}
export function SecurityAppClientProvider(props: SecurityAppClientProviderProps) {
    const {
        children
    } = props;
    const bridge = useBridge();
    const client = useMemo(() => {
        let handler = new HttpXhrBackend(new BrowserXhr());
        const client = new GraphQLClient({
            handler: handler,
            target: "/graphql",
        });
        return new SecurityClient<SecurityPrincipal | null>({
            initialPrincipal: () => {
                const token = bridge?.token??undefined;
                const snsUserToken = bridge?.snsUserToken??undefined;
                return executeQuery(client, {
                    headers: omitUndefined({
                        "X-U-Token": token,
                        "X-SNS-U-Token": snsUserToken
                    })
                }).then((userinfo) => {
                    if (!userinfo) {
                        throw new AuthenticationException();
                    }
                    return {
                        token: token,
                        snsUserToken: snsUserToken,
                        userinfo: userinfo
                    };
                })
            }
        });
    }, [bridge]);

    return <SecurityClientProvider client={client} >
        <RequestClientContext>
            {children}
        </RequestClientContext>
    </SecurityClientProvider>
}
