import React from "react";
import {useNavigate} from "react-router";
import DocumentTitle from "react-document-title";
import {Grid} from "antd-mobile";
import {isAndroid} from "@firefly/fly-defter";
import {useAuthenticationPrincipal} from "@firefly/fly-security";
import {useNavigateToWeappMe} from "src/suppport/router";
import MainUser from "./MainUser";
import { ReactComponent as OrderIcon } from "src/asset/icon/order.svg";
import { ReactComponent as FeedbackOutlined } from "src/asset/service/feedback.svg";
import { ReactComponent as ServiceIcon} from "src/asset/icon/service.svg";
import {ReactComponent as WeappIcon} from "src/asset/icon/weapp.svg";
import {useViewerQuery} from "../../../context/security";
import {useSecurity} from "../../../context/security";
import GridItem from "../../component/grid/GirdItem";
import ViewerDesignList from "../../component/ViewerDesignList";
import "./index.scss";


function Me() {
    const navigate = useNavigate();
    // const {
    //     data: extra
    // } = useViewerExtraQuery();
    //
    //
    //
    // const {
    //     data: designs
    // } = useViewerDesignsFirstQuery(8);

    const {
        authenticate,
    } = useSecurity();
    const {
        isPending,
        isAuthenticated,
    } = useAuthenticationPrincipal()

    // const navigateToDesignDesigner = useNavigateToDesignDesigner();
    // function onItemClick(item: InvitationDesign) {
    //     navigateToDesignDesigner(item, {
    //
    //     }).then();
    // }

    function navigateMeDesigns() {
        navigate("/me/designs")
    }
    function navigateMeStarredTemplate() {
        navigate("/me/starred-templates")
    }
    function navigateMeOrder() {
        navigate("/me/orders")
    }
    function navigateToHelpCenter() {
        navigate("/help-center");
    }
    function navigateToMePurchasedTemplates() {
        navigate("/me/purchased-templates");
    }

    const navigateToWeapppMe = useNavigateToWeappMe();

    function renderBadgeNum(value?: number): string {
        if (typeof value === 'number' && value > 0) {
            return String(value);
        }
        return '';
    }

    function requiredAuthorization(callback: () => void) {
        return () => {
            if (isAuthenticated) {
                callback();
                return;
            }
            authenticate({}).then();
        }

    }

    const {
        viewer
    } = useViewerQuery();
    return <DocumentTitle title={"我的"}>
        <div className={"me-page"}>
            <div className={"me-page-header"}>
                <MainUser value={viewer!} onClick={() => {
                    if (!viewer) {
                        authenticate({

                        }).then()
                    } else {
                        // navigate("/me/profile")
                    }
                }} />
                <Grid columns={4}>
                    <GridItem
                        icon={<ServiceIcon />}
                        text={"帮助"}
                        onClick={(() => {
                            navigateToHelpCenter();
                        })}
                    />
                    <GridItem
                        icon={<OrderIcon />}
                        text={"我的已购"}
                        onClick={requiredAuthorization(() => {
                            navigateToMePurchasedTemplates();
                        })}
                    />
                    {/*<Grid.Item*/}
                    {/*    icon={<SettingIcon />}*/}
                    {/*    text={"更多"}*/}
                    {/*    onClick={() => {*/}
                    {/*        navigateUserSetting();*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {isAndroid() ? <GridItem
                        icon={<WeappIcon />}
                        text={"微信小程序"}
                        onClick={() => {
                            navigateToWeapppMe();
                        }}
                    />: null}
                    {/*<Cell icon={<WechatOfficaccountO width={16} />} title="微信小程序" isLink onClick={() => navigateToWeapppMe()}  />*/}
                    {/*: null}*/}
                    <GridItem
                        icon={<FeedbackOutlined />}
                        text={"投诉与反馈"}
                        onClick={requiredAuthorization(() => {
                            window.location.href = `https://www.i-firefly.com/cs/feedback?return=${encodeURIComponent("browser:back")}`;
                        })}
                    />
                </Grid>
            </div>
            <div className={"me-page-body"}>
                <ViewerDesignList/>
            </div>
        </div>
    </DocumentTitle>
}

export default Me;
