import React, {CSSProperties} from "react";
import classNames from "classnames";
import cls from "classnames";
import {Picture} from "@firefly/fly-design-core";
import {createNamespace} from "@firefly/fly-kda";
import {Image} from "antd-mobile";
import "./style/index.scss";

const [bem] = createNamespace("design-card");

export interface ActionItemType {
    icon: React.ReactNode;
    text: React.ReactNode;
    onClick?: () => void;
}
interface DesignCardProps {
    className?: string;
    style?: CSSProperties;
    // value: InvitationDesign;
    title: string;
    cover: Picture;
    tags: string[];
    time: string;
    extra?: React.ReactNode;
    actions: ActionItemType[];
}
function DesignCard(props: DesignCardProps) {
    const {
        className,
        style,
        title,
        cover,
        tags,
        time,
        extra,
        actions,
    } = props;
    // const {
    //   title,
    //   doctype,
    //   cover,
    //   dimension,
    //   createdAt
    // } = value;

    const action: React.ReactElement[] = [];
    actions.forEach((item, index) => {
        const {
            icon,
            text,
            onClick,
        } = item;
        if (action.length > 0) {
            action.push(<div className={cls(bem("action-divider"))} />);
        }
        action.push(<div key={index} className={cls(bem("action-item"))} onClick={onClick}>
            <div className={cls(bem("action-item-icon"))}>
                {icon}
            </div>
            <div className={cls(bem("action-item-text"))}>
                {text}
            </div>
        </div>);
    })
    return <div className={classNames(bem(), className)} style={style}>
        <div className={cls(bem("content"))}>
            <div className={cls(bem("meta"))}>
                <div className={cls(bem("meta-avatar"))}>
                    <Image
                        className={cls(bem("meta-image"))}
                        // className={"image"}
                        // mode={"aspectFill"}
                        // webp
                        src={cover? `${cover.url}?x-oss-process=image/resize,w_360/crop,h_720/format,jpg/quality,q_60` : "/asset/placeholder.png"}
                    />
                </div>
                <div className={cls(bem("meta-detail"))}>
                    <div className={cls(bem("meta-title"))}>
                        <span className={cls(bem("meta-title-text"))}>
                            {title}
                        </span>
                    </div>
                    <div className={cls(bem("meta-tags"))}>
                        {tags.map((tag, i) => (
                            <div key={i} className={cls(bem("tag"))}>
                                {tag}
                            </div>
                        ))}
                    </div>
                    <div className={cls(bem("meta-time"))}>
                        {time}
                    </div>
                    {extra}
                </div>
            </div>
        </div>
        <div className={cls(bem("actions"))}>
            {action.map((item, i) => {
                return React.cloneElement(item, {
                    key: i
                })
            })}
        </div>

    </div>
}
export default DesignCard;

