import React, {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import TemplateList from "../../component/TemplateList";
import NavBar from "src/page/component/NavBar";
import {SearchBar} from "antd-mobile";
import "./index.scss";


function SearchResultPage() {

    // const [searchParams] = useSearchParams()

    const params = useParams();
    const keyword = useMemo<string>(() => {
        return params.keyword??""
    }, [params]);
    const navigate = useNavigate();

    // useEffect(() => {
    //     Dialog.confirm({
    //         message: "没有您所需找的模板，是否需要更多模板"
    //     }).then()
    // }, []);
    return <>
        <NavBar
            style={{
                "--padding-left": "52px"
            }}
        >
            <SearchBar
                className={"fly-search-bar"}
                style={{
                    padding: 0
                }}
                placeholder={"搜索您喜欢的"}
                value={keyword}
                onFocus={() => {
                    navigate({
                        pathname: '/search',
                        search: `keyword=${encodeURIComponent(keyword)}`
                    }, {
                        replace: true
                    })
                }}
                // onClickInput={() => {

                // }}
            />
        </NavBar>
        <TemplateList keyword={keyword} />
    </>
}

export default SearchResultPage;
