import styled from "styled-components";




export const OrderListLayout = styled.div`
  background: #f6f7f9;
  height: 100%;
  overflow-y: auto;
  
  .rv-list {
    padding-bottom: 12px;
  }
`
export const OrderItem = styled.div`
  padding: 12px 12px 0;
  //&:last-child {
  //  padding-bottom: 12px;
  //}
`

export const OrderCardMetaCover = styled.div`
  width: 50px;
  height: 50px;
  background: #f5f5f5;
  flex-shrink: 0;
  .rv-image {
    width: 100%;
    height: 100%;
  }
`;


export const OrderCardLayout = styled.div`
`
export const OrderCardMetaNo = styled.div`
  font-weight: normal;
  font-size: 15px;
`
export const OrderCardMetaState = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  font-weight: normal;
  color: #2b70a9;
  font-size: 14px;
`
export const OrderCardMetaTime = styled.div`
  margin-top: 10px;
  font-weight: normal;
  color: #999;
  font-size: 13px;
`


export const OrderCardLineItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
  
`
export const OrderCardMetaContent = styled.div`
  margin-left: 8px;
`;
export const OrderCardMetaTitle = styled.div`
  font-size: 14px;
  line-height: 1.2;
`;

export const OrderCardMetaAmount = styled.div`
  margin-top: 14px;
  //color: #f9a473;
  color: #000;
  font-size: 14px;
`;

// export const OrderCardMetaOriginalAmount = styled.div`
// `;
