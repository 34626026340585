import {SizeType} from "src/typing";
import {Picture} from "@firefly/fly-design-core";



export function cover2share(url: string): string {
    return `${url}?x-oss-process=image/resize,w_120/crop,h_120/format,jpg/quality,q_80`;
}

export function preview2share(preview?: Picture, size = 120): string {
    return preview ?
        `${preview.url}?x-oss-process=image/resize,w_120/crop,h_120/format,jpg/quality,q_80`
        : ""
        ;
}


export function cover2order(url: string): string {
    return `${url}?x-oss-process=image/resize,w_360/crop,h_720/format,jpg/quality,q_60`;
}


// export function cover2view(cover: Picture) {
//
// }


export function template2item(cover: Picture | undefined, size: SizeType = 'small') {
    if (!cover) {
        return '/asset/placeholder.png'
    }
    const process: Record<SizeType, string> = {
        'small': 'image/resize,w_150/crop,h_300/format,jpg/quality,q_80',
        'middle': 'image/resize,w_300/crop,h_600/format,jpg/quality,q_80',
        'large': 'image/resize,w_300/crop,h_600/format,jpg/quality,q_80',
    }
    // if (size === 'middle' \ '') {
    //     return `${cover.url}?x-oss-process=`;
    // }
    return `${cover.url}?x-oss-process=${process[size]}`;
}
/**
 * 将预览图 转为封面
 * 如用于入微信小程序的分享
 * @param preview
 * @param options
 */
export function preview2cover(preview: Picture, options: {
    width: number;
    height: number;
} = {
    width: 500,
    height: 500,
}): Picture {
    const {
        width,
        height
    } = options;
    return {
        width: width,
        height: height,
        url: `${preview.url}?x-oss-process=image/resize,w_${width}/crop,h_${height}/format,jpg/quality,q_90`,
    }
}
