import React, {CSSProperties, useEffect} from "react";
import cls from "classnames";
import {createNamespace} from "@firefly/fly-kda";
import Tag from "../Tag";
import {useFirstMountState, useMergedState, useRefs} from "@firefly/fly-hook";

export type Key = string | null;
export interface TabType {
    key: Key;
    label: string;
}
export interface TabSelectorProps {
    className?: string;
    style?: CSSProperties;
    tabs: TabType[];
    active?: Key | null;
    onActiveChange?: (value: Key | null) => void;
    extra?: React.ReactNode;
}

const [bem] = createNamespace("tab-selector");
function TabSelector(props: TabSelectorProps): React.ReactElement {
    const {
        className,
        style,
        tabs,
        active: _active,
        onActiveChange,
        extra
    } = props;
    const [activeKey, setActiveKey] = useMergedState<Key | null>(null, {
        value: _active,
        onChange: onActiveChange,
    })
    const [getRef, removeRef]= useRefs<HTMLDivElement>();
    const firstMount = useFirstMountState();
    // const
    useEffect(() => {
        if (activeKey) {
            const element = getRef(activeKey).current;
            element?.scrollIntoView({
                block: 'nearest',
                inline: "center",
                behavior: firstMount ? "auto" : "smooth",
            });
        }
    }, [firstMount, activeKey, getRef]);
    return <div className={cls(className, bem())} style={style}>
        <div className={cls(bem("content"))}>
            {tabs.map((option, i) => (
                <Tag key={option.key}
                     ref={getRef(String(option.key))}
                     checked={activeKey === option.key}
                     onClick={(active, event) => {
                         setActiveKey(option.key);
                         // const element = getRef(option.key!).current;
                         // console.log(`element:`, element)
                         // element?.scrollIntoView({
                         //     block: 'nearest',
                         //     inline: "center",
                         //     behavior: "smooth",
                         // });
                     }}
                >
                    {option.label}
                </Tag>
            ))}
        </div>
        {extra ? <div className={cls(bem("extra"))}>
            {extra}
        </div> : null}
    </div>
}

export default TabSelector;