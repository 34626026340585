import {PaymentConfirmDialog2} from "../../styles";
import React from "react";


export interface PaymentConfirmDialogProps {
    visible?: boolean;
    onCancel: () => void;
    onOk: () => void;
}
function PaymentConfirmDialog(props: PaymentConfirmDialogProps): React.ReactElement {
    const {
        visible,
        onCancel,
        onOk,
    } = props;
    return <PaymentConfirmDialog2
        content={"请确认支付是有已完成"}
        visible={visible}
        style={{
            "--min-width": "320px",
            "--max-width": "90vw",
        } as any}
        actions={[
            [
                {
                    key: "cancel",
                    text: "遇到问题，重新支付",
                    onClick: onCancel,
                },
                {
                    key: "ok",
                    text: "已完成支付",
                    style: {
                        color: "rgb(255, 85, 85)",
                    },
                    onClick: onOk
                }
            ]
        ]}
        // ok={"已完成支付"}
        // confirmButtonColor={"#f55"}
        // cancelButtonText={"遇到问题，重新支付"}
        // cancelButtonColor={"#777"}
    />
}

export default PaymentConfirmDialog;