import React from "react";
import DocumentTitle from "react-document-title";
import {Flex} from "@firefly/fly-kda";
import {isMiniapp} from "@firefly/fly-defter";
import NavBar from "src/page/component/NavBar";
import SendDetail from "./SendDetail";


export default function() {
    return  <DocumentTitle title={"送呈"}>
        <Flex style={{height: "100%"}} direction={"column"}>
            {
                isMiniapp() ?
                    <></>
                    :
                    <NavBar>
                        送呈
                    </NavBar>
            }
            <Flex.Item flex={1}>
                <SendDetail />
            </Flex.Item>
        </Flex>
    </DocumentTitle>

}