import React, {useMemo} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import DocumentTitle from "react-document-title";
import {Button} from "antd-mobile";
import {usePageQuery} from "src/hook/page";
import {isWechat} from "@firefly/fly-defter";
import {Share} from "@firefly/fly-bridge";
import {Loading} from "@firefly/fly-kda";
import {Invitations} from "@firefly/fly-design-inv-core";
import WechatShareGridItem from "src/page/component/grid/WechatShareGridItem";
import CopyLinkGridItem from "src/page/component/grid/CopyLinkGridItem";
import Inspect from "src/page/component/Inspect";
import {PhotoO} from "@react-vant/icons"
import WeappGridItem from "src/page/component/grid/WeappGridItem";
import ActionPanel from "src/page/component/ActionPanel";
import {PageContainer, PageContainerWrapper} from "./styles";
import {ReactComponent as ShareIcon} from "src/asset/share2.svg";
import Grid from "src/page/component/grid";
import {ReactComponent as TimelineOutline} from "src/asset/service/timeline.svg";
import {ReactComponent as WechatOutline} from "src/asset/service/wechat.svg";
import Indicator from "src/component/Indicator";


function PageInspect() {
    const params = useParams();
    const id = params.id!;
    const [searchParams] = useSearchParams();
    const sendId = useMemo<string | null>(() => {
        return searchParams.get("send");
    }, [searchParams])
    const {
        data: page,
    } = usePageQuery(id!, {
        suspense: false
    })
    const link = Invitations.link(id, {
        send: sendId
    });
    const wrappedLink = Invitations.link(id!, {
        send: sendId,
        wrapped: true,
    })
    const navigate = useNavigate();

    return <DocumentTitle title={page?.title??""}>
        <Share
            title={page?.title}
            description={page?.description}
            coverUrl={page ? Invitations.shareCover(page.preview, page.coverBox)! : undefined}
            link={link}
        >
            <Inspect>
                <Inspect.Content>
                    <PageContainerWrapper>
                        <PageContainer>
                            <iframe
                                title={page?.title}
                                width={"100%"}
                                height={"100%"}
                                src={wrappedLink}
                            />
                        </PageContainer>
                    </PageContainerWrapper>
                </Inspect.Content>
                <Inspect.Action fixed>
                    <ActionPanel>
                        <ActionPanel.Header>
                            继续操作/分享
                        </ActionPanel.Header>
                        <ActionPanel.Body>
                            <Grid
                                columns={4}
                                //border={false}
                                //   columnNum={5} iconSize={30}
                            >
                                {/*微信分享*/}
                                {isWechat() && <WechatShareGridItem
                                    icon={
                                        <WechatOutline />
                                    }
                                    text={"转发好友"}
                                />}
                                {/*微信分享*/}
                                {isWechat() && <WechatShareGridItem
                                    icon={
                                        <TimelineOutline />
                                    }
                                    text={"发朋友圈"}
                                />}
                                {/*微信海报分享*/}
                                {!isWechat() ? <Grid.Item
                                    icon={<ShareIcon />}
                                    text={"微信分享"}
                                    onClick={() => {
                                        navigate(`/invitation/pages/${id}/share-poster`)
                                    }}
                                /> : <Grid.Item
                                    icon={
                                        <PhotoO style={{color: "#000"}} />
                                    }
                                    text={"生成海报"}
                                    onClick={() => {
                                        navigate(`/invitation/pages/${id}/share-poster`)
                                    }}
                                />}
                                <CopyLinkGridItem
                                    link={link}
                                />
                                {!isWechat() && <WeappGridItem
                                    jump={{
                                        path: `page/share/invitation`,
                                        query: `id=${id}`,
                                    }}
                                />}
                            </Grid>
                        </ActionPanel.Body>
                        <ActionPanel.Footer>
                            <Button
                                className={"fly-button-primary"}
                                block color={"primary"}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                返回
                            </Button>
                            {/*<Button*/}
                            {/*    className={"fly-button-primary"}*/}
                            {/*    block color={"primary"}*/}
                            {/*    onClick={navigateToHome}*/}
                            {/*>*/}
                            {/*    回到主页*/}
                            {/*</Button>*/}
                        </ActionPanel.Footer>
                    </ActionPanel>
                </Inspect.Action>
            </Inspect>
        </Share>
    </DocumentTitle>
}



export default Indicator.withSuspense(PageInspect);
