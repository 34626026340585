import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 90px 32px 32px;
  background: #fff;
`
export const LayoutIcon = styled.div`
  text-align: center;
  margin-bottom: 24px;
`
export const LayoutTitle = styled.div`
  color: #000000d9;
  font-size: 20px;
  line-height: 1.8;
  text-align: center;
  font-weight: bold;
`
export const LayoutSubtitle = styled.div`
  color: #00000073;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
`
export const LayoutExtra = styled.div`
  margin: 24px 0 0;
  text-align: center;
`
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 15px 30px;
  background: #fff;
`
export const Footer = styled.div`
  width: 100%;
  padding: 20px;
`
