import React, {useMemo, useState} from "react";
import {ProjectVariant} from "@firefly/fly-design-inv-data";
import {
    MakeQuestionContent,
    PaymentProject,
    PaymentProjectHeader,
    PaymentProjectQuestionO,
    PaymentProjectSelectedAmount,
    PaymentProjectSelectedAmountValue,
    PaymentProjectTitle,
    PaymentProjectVariants,
} from "./styles";
import {Tag, Modal, Selector} from "antd-mobile";
import "./style.scss";
import {Flex, Loading} from "@firefly/fly-kda";


interface DesignerMakeFormProps {
    selectedVariantIdentifier?: string;
    isLoading?: boolean;
    subtitle?: React.ReactNode;
    variants?: ProjectVariant[];
    children?: React.ReactNode;
    onSelect?: (key: string | null, variant: ProjectVariant | null) => void;
}

function DesignerMakeForm(props: DesignerMakeFormProps) {
    const {
        subtitle,
        selectedVariantIdentifier,
        isLoading = false,
        variants = [],
        children,
        onSelect,
    } = props;
    const [makeHelpDialogVisible, setMakeHelpDialogVisible] = useState<boolean>(false);

    function select(variant: ProjectVariant | null) {
        onSelect?.(variant? variant.identifier : null, variant);
    }
    const options = useMemo(() => {
        return variants.map((variant) => {
            return {
                label: variant.title,
                value: variant.identifier,
                description: variant.description
            }
        })
    }, [variants]);
    const selectedVariant = useMemo(() => {
        return variants.find((variant) => {
            return variant.identifier === selectedVariantIdentifier
        })
    }, [variants, selectedVariantIdentifier])
    return <>
        <PaymentProject>
            <Loading loading={isLoading}>
                <Flex direction={"column"}>
                    <PaymentProjectHeader>
                        <PaymentProjectTitle onClick={() => {
                            setMakeHelpDialogVisible(true)
                        }}>
                            {subtitle ? <Tag color={"primary"} fill={"outline"} style={{marginRight: 4}}> {subtitle}</Tag> : null }
                            设计师代制作服务
                            <PaymentProjectQuestionO />
                        </PaymentProjectTitle>
                        {selectedVariant ?
                            <PaymentProjectSelectedAmount>
                                ￥
                                <PaymentProjectSelectedAmountValue>
                                    {selectedVariant.pricing.amount}
                                </PaymentProjectSelectedAmountValue>
                            </PaymentProjectSelectedAmount>
                            : null}
                    </PaymentProjectHeader>
                    <PaymentProjectVariants>
                        <Selector
                            options={options}
                            value={selectedVariant?.identifier ? [selectedVariant.identifier] : []}
                            onChange={(items) => {
                                const item = items[0];
                                select(variants.find(variant => {
                                    return variant.identifier === item
                                })??null);
                            }}
                        />
                    </PaymentProjectVariants>
                </Flex>
            </Loading>
            {/*{isLoading ? <Loading /> : }*/}
            {children}
        </PaymentProject>
        <Modal
            className={"designer-mark-explain"}
            visible={makeHelpDialogVisible}
            title="什么是设计师代做服务"
            // showCancelButton
            style={{
                width: "86vw"
            }}
            closeOnAction
            onClose={() => {
                setMakeHelpDialogVisible(false);
            }}
            content={(
                <MakeQuestionContent>
                    <ul>
                        <li>
                            1、选择「设计师代做」：确定模板支付成功后，您只需要提供宴请信息和相关图片给设计师，即由设计师帮您制作完成精美的邀请函，一个工作日内完成制作。
                        </li>
                        <li>
                            2、未选择「设计师代做」：确定模板支付成功后，可以自行在页面编辑宴请信息和更替图片，制作精美的邀请函。
                        </li>
                        <li>
                            3、代做效果：很多客户都表示物超所值，只需一杯奶茶的费用，就能得到设计师量身定制的精美邀请函(其中包含模板+定制费用)，制作效果又快又好。
                        </li>
                        <li>
                            4、收费透明：无隐藏费用，模板一次购买，终身使用，永不过期，放心购买。
                        </li>
                        <li>
                            5、如需帮助：请在[我的], [帮助], [联系客服]，回复"人工服务"。
                        </li>

                    </ul>
                </MakeQuestionContent>
            )}
            actions={[
                {
                    key: 'confirm',
                    text: '我知道了',
                },
            ]}
        />

            {/*<img className="demo-dialog-img" src="https://img.yzcdn.cn/vant/apple-3.jpg" alt="2131" />*/}
        {/*</Modal>*/}
    </>
}

export default DesignerMakeForm;
