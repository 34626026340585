import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {Suspense, useEffect, useMemo, useState} from "react";
import NavBar from "src/page/component/NavBar";
import {PaymentMethod} from "@firefly/fly-payment";
import {PaymentExplain} from "./styles";
import {useQueryClient} from "@tanstack/react-query";
import {error as reportError} from "src/util/reporter";
import useTemplatePayment from "./useTemplatePayment";
import SafeAreaPadding from "src/component/SafeArea/SafeAreaPadding";
import {HomeO} from "@react-vant/icons";
import {useViewReport} from "src/hook/view";
import {
    Body,
    Footer, FooterContent,
    OrderItemCard,
    Page,
    PaymentButtonAmount,
} from "src/page/payment/styles";
import PaymentMethodSelect from "src/page/payment/component/PaymentMethodSelect";
import PaymentLoadingOverlay from "src/page/payment/component/PaymentCheckLoadingOverlay";
import TemplateEmpty from "../component/TemplateEmpty";
import useConfigPaymentMethods from "../../../hook/useConfigPaymentMethods";
import {Order, ProjectVariant, TemplateLineItemAttributes} from "@firefly/fly-design-inv-data";
import {Button} from "antd-mobile";
import {Flex, Loading} from "@firefly/fly-kda";
import TemplateOrderItem from "./TemplateOrderItem";
import {isMobile, isPC} from "@firefly/fly-defter";
import {useNavigateToOrderComplete} from "../../../suppport/router";
import Indicator from "src/component/Indicator";
import PaymentConfirmDialog from "../../payment/component/PaymentConfirmDialog";
const QRCodePayModal = React.lazy(() => import("src/component/QRCodePayModal"))

interface TemplatePaymentProps {

}

function TemplatePayment(_: TemplatePaymentProps) {

    const {
        templateId,
    } = useParams<any>();
    const [searchParams] = useSearchParams()

    const templateLineItemAttributes = useMemo<TemplateLineItemAttributes>(() => {
        const extraValue = searchParams.get("extra");
        let extra = null;
        try {
            extra = extraValue ? JSON.parse(decodeURIComponent(extraValue!)) : null
        } catch (e) {
            console.warn("extra parse failure", extraValue, e)
        }
        return {
            designId: extra?.designId,
            from: searchParams.get('from')??undefined,
        }
    }, [searchParams])

    const paymentMethods = useConfigPaymentMethods();

    const [paymentMethod, _setPaymentMethod] = useState<PaymentMethod>(() => {
        return paymentMethods[0]
    });
    const [paying, setPaying] = useState<boolean>(false);
    const navigateToOrderComplete = useNavigateToOrderComplete();
    const {
        isTemplateLoading,
        template,
        variants,
        selectedVariant,
        setSelectedVariant: _setSelectedVariant,
        execute,
        amount,
        checkEnabled,
        isCheckFetching,
        check,
        isPending,
        setPending,
        isCompleted,
        createOrder
    } = useTemplatePayment(templateId!, {
        attributes: templateLineItemAttributes,
    });

    function setSelectedVariant(v: ProjectVariant | null) {
        if (paying) {
            return;
        }
        _setSelectedVariant(v);
    }
    function setPaymentMethod(v: PaymentMethod) {
        if (paying) {
            return;
        }
        _setPaymentMethod(v);
    }


    // const templateId = order?.metadata?.templateId;

    const navigate = useNavigate();


    const [extra, setExtra] = useState<Record<string, string>>({});
    const [qrcodeOrder, setQRCodeOrder] = useState<Order | null>(null);
    const [qrcodeMounted, setQRCodeMounted] = useState<boolean>(false);
    useEffect(() => {
        if (qrcodeOrder) {
            setQRCodeMounted(true)
        }
    }, [qrcodeOrder, setQRCodeMounted]);
    async function executePayment() {
        setPaying(true);
        if (isPC()) {
            const order = await createOrder();
            setQRCodeOrder(order);
            //弹出扫码对话框
        } else {
            await execute(paymentMethod, {
                ...extra,
            }).catch((reason) => {
                if (reason === 'cancel') {
                    return;
                }
                reportError('template-payment failure:' + reason);
            })
        }

        setPaying(false)
    }


    useViewReport(() => {
        return {
            label: 'TemplatePayment',
            extra: {
                templateId: templateId!
            }
        }
    }, [templateId]);

    function renderContent() {
        if (isTemplateLoading) {
            return <Flex align={"center"} justify={"center"} style={{height: "100%"}}>
                <Loading  />
            </Flex>
        }
        if (!template) {
            return <TemplateEmpty />
        }

        if (!isCompleted) {
            return <>
                <Body>
                    <TemplateOrderItem
                        template={template}
                        variants={variants}
                        selectedVariant={selectedVariant}
                        setSelectedVariant={setSelectedVariant}
                        extra={extra}
                        setExtra={setExtra}
                    />
                    {!isPC() ? <OrderItemCard padding={false}>
                        <PaymentMethodSelect
                            selectedValue={paymentMethod}
                            onSelect={(method) => {
                                setPaymentMethod(method)
                            }}
                            methods={paymentMethods}
                        />
                    </OrderItemCard> : null}
                    {/*帮助引导*/}
                    {/*<HelpGuide>*/}
                    {/*    如需帮助，请联系客服：<Link to={`/help-center`}>点击咨询</Link>*/}
                    {/*</HelpGuide>*/}
                    {/*<Button block plain type={"primary"} onClick={() => {*/}
                    {/*    if (template) {*/}
                    {/*        navigateToWeappTemplate(template!).then()*/}
                    {/*    } else {*/}
                    {/*        navigateToWeappMe().then();*/}
                    {/*    }*/}
                    {/*}}>*/}
                    {/*    到微信内制作*/}
                    {/*</Button>*/}
                </Body>
                <Footer>
                    <FooterContent>
                        {/*<PVipButton*/}
                        {/*    block*/}
                        {/*    onClick={() => {*/}
                        {/*        navigate(`/pvip`)*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    开通会员免费使用*/}
                        {/*</PVipButton>*/}
                        <Button
                            className={"fly-button-primary"}
                            loading={"auto"}
                            color={"primary"}
                            onClick={executePayment}
                            disabled={isTemplateLoading || paying}
                            block
                        >
                            确认支付 <PaymentButtonAmount>￥{amount}</PaymentButtonAmount>
                        </Button>
                    </FooterContent>
                </Footer>
            </>
        }

    }
    return <SafeAreaPadding>
        <Page>
            <NavBar
                title={"模板付费"}
                onBack={() => {
                    navigate(-1)
                }}
                right={isCompleted ? <>
                    <HomeO fontSize={22} />
                </> : null}
                onRightClick={() => {
                    navigate("/")
                }}
            />

            {qrcodeMounted ? (
                <Suspense fallback={<Loading fullscreen />}>
                    <QRCodePayModal
                        value={qrcodeOrder}
                        onClose={() => {
                            setQRCodeOrder(null)
                        }}
                        onFinish={() => {
                            navigateToOrderComplete({
                                id: qrcodeOrder!.id
                            }).then();
                            setQRCodeOrder(null)
                        }}
                    />
                </Suspense>
            ) : null}
            {renderContent()}
            <PaymentLoadingOverlay
                visible={!!checkEnabled && !isPending && isCheckFetching}
            />
            <PaymentConfirmDialog
                // message={"请确认支付是有已完成"}
                // confirmButtonText={"已完成支付"}
                // confirmButtonColor={"#f55"}
                // cancelButtonText={"遇到问题，重新支付"}
                // cancelButtonColor={"#777"}
                // showCancelButton
                visible={checkEnabled && isPending}
                onCancel={() => {
                    setPending(false);
                }}
                onOk={() => {
                    check().then()
                    setPending(false)
                }}
            />

        </Page>
    </SafeAreaPadding>
}

export default TemplatePayment;
