import copy from "copy-to-clipboard";
import {ReactComponent as LinkIcon} from "src/asset/link.svg";
import React from "react";
import {Toast} from "antd-mobile";
import GridItem from "./GirdItem";

interface CopyLinkGridItemProps {
    link?: string;
}
function CopyLinkGridItem(props: CopyLinkGridItemProps) {
    const {
        link,
    } = props;
    function onCopyLink() {
        if (!link) {
            return;
        }
        copy(link)
        Toast.show({
            content: "复制成功"
        })
    }
    return <GridItem
        icon={<LinkIcon />}
        text={"复制链接"}
        onClick={onCopyLink}
    />
}


export default CopyLinkGridItem;
