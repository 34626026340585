import styled from "styled-components";


export const PaymentAmount = styled.span`
`


export const PaymentExplain = styled.div`
  //padding: 0 10px;
  margin-top: 12px;
  line-height: 1.4;
  font-size: 14px;
  color: #6e6e6e;
`


export const PaymentResult = styled.div`
  display: flex;
  flex-direction: column;
`


export const HelpGuide = styled.p`
  font-size: 14px;
  line-height: 1.4;
  color: #9d9d9d;
  padding: 0 10px;
`
// export const HelpLink = styled

