import React from "react";
import cls from "classnames";
import {Button} from "antd-mobile";
import {createNamespace, Result} from "@firefly/fly-kda";
import "./index.scss";
import {useSecurity} from "../../context/security";

interface SecurityFallbackProps {
    description: React.ReactNode;
    // onAuthenticate: () => Promise<void> | void;
}
const [bem] = createNamespace("security-fallback");
function SecurityFallback(props: SecurityFallbackProps): React.ReactElement {
    const {
        description,
        // onAuthenticate,
    } = props;
    const {
        authenticate
    } = useSecurity();
    return <div className={cls(bem())}>
        <Result
            description={description}
            extra={
                <Button
                    className={"fly-button-primary"}
                    color={"primary"}
                    style={{width: 200, borderRadius: 22}}
                    onClick={() => {
                        authenticate({}).then()
                    }}
                >
                    立即登录
                </Button>
            }
        />
    </div>
}

export default SecurityFallback;
