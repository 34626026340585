import YSF from "@neysf/qiyu-web-sdk";

declare let ysf: any;


export function openClientService(target: string) {
    YSF.init(target, {

    }).then(() => {
        window.location.href = ysf('url')
    })
    // ysf('onready', function() {
    //
    // });
}