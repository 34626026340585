import {useContext} from "react";
import TrackingContext, { Tracking } from "./TrackingContext";


function useTracking(): Tracking | null {
  const {
    tracking
  } = useContext(TrackingContext);
  return tracking;
}

export default useTracking;
