import styled from "styled-components";
import {Loading as KDALoading} from "@firefly/fly-kda";

export const PaymentLoadingLayout = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`
// export const PaymentLoading = styled(Loading).attrs({
//     // color: BRAND_COLOR,
//     type: "spinner",
//     size: 40,
//     vertical: true,
// })`
//   flex-direction: column;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 120px;
//   height: 120px;
//   background: #fff;
//   border-radius: 4px;
//   .rv-loading__text {
//     margin-top: 20px;
//   }
// `
export const PaymentLoading = styled(KDALoading)`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background: #fff;
  border-radius: 4px;
  .fly-loading__tip {
      margin-top: 10px;
  }
`