import {useEffect, useState} from "react";
import {useViewerTemplateQuery} from "src/hook/template";
import {PaymentMethod, usePayment} from "@firefly/fly-payment";
import {useCreateOrderOfTemplateProjectMutation} from "src/hook/order";
import {useNavigateToOrderComplete} from "../../../suppport/router";
import {useProjectVariantsQuery} from "../../../hook/project-variant";
import {OrderExtra, ProjectVariant} from "@firefly/fly-design-inv-data";
import {useSecurity} from "../../../context/security";


export default function useTemplateMakePayment(templateId: string) {


    const {
        isPending,
        setPending,
        execute,
        isCheckFetching,
        check,
        state,
        pendingPayment,
        isCompleted,
    } = usePayment(['template', templateId, 'make']);

    const {
        requestAuthenticated,
    } = useSecurity();
    const {
        isFetching: isTemplateLoading,
        data: template
    } = useViewerTemplateQuery(templateId!, {
        enabled: !!templateId,
    });

    const {
        isFetching: variantsLoading,
        data: variants = []
    } = useProjectVariantsQuery("TEMPLATE_MAKE", ["in24h", "in3h", "in1h"]);

    const [selectedVariant, setSelectedVariant] = useState<ProjectVariant | null>(null)

    useEffect(() => {
        if (variants.length > 0) {
            setSelectedVariant(variants[0])
        }
    }, [variants])
    const amount = selectedVariant?.pricing.amount;

    const {
        mutateAsync: createOrderOfTemplateProject
    } = useCreateOrderOfTemplateProjectMutation();
    const navigateToOrderComplete = useNavigateToOrderComplete();

    function executeMakePayment(method: PaymentMethod, extra: OrderExtra = {}) {
        const selectedProjectIdentifier = {
            project: selectedVariant!.project,
            identifier: selectedVariant!.identifier,
        };
        return requestAuthenticated().then(() => {
            return createOrderOfTemplateProject({
                templateId: templateId,
                lineItems: [selectedProjectIdentifier],
                extra: extra,
            }).then((order) => {
                // const location = window.location;
                // const url = new URL(`${location.protocol}//${location.host}/order-payment`);
                // url.searchParams.set("orderId", order.id);
                // url.searchParams.set("redirect", "homepage");
                return execute({
                    id: order.paymentOrderId
                }, method, {}).then((status) => {
                    if (status === 'completed') {
                        navigateToOrderComplete({
                            id: order.id
                        }).then()
                        return;
                    }
                    if (status === 'pending') {
                        return
                    }
                });
            })
        })
    }

    useEffect(() => {
        if (pendingPayment && isCompleted) {
            navigateToOrderComplete({
                id: pendingPayment?.outId,
            }).then()
        }
    }, [templateId, pendingPayment, isCompleted, navigateToOrderComplete])
    return {
        isPending,
        setPending,
        templateLoading: isTemplateLoading,
        template: template,
        variantsLoading: variantsLoading,
        variants: variants,
        selectedVariant,
        setSelectedVariant,
        amount,
        execute: executeMakePayment,
        isCheckFetching,
        check,
        isCompleted: isCompleted,
    }
}
