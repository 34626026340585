import {
    useCreateSendByMutation,
} from "../../../hook/send";
import {useNavigate, useParams} from "react-router-dom";
import NavBar from "../../component/NavBar";
import React from "react";
import SendForm from "../../component/SendForm";
import {Flex} from "@firefly/fly-kda";


function CreateSend() {
    const {designId} = useParams();

    const {
        mutateAsync: create
    } = useCreateSendByMutation();

    const navigate = useNavigate()

    return <Flex style={{height: "100%"}} direction={"column"}>
        <NavBar>
            送呈
        </NavBar>
        <Flex.Item flex={1}>
            <SendForm
                initialValues={{
                    explain: "恭请您来与我们共同举杯庆祝"
                }}
                submit={`确认添加`}
                onFinish={(values) => {
                        return create({
                            designId: designId!,
                            toName: values.toName!,
                            explain: values.explain!,
                            envelopeId: "6579ba0fc4c081440699622a",
                        }).then(() => {
                            navigate(-1);
                        })
                    }
                }
            />
        </Flex.Item>
    </Flex>
}
export default CreateSend;