// import {NavBar} from "react-vant";
import {useNavigate} from "react-router-dom";
import Footer from "../../component/Footer";
import {Article, Page, PageBody} from "./styles";
import NavBar from "src/page/component/NavBar";
import {useDeliveryView} from "../../../hook/useDeliveryView";
import {Flex, Loading} from "@firefly/fly-kda";


function ContactUs() {

    const {
        isLoading,
        data: value
    } = useDeliveryView("web")
    const navigate = useNavigate();
    if (isLoading) {
        return <Flex style={{height: "100%"}} align={"center"} justify={"center"}>
            <Flex.Item>
                <Loading />
            </Flex.Item>
        </Flex>
    }
    return <Page>
        <NavBar
            style={{
                // '--height': '36px',
                // '--border-bottom': '1px #eee solid',
            }}
            onBack={() => {
                navigate(-1)
            }}
        >
            关于我们
        </NavBar>
        <PageBody>
            <Article>
                <h4>关于我们</h4>
                <p>
                    {value.name}隶属于{value.corporateName}
                    专门提供以H5邀请函、长表单、长单页、长海报、图片、短视频的设计和制作服务为主的H5互动综合营销平台。
                    帮助更多人轻松完成设计，实现更多人的梦想与创意。
                </p>
                <h4>联系地址</h4>
                <p>
                    {value.corporateAddress}
                </p>
                <h4>联系电话</h4>
                <p>
                    15375717130
                </p>
            </Article>
        </PageBody>
        <Footer
        />
    </Page>
}


export default ContactUs;
