// import {Overlay} from "react-vant";
import {PaymentLoadingLayout, PaymentLoading} from "./styles";
import React from "react";
import {Mask} from "antd-mobile";
import {Loading} from "@firefly/fly-kda";


interface PaymentLoadingOverlayProps {
    visible: boolean;
}
export default function PaymentLoadingOverlay(props: PaymentLoadingOverlayProps) {
    const {
        visible,
    } = props;
    return  <Mask
        visible={visible}
    >
        <PaymentLoadingLayout>
            <PaymentLoading type={"spinner"} indicatorSize={30} direction={"vertical"} tip={`验证中`} />
        </PaymentLoadingLayout>
    </Mask>
}