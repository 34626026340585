import { BridgedContext } from "@firefly/fly-security";
import React from "react";
import TrackingProvider from "../trace/TrackingProvider";

export interface DOMLaunchProps {
    children: React.ReactNode;
}

function DOMLaunch(props: DOMLaunchProps): React.ReactElement {
    const {
        children
    } = props;
    return <>
        <BridgedContext>
            <TrackingProvider>
                {children}
            </TrackingProvider>
        </BridgedContext>
    </>
}

export default DOMLaunch;