import React from "react";
import {NavBar as ANavBar} from "antd-mobile";
// import {NavBar as VantNavBar} from "react-vant";
import {useNavigate} from "react-router-dom";
import "./index.scss";
import { ReactComponent as BackOutline } from "src/asset/nav/back2.svg";
import cls from "classnames";

export interface NavBarProps {
    className?: string;
    style?: React.CSSProperties & Record<string, string>;
    title?: React.ReactNode;
    border?: boolean;
    // left?: boolean;
    onBack?: () => void;
    right?: React.ReactNode ;
    onRightClick?: () => void;
    children?: React.ReactNode;
}
function NavBar(props: NavBarProps) {
    const {
        title,
        style,
        border,
        onBack: _onBack,
        // left = undefined ,
        right,
        onRightClick,
        children
    } = props;

    function onBack() {
        if (_onBack) {
            _onBack();
        } else {
            navigate(-1);
        }
    }
    const navigate = useNavigate();
    return <ANavBar
        className={cls("fly-nav-bar", {
            "fly-nav-bar--border": border
        })}
        // title={title}
        // border={border}
        style={style}
        onBack={onBack}
        backArrow={
            <BackOutline />
        }
        // leftArrow={left}
        // rightText={right}
        // onClickLeft={onLeftClick}
        // onClickRight={onRightClick}
    >
        {title}
        {children}
    </ANavBar>
}

export default NavBar;
