import React, {useCallback, useEffect, useMemo} from "react";
import Cookies from "js-cookie";
import {QueryClient, useQuery} from "@tanstack/react-query";
import {http} from "@firefly/fly-http";
import {isBot, isByteapp, isMiniapp, isWeapp} from "@firefly/fly-defter";
import fingerprint, {getUnstableScreenResolution, sources} from "@sniff/fingerprint";
import TrackingContext, {TrackingContextType} from "./TrackingContext";
import useAdvert from "./useAdvert";
import {Reporter} from "../util/reporter";
import {Adverts} from "./advert";
import { COOKIE_NAME_TRACKING } from "./config";

interface TrackingProviderProps {
    children: React.ReactNode;
}
function TrackingProvider(props: TrackingProviderProps) {
    const {
        children
    } = props;


    const queryClient = React.useMemo(() => {
        return new QueryClient({})
    }, []);

    const {
        isSuccess,
        data: tracking
    } = useQuery({
        enabled: !isBot() && !isMiniapp() && !isWeapp() && !isByteapp(),
        queryKey: ["tracking"],
        queryFn: () => {
            // if (isTra)
            // const gid = Cookies.get(COOKIE_NAME_TRACKING);
            // if (gid) {
            //     return Promise.resolve({
            //         gid: gid
            //     });
            // }
            return http.get(`https://www.i-firefly.com/api/promotion/tracking/web`, {
            }, {
                withCredentials: true,
            }).then((tracking) => {
                if (tracking && tracking.gid) {
                    Cookies.set(COOKIE_NAME_TRACKING, tracking.gid, {
                        expires: 365 * 3,
                    })
                }
                return tracking
            })
        },
    }, queryClient)

    const ContextValue = useMemo<TrackingContextType>(() => {
        return {
            isLoaded: isSuccess,
            tracking: tracking??null
        }
    }, [isSuccess, tracking]);

    const advert = useAdvert();

    useEffect(() => {
        if (advert) {
            Adverts.store(advert);
        }
    }, [advert]);
    const setup = useCallback(async function setup() {
        const agent = await fingerprint.load()
        const value = await agent.get();
        const screen = window.screen;
        Reporter.config({
            metadata: {
                fingerprint: value.visitorId,
                resolution: screen ? `${screen.width}x${screen.height}` : ''
            }
        });
    }, []);
    useEffect(() => {
        if (tracking) {
            setup().then();
        }
    }, [tracking, setup]);
    useEffect(() => {
        if (advert && tracking) {
            Reporter.advert(advert);
        }
    }, [tracking, advert])
    return <TrackingContext.Provider value={ContextValue}>
        {children}
    </TrackingContext.Provider>
}

export default TrackingProvider;