// import {Card, Image} from "react-vant";
import {Order} from "@firefly/fly-design-inv-data";
import {
    OrderCardLineItem,
    OrderCardLayout,
    OrderCardMetaAmount,
    OrderCardMetaContent,
    OrderCardMetaCover,
    OrderCardMetaNo,
    OrderCardMetaState,
    OrderCardMetaTitle,
} from "./styles";
import {PAYMENT_STATE_LABEL_MAP} from "src/config";
import {cover2order} from "src/util/format";
import {useNavigateToOrderLineItem} from "src/suppport/router";
import { Card, Image } from "antd-mobile";
import {createNamespace} from "@firefly/fly-kda";
import cls from "classnames";


const [bem] = createNamespace("order-card")
interface OrderCardProps {
    value: Order
}
function OrderCard(props: OrderCardProps) {
    const {
        value,
    } = props;
    const {
        id,
        lineItems,
        paymentState
    } = value;

    const navigateToOrderLineItem = useNavigateToOrderLineItem();
    return <OrderCardLayout className={cls(bem())}>
        <Card>
            <div className={cls(bem("header"))}>
                <OrderCardMetaNo>
                    订单号：{id}
                </OrderCardMetaNo>
                <OrderCardMetaState>
                    {PAYMENT_STATE_LABEL_MAP[paymentState]}
                </OrderCardMetaState>
                {/*<OrderCardMetaTime>*/}
                {/*    {new Date(Number(value.createdAt)).toISOString()}*/}
                {/*    /!*2022-07-11 00:00:00*!/*/}
                {/*</OrderCardMetaTime>*/}
            </div>
            <div className={cls(bem("body"))}>
                {lineItems?.map((lineItem, index) => {
                    const {
                        imageUrl,
                        title,
                        price,
                    } = lineItem;
                    return <OrderCardLineItem key={index} onClick={() => {
                        navigateToOrderLineItem(lineItem);
                    }}>
                        <OrderCardMetaCover>
                            {imageUrl ?
                            <Image
                                src={cover2order(imageUrl)}
                                fit={"contain"}
                            /> : null}
                        </OrderCardMetaCover>
                        <OrderCardMetaContent>
                            <OrderCardMetaTitle>
                                {title}
                            </OrderCardMetaTitle>
                            <OrderCardMetaAmount>
                                {/*{order.metadata.originalAmount ? <>*/}
                                {/*    限时优惠*/}
                                {/*</> : null}*/}
                                ￥{price}
                                {/*{order.metadata.originalAmount ? <OrderItemMetaOriginalAmount>*/}
                                {/*    ￥{order.metadata.originalAmount}*/}
                                {/*</OrderItemMetaOriginalAmount> : null}*/}
                            </OrderCardMetaAmount>
                        </OrderCardMetaContent>
                    </OrderCardLineItem>
                })}
                {/*<OrderCardItem>*/}
                {/*    <OrderCardMetaCover>*/}
                {/*        <Image*/}
                {/*            src={formatCoverUrl??""}*/}
                {/*            fit={"contain"}*/}
                {/*        />*/}
                {/*    </OrderCardMetaCover>*/}
                {/*    <OrderCardMetaContent>*/}
                {/*        <OrderCardMetaTitle>*/}
                {/*            {value.subject}*/}
                {/*        </OrderCardMetaTitle>*/}
                {/*        <OrderCardMetaAmount>*/}
                {/*            /!*{order.metadata.originalAmount ? <>*!/*/}
                {/*            /!*    限时优惠*!/*/}
                {/*            /!*</> : null}*!/*/}
                {/*            ￥{value.amount}*/}
                {/*            /!*{order.metadata.originalAmount ? <OrderItemMetaOriginalAmount>*!/*/}
                {/*            /!*    ￥{order.metadata.originalAmount}*!/*/}
                {/*            /!*</OrderItemMetaOriginalAmount> : null}*!/*/}
                {/*        </OrderCardMetaAmount>*/}
                {/*    </OrderCardMetaContent>*/}
                {/*</OrderCardItem>*/}

            </div>
        </Card>
    </OrderCardLayout>
}

export default OrderCard;
