import React, {useEffect, useState} from "react";
import {useNavigationType, useSearchParams} from "react-router-dom";
import {omitUndefined} from "@firefly/fly-defter";
import {useAuthenticationPrincipal} from "@firefly/fly-security";
import styled from "styled-components";
import "./index.scss";
import {useDocumentMeta} from "@firefly/fly-hook";
import { Loading } from "@firefly/fly-kda";
import {useSecurity} from "../../../context/security";
import Fallback from "../../../security/SecuredBoundary/Fallback";

const IndicatorOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  inset: 0;
`
function Login(): React.ReactElement | null {
    const [searchParams] = useSearchParams()
    const returnUrl = searchParams.get("return")??`//${window.location.host}/`;
    const {
        isPending,
        isAuthenticated,
    } = useAuthenticationPrincipal();
    const navigationType = useNavigationType();
    const {

        authenticate
    } = useSecurity();
    // useEffect(() => {
    //     if (isAuthenticated) {
    //         window.location.href = returnUrl;
    //     } else {
    //         if (navigationType === 'POP') {
    //             window.history.back();
    //         } else {
    //             authenticate({
    //
    //             }).then()
    //         }
    //     }
    // }, [isAuthenticated, navigationType, returnUrl])
    if (isPending) {
        return <IndicatorOverlay>
            <Loading />
        </IndicatorOverlay>;
    } else if (isAuthenticated) {
        window.location.href = returnUrl;
        return null
    } else if (navigationType === 'POP') {
        window.history.back();
        return null
    } else {
        return <Fallback
            description={`登录后才可以看到`}
        />
    }

}

export default Login;
