import styled from "styled-components";


export const Ball2 = styled.div`
    user-select: none;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 100;
    width: 48px;
    height: 48px;
`
// export const Ball = styled(FloatingBall)`
//
//   //padding: 10px;
//   //font-size: 16px;
//   //color: #fff;
//   //height: auto;
//   //width: 36px;
//   //border-radius: 6px;
//   //.rv-floating-ball__base {
//   //  width: auto;
//   //  height: auto;
//   //}
// `
const shadow = `0 3px 8px rgba(0, 0, 0, 0.08)`;
export const MainButton = styled.div<{
    active?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: ${shadow};
  //transition: all 0.2s ease-in-out;
  
  font-size: 28px;
  background: #fff;
  color: #3664F4;
  svg {
    font-size: 28px;
  }

  color: ${props => props.active? '#999': undefined }
`
