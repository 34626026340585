import React from "react";
import {ProjectVariant, Template, ViewerTemplate} from "@firefly/fly-design-inv-data";
import {
    OrderItemCard,
    OrderItemCardBody,
    OrderItemCardHeader,
    OrderLineItem, OrderLineItemMetaAmount,
    OrderLineItemMetaCover, OrderLineItemMetaDetail, OrderLineItemMetaOriginalAmount, OrderLineItemMetaTitle
} from "../../payment/styles";
import {Image} from "antd-mobile";
import {PaymentExplain} from "./styles";
import DesignerMakeForm from "../../payment/component/DesignerMakeForm";
import {Divider} from "@firefly/fly-kda";
import DesignForm from "../../payment/component/DesignForm";
import Indicator from "src/component/Indicator";


export interface TemplateOrderItemProps {
    template: ViewerTemplate;
    variants: ProjectVariant[];
    selectedVariant: ProjectVariant | null;
    setSelectedVariant: (value: ProjectVariant | null) => void;
    extra: Record<string, any>;
    setExtra: (value: Record<string, any>) => void;
}
function TemplateOrderItem(props: TemplateOrderItemProps) {
    const {
        template,
        variants,
        selectedVariant,
        setSelectedVariant,
        extra,
        setExtra,
    } = props;
    const coverUrl = template?.cover?.url;
    const formatCoverUrl = coverUrl ? `${coverUrl}?x-oss-process=image/resize,w_360/crop,h_720/format,jpg/quality,q_60` : null;

    return <>
        <OrderItemCard>
            <OrderItemCardHeader>
                模板设计费
            </OrderItemCardHeader>
            <OrderItemCardBody>
                <OrderLineItem>
                    <OrderLineItemMetaCover>
                        <Image
                            src={formatCoverUrl??""}
                            fit={"contain"}
                        />
                    </OrderLineItemMetaCover>
                    <OrderLineItemMetaDetail>
                        <OrderLineItemMetaTitle>
                            {template.title}
                        </OrderLineItemMetaTitle>
                        <OrderLineItemMetaAmount>
                            {template.pricing.originalAmount ? <>
                                限时优惠
                            </> : null}
                            ￥{template.pricing.amount}
                            {template.pricing.originalAmount ? <OrderLineItemMetaOriginalAmount>
                                ￥{template.pricing.originalAmount}
                            </OrderLineItemMetaOriginalAmount> : null}
                        </OrderLineItemMetaAmount>
                    </OrderLineItemMetaDetail>
                </OrderLineItem>
            </OrderItemCardBody>
            <PaymentExplain>
                <ul>
                    <li>
                        购买模板后文字、图片、音乐可任意修改，重复使用制作邀请函，一键转发亲朋好友，朋友圈！
                    </li>
                </ul>
            </PaymentExplain>
        </OrderItemCard>
        <OrderItemCard>
            <DesignerMakeForm
                subtitle={"推荐加选"}
                selectedVariantIdentifier={selectedVariant?.identifier}
                variants={variants}
                onSelect={(_, variant) => {
                    setSelectedVariant(variant)
                }}
            >
                {selectedVariant ? <>
                    <Divider style={{margin: "4px 0"}} />
                    <Indicator.Suspense>
                        <DesignForm value={extra} onChange={v => setExtra(v)} />
                    </Indicator.Suspense>
                </> : null}
            </DesignerMakeForm>
        </OrderItemCard>
    </>
}

export default TemplateOrderItem;