import React, {useRef} from "react";
import classNames from "classnames";
import useCells from "./hooks/useCells";
import WaterfallBody from "./WaterfallBody";
import useGridLayout from "./hooks/useGridLayout";

interface WaterfallProps {
  column?: number
  //行间距，高间距
  gutter?: number;

  width: number;
  children?: React.ReactNode;
}

function Waterfall(props: WaterfallProps) {
  const {
    column = 2,
    gutter = 16,
    width,
    children
  } = props;
  const cells = useCells({
    children: children
  });
  const ref = useRef<HTMLDivElement>(null);

  // const [layoutWidth, setLayoutWidth] = React.useState<number | undefined>(width);

  const grid = useGridLayout((width??0) - (gutter * (column + 1)), cells, column);
  return <div ref={ref} className={classNames("watermark")} style={{
    width: width
  }}>
    <WaterfallBody gutter={gutter} layout={grid} dataSource={cells}/>
  </div>
  // return <AutoSizer>
  //   {(size: Size) => {
  //
  //   }}
  //
  // </AutoSizer>
  //考虑设计虚拟DOM
  // return <ResizeObserver disabled={!!layoutWidth} onResize={({ width }) => {
  //   setLayoutWidth(width)
  // }} >
  //
  // </ResizeObserver>
}

export default Waterfall;
