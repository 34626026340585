import {ScrollRestoration} from "./types";
import {Restoration} from "./restoration";


function restoreRetry(callback: () => (true | void), timeout: number = 2000) {
    const restoreTime = new Date().getTime();
    function async(func: Function, time: number = 50) {
        setTimeout(() => {
            requestAnimationFrame(() => {
                func()
            })
        }, time)
    }

    function run() {
        if (new Date().getTime() - restoreTime > timeout) {
            return;
        }
        const result = callback();
        if (result !== true) {
            async(run)
        }
    }
    run();
}

export class RestorationElement {
    private readonly key: string;
    private restoration: Restoration;
    private element: HTMLElement;


    constructor(
        restoration: Restoration,
        key: string,
        element: HTMLElement
    ) {
        this.restoration = restoration;
        this.key = key;
        this.element = element;
    }

    bind(element: HTMLElement) {
        this.element = element;
    }
    save() {
        const scrollLeft = this.element.scrollLeft;
        const scrollTop = this.element.scrollTop;
        const restoration = {
            scrollLeft: scrollLeft,
            scrollTop: scrollTop,
        }
        this.restoration.setCache(this.key, restoration)
    }

    resume() {
        const restoration: ScrollRestoration | undefined = this.restoration.getCache(this.key);
        if (restoration) {

            restoreRetry(() => {
                if (this.element.scrollTop === restoration.scrollTop && this.element.scrollLeft === restoration.scrollLeft) {
                    return true;
                }
                this.element.scrollLeft = restoration.scrollLeft;
                this.element.scrollTop = restoration.scrollTop;
                return ;
            })

        }
    }
}
