import {ScrollRestoration} from "./types";
import {RestorationElement} from "./restorationElement";

export class Restoration {
    private cache: Record<string, ScrollRestoration> = {};


    public getCache(key: string): ScrollRestoration | undefined {
        return this.cache[key];
    }
    public setCache(key: string, value: ScrollRestoration) {
        this.cache[key] = value;
    }
    public removeCache(key: string) {
        delete this.cache[key];
    }

    public mountElement(key: string, element: HTMLElement): RestorationElement {
        return new RestorationElement(this, key, element)
    }
}
