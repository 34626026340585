import NavBar from "../NavBar";
import {Button, Form, Input, TextArea} from "antd-mobile";
import cls from "classnames";
import "./style/index.scss";
import {
    useCreateSendByMutation,
    useRemoveSendByMutation,
    useSaveSendByMutation,
    useSendSuspenseQuery
} from "../../../hook/send";
import {useNavigate, useParams} from "react-router-dom";
import React from "react";
import {InvitationSend} from "@firefly/fly-design-inv-data";

export interface SendFormProps {
    initialValues: Partial<InvitationSend>;
    onFinish: (values: Partial<InvitationSend>) => Promise<void>;
    submit?: React.ReactNode;
    extra?: React.ReactNode;
}
function SendForm(props: SendFormProps) {
    const {
        initialValues,
        onFinish,
        submit,
        extra,
    } = props;

    return (
        <Form
            className={cls("send-form")}
            requiredMarkStyle="text-optional"
            initialValues={initialValues}
            onFinish={onFinish}
            footer={
                <>
                    <Button
                        block
                        type={"submit"}
                        shape={"rounded"}
                        className={cls("fly-button-primary", "send-design__submit")}
                        color={"primary"}
                    >
                        {submit}
                    </Button>
                    {extra}
                </>

            }
        >
            <Form.Item
                name={"toName"}
                label={"送呈宾客"}
                rules={[
                    {required: true},
                    {max: 12, message: "限制12字以内"}
                ]}>
                <Input
                    placeholder={"请填写送呈宾客称呼"}
                />
            </Form.Item>
            <Form.Item
                name={"explain"}
                label={"送呈短语"}
                rules={[
                    {required: true},
                    {max: 18, message: "限制18字以内"}
                ]}
            >
                <TextArea placeholder={"请输入18个字以内送呈语"}/>
            </Form.Item>
        </Form>

    )
}

export default SendForm;