import {InfiniteScroll as AInfiniteScroll, InfiniteScrollProps as AInfiniteScrollProps  } from "antd-mobile";
import React from "react";
import "./index.scss";
import {Loading} from "@firefly/fly-kda";

export interface InfiniteScrollProps extends AInfiniteScrollProps{

}
function InfiniteScroll(props: InfiniteScrollProps): React.ReactElement {
    const {
        hasMore,
        loadMore
    } = props;

    return <AInfiniteScroll
        className={"fly-infinite-scroll"}
        hasMore={hasMore}
        threshold={250}
        loadMore={loadMore}
    >
        {(hasMore, failed, retry) => {

            if (!hasMore) {
                return <div className={"fly-infinite-scroll--nomore"}>
                    — 到底啦 —
                </div>
            }
            if (failed) {
                return <button
                    className={"fly-infinite-scroll--loadmore"}
                    onClick={retry}
                >
                    加载更多
                </button>
            }
            return <Loading />

        }}
    </AInfiniteScroll>
}

export default InfiniteScroll;