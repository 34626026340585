import styled from "styled-components";


export const Page = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
`

export const PageBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`
export const PageAction = styled.div`
  padding: 18px 12px;
`

export const ImageWrapper = styled.div`
  position: relative;
  padding: 6vw;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  //height: 0;
`;
export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  align-items: center;
  justify-content: center;
`
export const ImageContent = styled.img`
  max-width: 100%;
  max-height: 100%;
  box-shadow: 1px 1px 3px 2px #0000000d;
`;
export const PosterTip = styled.div`
  color: #b1b1b1;
  font-size: 12px;
  bottom: 0;
  margin-top: 12px;
`
