import {useEffect, useMemo, useState} from "react";
import {PaymentMethod, usePayment} from "@firefly/fly-payment";
import {useCreateOrderOfProjectMutation} from "src/hook/order";
import {useNavigate} from "react-router";
import {useNavigateToContactDesigner, useNavigateToOrderComplete} from "../../../suppport/router";
import {useProjectVariantsQuery} from "src/hook/project-variant";
import {OrderExtra, ProjectVariant} from "@firefly/fly-design-inv-data";
import {useSecurity} from "../../../context/security";


export default function useMakePayment() {


    const navigate = useNavigate();

    const {
        isPending,
        setPending,
        execute,
        isCheckFetching,
        check,
        isCompleted,
        pendingPayment,
    } = usePayment(["MAKE"]);
    const {
        requestAuthenticated,
    } = useSecurity();

    const {
        isFetching: isVariantsLoading,
        data: variants = []
    } = useProjectVariantsQuery("MAKE", ["in24h", "in6h", "in3h", "in1h"]);
    const [selectedVariant, setSelectedVariant] = useState<ProjectVariant | null>(null)
    useEffect(() => {
        if (variants.length > 0) {
            setSelectedVariant(variants[0])
        }
    }, [variants])
    const amount = selectedVariant?.pricing.amount;
    const {
        mutateAsync: createOrderOfProject
    } = useCreateOrderOfProjectMutation();
    function executeMakePayment(method: PaymentMethod, extra: OrderExtra = {}) {
        const selectedProjectIdentifier = {
            project: selectedVariant!.project,
            identifier: selectedVariant!.identifier,
        };
        return requestAuthenticated().then(() => {
            return createOrderOfProject({
                lineItems: [selectedProjectIdentifier],
                extra: extra,
            }).then((order) => {
                return execute({
                    id: order.paymentOrderId,
                    outId: order.id,
                }, method, {

                }).then((status) => {
                    if (status === 'completed') {
                        navigateToOrderComplete({
                            id: order.id
                        }).then()
                        return;
                    }
                    if (status === 'pending') {
                        return
                    }
                });
            })
        })
    }


    const navigateToOrderComplete = useNavigateToOrderComplete();
    useEffect(() => {
        if (pendingPayment && isCompleted) {
            navigateToOrderComplete({
                id: pendingPayment.outId
            }).then()
        }
    }, [pendingPayment, isCompleted, navigateToOrderComplete])
    return {
        requestAuthenticated,
        isPending,
        setPending,
        isVariantsLoading,
        variants,
        selectedVariant,
        setSelectedVariant,
        amount,
        execute: executeMakePayment,
        isCheckFetching,
        check,
        isCompleted: isCompleted,
    }
}