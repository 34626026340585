import gql from "fake-tag";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Picture} from "@firefly/fly-design-core";
import {useGraphQLClient} from "@firefly/fly-security";
import {InvitationPage} from "@firefly/fly-design-inv-data";

export function usePageQuery(id: string, {
    // suspense
}: {
    // suspense?: boolean
} = {}) {
    const graphqlClient = useGraphQLClient();
    return useQuery<InvitationPage>({
        queryKey: ['invitation', 'page', id],
        queryFn: () => {
            return graphqlClient.query({
                query: gql`
                    query page($id: ID!) {
                        invitationPage(id: $id) {
                            id,
                            title,
                            description,
                            cover {
                                width,
                                height,
                                url
                            },
                            preview {
                                width,
                                height,
                                url
                            },
                            doctype,
                            document
                        }
                    }
                `,
                variables: {
                    id: id
                }
            }).then((output) => {
                return output.invitationPage;
            })
        }
    })
}



export function useCreatePageSharePosterMutation() {
    const graphqlClient = useGraphQLClient();

    return useMutation<Picture, any, {
        id: any;
    }>({
        mutationFn: (variables) => {
            return graphqlClient.mutate({
                mutation: gql`
                    mutation createPageSharePoster($input: CreatePageSharePosterInput) {
                        createPageSharePoster(input: $input) {
                            picture {
                                width,
                                height,
                                url
                            }
                            error {
                                code,
                                message
                            }
                        }
                    }
                `,
                variables: {
                    input: variables
                }
            }).then((output) => {
                const createPageSharePoster = output.createPageSharePoster;
                if (createPageSharePoster.error) {
                    throw createPageSharePoster.error
                }
                return createPageSharePoster.picture
            })
        }
    })
}
