import {Navigate} from "react-router-dom";
import {useEffect} from "react";


function Logout() {

    useEffect(() => {
        sessionStorage.removeItem("bridge");
    }, []);

    return <Navigate to={"/"} replace />
}

export default Logout;