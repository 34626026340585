import {useMutation} from "@tanstack/react-query";
import {http, Http} from "@firefly/fly-http";

export function useGenerateWechatWeappURLMutation() {


    return useMutation({
        mutationFn: ({
             scheme,
             jump
        }: {
            scheme: 'http' | 'weixin'
            jump: any
        }) => {
            return http.post(`/api/wechat/weapp/url`, {
                scheme: scheme,
                jump: jump
            })
        }
    })
}