import DocumentTitle from "react-document-title";
import NavBar from "src/page/component/NavBar";
import {
    ContactGroup, DesignerQRCodeImage,
    Page,
    PageBody,
    Section,
    SectionBody,
    SectionHeader,
    ServiceAvatar,
    ServiceBody,
    ServiceDescription,
    ServiceTime,
    ServiceTitle,
} from "./styles";
// import {Typography} from "react-vant";
import {ClockO, ServiceO} from "@react-vant/icons";
import DESIGNER_QRCODE_URL from "src/asset/guide/designer-qrcode.png";
import { Typography } from "@firefly/fly-kda";
import {Button} from "antd-mobile";
import ContactButton from "./ContactButton";


function ContactDesigner() {

    return <DocumentTitle title={"服务"}>
        <Page>
            <NavBar title={"服务"}/>
            <PageBody>
                <Section>
                    <SectionHeader>联系设计师</SectionHeader>
                    <SectionBody>
                        <ServiceDescription>
                            <ServiceAvatar>
                                <ServiceO  />
                            </ServiceAvatar>
                            <ServiceTitle>
                                设计师
                            </ServiceTitle>
                            {/*<DesignerQRCodeImage*/}
                            {/*    src={DESIGNER_QRCODE_URL}*/}
                            {/*/>*/}
                            <ServiceBody>
                                <ServiceTime>
                                    <ClockO  /> 工作日：上午9:00 —— 晚上21:00
                                </ServiceTime>
                                <Typography.Text type="secondary">非工作时间，不定时回复</Typography.Text>
                            </ServiceBody>
                        </ServiceDescription>
                    </SectionBody>
                </Section>
                <ContactGroup>
                    <ContactButton>
                        联系设计师
                    </ContactButton>
                </ContactGroup>
            </PageBody>
        </Page>
    </DocumentTitle>
}

export default ContactDesigner;
