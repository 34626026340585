
import {useViewReport} from "src/hook/view";
import React, {useState} from "react";
import {PaymentMethod} from "@firefly/fly-payment";
import {useNavigate} from "react-router-dom";
import NavBar from "src/page/component/NavBar";
import {HomeO} from "@react-vant/icons";
import {
    Body,
    Footer,
    FooterContent,
    OrderItemCard, Page,
    PaymentButtonAmount,
} from "src/page/payment/styles";
import DesignerMakeForm from "src/page/payment/component/DesignerMakeForm";
import DesignForm from "src/page/payment/component/DesignForm";
import PaymentMethodSelect from "src/page/payment/component/PaymentMethodSelect";
import SafeAreaPadding from "src/component/SafeArea/SafeAreaPadding";
import PaymentCheckLoadingOverlay from "../component/PaymentCheckLoadingOverlay";
import useMakePayment from "./useMakePayment";
import useConfigPaymentMethods from "../../../hook/useConfigPaymentMethods";
import {ProjectVariant} from "@firefly/fly-design-inv-data";
import {Divider} from "@firefly/fly-kda";
import {Button, Dialog} from "antd-mobile";
import {error as reportError} from "src/util/reporter";
import Indicator from "src/component/Indicator";
import PaymentConfirmDialog from "../component/PaymentConfirmDialog";


export interface MakePaymentProps {

}

function MakePayment(props: MakePaymentProps) {

    const {
        requestAuthenticated,
        variants,
        isVariantsLoading,
        selectedVariant,
        setSelectedVariant: _setSelectedVariant,
        execute,
        isPending,
        setPending,
        isCheckFetching,
        check,
        isCompleted,
    } = useMakePayment();

    useViewReport(() => {
        return {
            label: 'MakePayment',
            extra: {
            }
        }
    }, [])

    const [extra, setExtra] = useState<Record<string, string>>();
    const paymentMethods = useConfigPaymentMethods();
    const [paymentMethod, _setPaymentMethod] = useState<PaymentMethod>(() => {
        return paymentMethods[0]
    });
    const [paying, setPaying] = useState<boolean>(false);
    function setSelectedVariant(v: ProjectVariant | null) {
        if (paying) {
            return;
        }
        _setSelectedVariant(v);
    }
    function setPaymentMethod(v: PaymentMethod) {
        if (paying) {
            return;
        }
        _setPaymentMethod(v);
    }
    const amount = selectedVariant?.pricing.amount;

    // const =
    const navigate = useNavigate();
    function executePayment() {
        setPaying(true);
        requestAuthenticated().then(() => {
            return execute(paymentMethod, {
                ...extra
            }).catch((reason) => {
                if (reason === 'cancel') {
                    return;
                }
                reportError('make-payment failure' + reason, {});
            })
        }).finally(() => {
            setPaying(false)
        })
    }

    return <SafeAreaPadding>
        <Page>
            <NavBar
                title={"设计师服务"}
                right={isCompleted ? <>
                    <HomeO fontSize={22} />
                </> : null}
                onRightClick={() => {
                    navigate("/")
                }}
            />
            {!isCompleted ? <>
                <Body>
                    <OrderItemCard>
                        <DesignerMakeForm
                            isLoading={isVariantsLoading}
                            selectedVariantIdentifier={selectedVariant?.identifier}
                            variants={variants}
                            onSelect={(_, variant) => {
                                setSelectedVariant(variant)
                            }}
                        >
                            {selectedVariant ? <>
                                <Divider style={{margin: "4px 0"}} />
                                <Indicator.Suspense>
                                    <DesignForm value={extra} onChange={v => setExtra(v)} />
                                </Indicator.Suspense>
                            </> : null}
                        </DesignerMakeForm>
                    </OrderItemCard>
                    <OrderItemCard padding={false}>
                        <PaymentMethodSelect
                            selectedValue={paymentMethod}
                            onSelect={setPaymentMethod}
                            methods={paymentMethods}
                        />
                    </OrderItemCard>
                    {/*<HelpGuide>*/}
                    {/*    如需帮助，请联系客服：<Link to={`/viphelp-center`}>点击咨询</Link>*/}
                    {/*</HelpGuide>*/}
                </Body>
                <Footer>
                    <FooterContent>
                        <Button
                            className={"fly-button-primary"}
                            loading={paying}
                            color={"primary"}
                            onClick={executePayment}
                            disabled={!amount}
                            block
                        >
                            立即支付 {amount && amount > 0 ? <PaymentButtonAmount>￥{amount}</PaymentButtonAmount> : null}
                        </Button>
                    </FooterContent>
                </Footer>
            </>: null}
            {/*{isCompleted ? <MakePaymentResult>*/}
            {/*    <Result*/}
            {/*        title={"完成"}*/}
            {/*        extra={*/}
            {/*            <Space direction={"vertical"} gap={16} block>*/}
            {/*                /!*{!isWechat() ?*!/*/}
            {/*                <Button block plain type={"primary"} onClick={() => {*/}
            {/*                    navigate("/homepage/me", {*/}
            {/*                        replace: true*/}
            {/*                    })*/}
            {/*                    return navigateToCustomerService().then();*/}
            {/*                }}>*/}
            {/*                    加设计师对接资料*/}
            {/*                </Button>*/}
            {/*                /!*: null}*!/*/}
            {/*                <Button block type={"primary"} onClick={next}>*/}
            {/*                    完成*/}
            {/*                </Button>*/}
            {/*            </Space>*/}
            {/*        }*/}
            {/*    />*/}
            {/*</MakePaymentResult> : null}*/}
            <PaymentCheckLoadingOverlay
                visible={!isPending && isCheckFetching}
            />
            <PaymentConfirmDialog
                visible={isPending}
                onCancel={() => {
                    setPending(false);
                }}
                onOk={() => {
                    check().then();
                    setPending(false)
                }}
            />
        </Page>
    </SafeAreaPadding>
}

export default MakePayment;