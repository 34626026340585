import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useQueryClient} from "@tanstack/react-query";
import {useViewerOrderQuery, useViewerQueryPaymentStateQuery} from "src/hook/order";
import NavBar from "src/page/component/NavBar";
import {Page} from "./styles";
import OrderEmpty from "../component/OrderEmpty";
import Result from "src/component/Result";
// import useBeat from "@firefly/fly-painter-util/es/hook/useBeat";
import useOrderProject from "./useOrderProject";
import TemplateOrderResultExtra from "./TemplateOrderResultExtra";
import MakeOrderResultExtra from "./MakeOrderResultExtra";
import {useParams} from "react-router";
import { useBeat } from "@firefly/fly-hook";
import {Button, Mask} from "antd-mobile";
import {Space, Loading, Flex} from "@firefly/fly-kda";
import PaymentConfirmDialog from "../component/PaymentConfirmDialog";
import PaymentCheckLoadingOverlay from "../component/PaymentCheckLoadingOverlay";

interface OrderSuccessProps {

}
export default function OrderSuccess(props: OrderSuccessProps) {
    const {id} = useParams();


    const {
        data: order,
        isLoading: orderLoading,
    } = useViewerOrderQuery(id!);
    const queryClient = useQueryClient();
    const {
        isFetching: isCheckFetching,
        refetch: check,
        data: orderState
    } = useViewerQueryPaymentStateQuery(id!, {
        enabled: !!(order),
    });
    const state = orderState?.paymentState;
    useEffect(() => {
        if (state) {
            queryClient.setQueryData(['viewer', 'order', id], (order: any) => {
                if (order) {
                    order.paymentState = state;
                    return order;
                }
                return order;
            })
        }
    }, [id, state]);

    const [checkFailed, setCheckFailed] = useState<boolean>(false);

    const enabledChecked =  !!(!checkFailed && order && order.paymentState !== 'PAID');
    useBeat({
        enabled: enabledChecked,
        onBeat: (index) => {
            if (index > 30) {
                setCheckFailed(true);
                return Promise.reject();
            }
            return check().then();
        },
        interval: 1000,
    });

    const {
        templateLineItem,
        templateMakeLineItem,
        makeLineItem
    } = useOrderProject(order);


    const navigate = useNavigate();
    function renderExtra() {
        if (templateLineItem) {
            return <TemplateOrderResultExtra
                template={templateLineItem}
                templateMake={templateMakeLineItem}
            />
        }
        if (makeLineItem) {
            return <MakeOrderResultExtra
            />
        }
        if (templateMakeLineItem) {
            return <MakeOrderResultExtra
            />
        }
        return <Space direction={"vertical"} gap={16} block>
            <Button className={"fly-button-primary"} block color={"primary"} onClick={() => {
                navigate("/homepage/me")
            }}>
                完成
            </Button>
        </Space>
    }
    return <Page>
        <NavBar title={"完成支付"} />
        {orderLoading ? <Flex align={"center"} justify={"center"} style={{height: "100%"}}>
            <Loading />
        </Flex>: null}
        {!orderLoading && !order ? <>
            <OrderEmpty />
        </> : null}
        {order && order.paymentState === 'PAID' ? (
            <Result
                title={"完成"}
                subtitle={order?.subject}
                extra={
                    renderExtra()
                }
            />
        ) : <>
            {checkFailed ? <>
                <OrderEmpty description={"支付验证失败，请刷新重试，或联系在线客服。"}/>
            </> : null}
        </>}
        <PaymentCheckLoadingOverlay
            visible={enabledChecked}
        />
        {/*<Mask*/}
        {/*    visible={enabledChecked}*/}
        {/*>*/}
        {/*    <PaymentLoadingWrapper>*/}
        {/*        <PaymentLoading>*/}
        {/*            支付确认中*/}
        {/*        </PaymentLoading>*/}
        {/*    </PaymentLoadingWrapper>*/}
        {/*</Mask>*/}
    </Page>
}
