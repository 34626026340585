import {DependencyList, useEffect} from "react";
import {view} from "../util/reporter";
import {omitUndefined} from "@firefly/fly-defter";

export interface Report {
    label: string,
    extra: Record<string, string | undefined>
}
export function useViewReport(fn: () => Report, deps: DependencyList) {

    useEffect(() => {
        const report = fn();
        view(report.label, omitUndefined(report.extra) as any)
    }, deps)
}