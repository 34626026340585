import {
    CheckoutAction,
    CheckoutAgreement,
    CheckoutAmount,
    CheckoutAmountValue,
    CheckoutCounterBox,
    CheckoutCounterBoxContent,
    CheckoutPaymentButton,
    CheckoutTotal,
    CheckoutTotalLabel,
    Page,
    PageBody,
    PageHeader,
    PageHeaderContent,
    PVipTabs,
    PVipUser,
    PVipUserAvatar,
    PVipUserMetaDetail,
    PVipUserMetaNickname,
    PVipUserMetaVip,
    VipExplain,
    VipPackageOption,
    VipPackageOptionAmount,
    VipPackageOptionAmountValue,
    VipPackageOptionDescription,
    VipPackageOptionInner,
    VipPackageOptionTitle,
    VipPackageSelect,
    VipPackageSelectContent
} from "./styles";
import { Image } from "antd-mobile";
import NavBar from "src/page/component/NavBar";
import React, {useMemo} from "react";
import useVipPayment from "./useVipPayment";
import {Link} from "react-router-dom";
import HEADER_BACKGROUND_URL from "src/asset/header-bg.png";
import {isWechatpayPaymentType, PaymentMethod} from "@firefly/fly-payment";
import {useViewReport} from "src/hook/view";
import useConfigPaymentMethods from "src/hook/useConfigPaymentMethods";
import {useViewerQuery} from "src/context/security";
import Indicator from "src/component/Indicator";

/**
 * 购买会员
 * @constructor
 */
function PVip() {

    const {
        viewer
    } = useViewerQuery();
    const user = viewer!;


    const paymentMethods: PaymentMethod[] = useConfigPaymentMethods();
    const {
        options,
        setSelectedVariantIdentifier,
        selectedVariant,
        selectedVariantIdentifier,
        execute,
    } = useVipPayment();

    useViewReport(() => {
        return {
            label: 'PVip',
            extra: {

            }
        }
    }, []);

    const vipExpiresAt = useMemo(() => {
        if (user?.vip?.expiresAt) {
            const expiresAt = new Date(Number(user.vip.expiresAt));
            return `${expiresAt.getFullYear()}-${expiresAt.getMonth() + 1}-${expiresAt.getDate()}`;
        }

    }, [user]);


    return <Page>
        <PageHeader backgroundUrl={HEADER_BACKGROUND_URL}>
            <NavBar border={false} style={{background: "transparent"}} />
            <PageHeaderContent>
                <PVipUser>
                    <PVipUserAvatar>
                        <Image
                            src={user.avatar}
                            width={60}
                            height={60}
                            style={{
                                borderRadius: 30
                            }}
                        />
                    </PVipUserAvatar>
                    <PVipUserMetaDetail>
                        <PVipUserMetaNickname>
                            {user.nickname}
                        </PVipUserMetaNickname>
                        <PVipUserMetaVip>
                            {vipExpiresAt ? <>
                                于 {vipExpiresAt} 到期
                            </> : <>
                                你当前未开通会员
                            </>}
                        </PVipUserMetaVip>
                    </PVipUserMetaDetail>
                </PVipUser>
            </PageHeaderContent>
            <PVipTabs>

            </PVipTabs>
        </PageHeader>
        <PageBody>
            <VipPackageSelect>
                <VipPackageSelectContent>
                    {options.map((option, index) => {
                        return <VipPackageOption selected={selectedVariantIdentifier === option.value} key={index} onClick={() => {
                            setSelectedVariantIdentifier(option.value)
                        }}>
                            <VipPackageOptionInner>
                                {/*<VipPackageOptionBadge>{option.badge}</VipPackageOptionBadge>*/}
                                <VipPackageOptionTitle>{option.title}</VipPackageOptionTitle>
                                <VipPackageOptionAmount>
                                    ￥
                                    <VipPackageOptionAmountValue>
                                        {option.amount}
                                    </VipPackageOptionAmountValue>
                                </VipPackageOptionAmount>
                                <VipPackageOptionDescription>
                                    {option.description}
                                </VipPackageOptionDescription>
                            </VipPackageOptionInner>
                        </VipPackageOption>
                    })}

                </VipPackageSelectContent>
            </VipPackageSelect>
            <VipExplain>
                <p>
                    温馨提示：
                </p>
                <p>
                    1、会员属虚拟商品，不支持退款。
                </p>
                <p>
                    2、如果有问题或疑问，请 <Link to={`/help-center`}>联系客服</Link> 解决问题
                </p>
            </VipExplain>
            <CheckoutCounterBox>
                <CheckoutCounterBoxContent>
                    <CheckoutTotal>
                        {selectedVariant ? <>
                            <CheckoutTotalLabel>
                                合计：
                            </CheckoutTotalLabel>
                            <CheckoutAmount>
                                ￥
                                <CheckoutAmountValue>
                                    {selectedVariant.pricing.amount}
                                </CheckoutAmountValue>
                            </CheckoutAmount>
                        </> : null}
                    </CheckoutTotal>
                    <CheckoutAction>
                        <CheckoutPaymentButton disabled={!selectedVariant} onClick={() => {
                            const paymentMethod = paymentMethods.find((method) => {
                                return isWechatpayPaymentType(method)
                            })
                            execute(paymentMethod)
                        }}>
                            立即开通
                        </CheckoutPaymentButton>
                    </CheckoutAction>
                </CheckoutCounterBoxContent>
                <CheckoutAgreement>
                    支付即视为同意《会员服务协议》
                </CheckoutAgreement>
            </CheckoutCounterBox>
        </PageBody>
    </Page>
}



export default Indicator.withSuspense(PVip);
