import styled from "styled-components";

export const TemplateCardBox = styled.div`
  position: relative;
  &-meta {
    &-detail {
      overflow: hidden;
    }
    &-title {
      overflow: hidden;
      color: #000000d9;
      font-weight: 500;
      font-size: 3.6vw;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &-description {
      color: #00000073;
      font-size: 3.4vw;
    }
  }
`

export const TemplateCardCover = styled.div`
  position: relative;
  //border: 1px solid #f3f3f3;
  //border-radius: 6px;
  overflow: hidden;
  border-radius: 6px;
  
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 1px solid #e6ebed;
    content: " ";
  }
`


export const TemplateCoverImageBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
`
export const TemplateCoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
`


export const TemplateCardMeta = styled.div`
  padding-top: 4px;
  position: relative;
  height: 40px;
`;

export const TemplateCardMetaTitle = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
  line-height: 16px;
  font-size: 13px;
`
export const TemplateCardMetaDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18px;
`;
export const TemplateCardMetaDivider = styled.div`
  box-sizing: border-box;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  border-top: 1px solid rgba(0,0,0,.06);


  position: relative;
  top: -.06em;
  display: inline-block;
  height: .9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0,0,0,.06);
`;
export const TemplateCardMetaPrice = styled.div`
  //color: #ffbd29;
  display: inline-block;
  font-size: 10PX;
  line-height: 12px;
  height: 18px;
  color: #d39d06;
  //position: absolute;
  //right: 6PX;
  //top: 6PX;
  background: #e5a9003d;
  //background: #ffbd29;
  //color: #fff;
  border-radius: 4PX;
  padding: 3px 3px;
  min-width: 36px;
  text-align: center;
  margin-right: 6px;
`
export const TemplateCardMetaDoctype = styled.div`
  //display: inline-block;
  font-size: 10PX;
  position: absolute;
  //right: 6PX;
  right: 4px;
  bottom: 4PX;
  background: rgba(0,0,0, 0.65);
  color: #fff;
  //color: rgba(0,0,0, 0.55);
  padding: 4PX 6PX;
  border-radius: 4PX;
`;

export const TemplateCardMetaPhotoCount = styled.div`
  font-size: 11px;
  display: inline-block;
  color: #a1a1a1;
`;
export const TemplateCardMetaPhotoCountValue = styled.span`

`
export const TemplateCardMetaPhotoCountSuffix = styled.span`
  margin-left: 1px;
`

